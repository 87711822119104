import React, { useState, useEffect } from "react";
import { RegExtraPageThree } from "./RegExtraPageThree";
import { ReqExtraPageOne } from "./ReqExtraPageOne";
import { ReqExtraPageTwo } from "./ReqExtraPageTwo";
import { RevPageNoFive } from "./RevPageNoFive";
import { RevPageNoFour } from "./RevPageNoFour";
import { RevPageNoOne } from "./RevPageNoOne";
import { RevPageNoThree } from "./RevPageNoThree";
import { RevPageNoTwo } from "./RevPageNoTwo";

import RegisterImage from "../../assets/registerScreenImage.jpg";
import { ThankYouScreen } from "./ThankYouScreen";

function RegisterStudentFormGuidance({ setRequestModalOpen }) {
  const setRegisterModalOpen = setRequestModalOpen;

  const [pageNo, setPageNo] = useState(1);
  const [showImage, setShowImage] = useState(true);

  const [activePage, setActivePage] = useState(1);

  const [fullFormData, setFullFormData] = useState({
    userType: "",
    whatCourseToPursue: "",
    finalExamDate: "",
    seekingTutoringFor: [],
    userDetails: {
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      city: "",
      state: "",
      zip: "",
      country: null,
      address: "",
      addressLineTwo: "",
      relation: "",
      parentFirstName: "",
      parentLastName: "",
      parentPhone: "",
      parentEmail: "",
    },
    howOftenToTakeLesson: [],
    wantGuidanceAndSupport: "yes",
    chosenPricingPlan: "Guidance and Support",
  });

  // useEffect(() => {
  //   console.log("FULL FORM DATA", fullFormData);
  // }, [fullFormData]);

  const [isMobile, setIsMobile] = useState(false);

  const checkIsMobile = () => {
    setShowImage(window.innerWidth > 900)
    setIsMobile(window.innerWidth <= 600); // You can adjust the width threshold as needed
  };

  // Listen for window resize events to update isMobile state
  useEffect(() => {
    checkIsMobile(); // Initial check
    window.addEventListener("resize", checkIsMobile); // Add event listener

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", checkIsMobile);
    };
  }, []);

  return (
    <div >
      {pageNo === 9 ? (
        <ThankYouScreen
          setRegisterModalOpen={setRegisterModalOpen}
          setActivePage={setActivePage}
          setPageNo={setPageNo}
          pageNo={pageNo}
          fullFormData={fullFormData}
          setFullFormData={setFullFormData}
          isMobile={isMobile}
        />
      ) : (
        <div
        >
          <div
            style={{
              display: "flex",
              flex: 1,
              borderBottom: "1px solid #ccc",
              paddingBottom: "1rem",
              fontSize: isMobile ? "1.5rem" : "2rem",
              fontWeight: "bold",
              marginBottom: "1rem",
              width: "90%",
            }}
          >
            Inquiry / Admission Form
          </div>

          {!isMobile && (
            <div style={{ display: "flex", flex: 1, flexWrap: "wrap" }}>
              {[...Array(7).keys()].map((index) => (
                <div style={{ flex: 1, margin: "10px" }} key={index}>
                  <div
                    style={{
                      position: "relative",
                      paddingTop: "20px", // Adjust this value as needed for spacing
                      paddingBottom: "10px",
                      textAlign: "center",
                    }}
                  >
                    {index === 0 ? (
                      <span style={{ fontSize: "18px" }}>
                        Your are just few steps away
                      </span>
                    ) : (
                      <span style={{ fontSize: "18px" }}>Step-{index}</span>
                    )}
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        height: "10px",
                        background:
                          activePage >= index + 1 ? "rgb(156, 16, 16)" : "#ccc",
                        backgroundImage: `linear-gradient(to right, transparent 50%, ${
                          activePage >= index + 1 ? "rgb(156, 16, 16)" : "#ccc"
                        } 50%)`,
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          )}

          <div
            style={{
              flex: 1,
              display: "flex",
              flexWrap: "wrap",
              alignItems: "flex-start",
              gap: "10px",
            }}
          >
            {showImage && (
              <img
                src={RegisterImage}
                style={{ float: "left", width: "40%" }}
              />
            )}

            <div style={{ flex: 1, padding: "1rem 0rem" }}>
              {pageNo === 1 && (
                <RevPageNoOne
                  setRegisterModalOpen={setRegisterModalOpen}
                  setActivePage={setActivePage}
                  setPageNo={setPageNo}
                  pageNo={pageNo}
                  fullFormData={fullFormData}
                  setFullFormData={setFullFormData}
                  isMobile={isMobile}
                />
              )}

              {pageNo === 2 && (
                <ReqExtraPageOne
                  setRegisterModalOpen={setRegisterModalOpen}
                  setActivePage={setActivePage}
                  setPageNo={setPageNo}
                  pageNo={pageNo}
                  fullFormData={fullFormData}
                  setFullFormData={setFullFormData}
                  isMobile={isMobile}
                />
              )}

              {pageNo === 3 && (
                <RevPageNoTwo
                  setRegisterModalOpen={setRegisterModalOpen}
                  setPageNo={setPageNo}
                  pageNo={pageNo}
                  setActivePage={setActivePage}
                  fullFormData={fullFormData}
                  setFullFormData={setFullFormData}
                  isMobile={isMobile}
                />
              )}

              {pageNo === 4 && (
                <RevPageNoThree
                  setRegisterModalOpen={setRegisterModalOpen}
                  setPageNo={setPageNo}
                  pageNo={pageNo}
                  setActivePage={setActivePage}
                  fullFormData={fullFormData}
                  setFullFormData={setFullFormData}
                  isMobile={isMobile}
                />
              )}

              {pageNo === 5 && (
                <RevPageNoFour
                  setRegisterModalOpen={setRegisterModalOpen}
                  setPageNo={setPageNo}
                  pageNo={pageNo}
                  setActivePage={setActivePage}
                  fullFormData={fullFormData}
                  setFullFormData={setFullFormData}
                  isMobile={isMobile}
                />
              )}

              {pageNo === 6 && (
                <RevPageNoFive
                  setShowImage={setShowImage}
                  setRegisterModalOpen={setRegisterModalOpen}
                  setPageNo={setPageNo}
                  pageNo={pageNo}
                  setActivePage={setActivePage}
                  fullFormData={fullFormData}
                  setFullFormData={setFullFormData}
                  isMobile={isMobile}
                />
              )}

              {pageNo === 7 && (
                <ReqExtraPageTwo
                  setShowImage={setShowImage}
                  setRegisterModalOpen={setRegisterModalOpen}
                  setActivePage={setActivePage}
                  setPageNo={setPageNo}
                  pageNo={pageNo}
                  fullFormData={fullFormData}
                  setFullFormData={setFullFormData}
                  isMobile={isMobile}
                />
              )}

            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default RegisterStudentFormGuidance;
