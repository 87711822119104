import React, {useState, useEffect} from "react";

import bulletPoint from '../../assets/precision.png';

export default function HeaderCard(props) {

  const [isMobile, setIsMobile] = useState(false);

  const checkIsMobile = () => {
    setIsMobile(window.innerWidth <= 1200); // You can adjust the width threshold as needed
  };

  useEffect(() => {
    checkIsMobile(); // Initial check
    window.addEventListener("resize", checkIsMobile); // Add event listener

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", checkIsMobile);
    };
  }, []);


  const { title, content } = props;
  return (
    <div style={{marginBottom: '2rem'}}>
      <div
        style={{
          borderRadius: "1.5rem",
          height: "max-content",
          color: "white",
          background:
            "linear-gradient(69.7deg, rgb(216, 81, 82) 40%, rgb(154, 27, 69) 100.1%)",
          fontSize: isMobile? "1.5rem" : "2rem",
        }}
        className="card-header  textTheme"
      >
        <b>{title}</b>
      </div>
      <div >
        <div style={{display: 'flex'}}>
        <div>
      <img src={bulletPoint}  alt={"•"} style={{height: '40px', width: 'auto', marginTop: '8px'}}/> 
      </div>
      <div>
        <p
          style={{ fontSize: "1.5rem", textAlign: "left", marginTop: '10px'}}
          className="card-text "
        >
          {content}
        </p>
        </div>
        </div>
      </div>
    </div>
  );
}
