import React from "react";
import styles from "./Login.module.css";
import { useState } from "react";
import { useEffect } from "react";
import {
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import { signInWithEmailAndPassword } from "firebase/auth";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { collection, addDoc, getDocs, where, query } from "firebase/firestore";
import { db } from "../../firebase";
import { useNavigate } from "react-router-dom";
import { MyContext } from "../../Context/MyContext";
import { useContext } from "react";
import FormModal from "../../Components/Modal";
import LoginImage from "../../assets/loginScreenImage.jpg";
import LoginLogo from "../../assets/final logo-16.png";

import GoogleImage from "../../assets/R.png";
import { Helmet } from "react-helmet";
import { toast } from "react-hot-toast";

function Login() {
  const navigate = useNavigate();

  const { seoData } = useContext(MyContext);
  const [thisPageSEO, setThisPageSEO] = useState({});

  useEffect(() => {
    if (seoData) {
      setThisPageSEO(seoData?.find((e) => e?.pageName === "Login"));
    }
  }, [seoData]);

  const createHelmetTags = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    const headTags = Array.from(doc.head.children);

    return headTags.map((tag, index) => {
      const tagName = tag.tagName.toLowerCase();

      if (tagName === "title") {
        return <title key={index}>{tag.innerText}</title>;
      } else if (tagName === "meta") {
        const attributes = Array.from(tag.attributes).reduce((acc, attr) => {
          acc[attr.name] = attr.value;
          return acc;
        }, {});
        return <meta key={index} {...attributes} />;
      } else if (tagName === "link") {
        const attributes = Array.from(tag.attributes).reduce((acc, attr) => {
          acc[attr.name] = attr.value;
          return acc;
        }, {});
        return <link key={index} {...attributes} />;
      } else if (tagName === "script" && tag.type === "application/ld+json") {
        return (
          <script key={index} type="application/ld+json">
            {tag.innerText}
          </script>
        );
      } else {
        return null;
      }
    });
  };

  const [openModal, setOpenModal] = useState(false);

  const LoginForm = () => {
    const [loginUserName, setLoginUserName] = useState("");
    const [loginPassword, setLoginPassword] = useState("");
    const [loading, setLoading] = useState(false);

    const LoginHandler = async (e) => {
      e.preventDefault(); // Prevent the form from submitting

      // Validation checks (you can add more as needed)
      if (!loginUserName.trim()) {
        alert("Username is required");
      } else if (!loginPassword) {
        alert("Password is required");
      } else {
        try {
          setLoading(true);
          const auth = getAuth(); // Import getAuth from firebase/auth if needed

          // Perform login using Firebase Authentication
          const userCredential = await signInWithEmailAndPassword(
            auth,
            loginUserName, // Assuming loginUserName is the user's email
            loginPassword
          );

          // Obtain the user's UID (userId)
          const userId = userCredential.user.uid;

          // Query Firestore to get the user's type and additional data based on UID
          const userListRef = collection(db, "userList");
          const q = query(userListRef, where("userId", "==", userId));
          const querySnapshot = await getDocs(q);

          if (querySnapshot.size === 1) {
            // Assuming each user has a unique document in "userList" based on UID
            querySnapshot.forEach((doc) => {
              const userType = doc.data().type;
              const userData = doc.data(); // Additional user data

              // // Store data in Local Storage
              // localStorage.setItem("userId", userId);
              // localStorage.setItem("isLoggedIn", "true");

              // // Update context state
              // setIsUserLoggedIn(true);
              // setUserDetails(userData); // Set user details in context
              // setUserType(userType); // Set user type in context

              // Redirect to portal.ibinnovators.com with user ID in the URL
              //  window.location.href = `http://localhost:3001/login#kl1m24k53km35n64l6m234l253n4kj6l7kml54kn767n36n34=${encodeURIComponent(loginUserName)}&mk532m235iou235o6hn4jn633j46n34j6n=${encodeURIComponent(loginPassword)}`;
              const url = `https://portal.ibinnovators.com/login`;

              const newWindow = window.open(url, "_blank");
              // Optionally, focus on the new window
              if (newWindow) {
                newWindow.focus();
              }
            });
          } else {
            alert("User doesn't exist");
          }
        } catch (error) {
          console.error("Error logging in user: ", error);
          if (error.code === "auth/multi-factor-auth-required") {
            const url = `https://portal.ibinnovators.com/login`;

            const newWindow = window.open(url, "_blank");
            // Optionally, focus on the new window
            if (newWindow) {
              newWindow.focus();
            }
          } else {
            toast.error("Error logging in user");
          }
        } finally {
          setLoading(false);
        }
      }
    };

    const handleForgotPassword = async () => {
      if (loginUserName !== "") {
        try {
          const auth = getAuth(); // Import getAuth from firebase/auth if needed

          // Send password reset email
          await sendPasswordResetEmail(auth, loginUserName);

          // Inform the user that the password reset email has been sent
          alert("Password reset email sent. Please check your email inbox.");
        } catch (error) {
          console.error("Error sending password reset email: ", error);
          alert("Error sending password reset email");
        }
      } else {
        alert("Please Enter Email");
      }
    };
    // const LoginHandler = async (e) => {
    //   e.preventDefault(); // Prevent the form from submitting

    //   // Validation checks (you can add more as needed)
    //   if (!loginUserName.trim()) {
    //     alert('Username is required');
    //   } else if (!loginPassword) {
    //     alert('Password is required');
    //   } else {
    //     try {
    //       const auth = getAuth(); // Import getAuth from firebase/auth if needed

    //       // Perform login using Firebase Authentication
    //       const userCredential = await signInWithEmailAndPassword(
    //         auth,
    //         loginUserName, // Assuming loginUserName is the user's email
    //         loginPassword
    //       );

    //       // Obtain the user's UID (userId)
    //       const userId = userCredential.user.uid;

    //       // Query Firestore to get the user's type based on UID
    //       const userListRef = collection(db, 'userList');
    //       const q = query(userListRef, where('userId', '==', userId));
    //       const querySnapshot = await getDocs(q);

    //       if (querySnapshot.size === 1) {
    //         // Assuming each user has a unique document in "userList" based on UID
    //         querySnapshot.forEach((doc) => {
    //           const userType = doc.data().type;
    //           // Display an alert based on the user's type
    //           if (userType === 'student') {
    //             alert('Student');
    //           } else if (userType === 'teacher') {
    //             alert('Teacher');
    //           } else if (userType === 'admin') {
    //             alert('Admin');
    //           } else {
    //             alert('Unknown user type');
    //           }
    //         });
    //       } else {
    //         alert('User not found in Firestore');
    //       }

    //       console.log('User logged in successfully');
    //     } catch (error) {
    //       console.error('Error logging in user: ', error);
    //       alert('Error logging in user');
    //     }
    //   }
    // };

    return (
      <form
        onSubmit={LoginHandler}
        style={{ width: "100%", padding: "10px 20px" }}
      >
        <div className={styles.row}>
          <div
            style={{
              textAlign: "left",
              width: "100%",
              margin: "10px 10px",
              color: "rgba(14, 56, 136, 0.9)",
              fontWeight: "bold",
              fontSize: "1.15rem",
            }}
          >
            Email
          </div>
          <input
            onChange={(e) => setLoginUserName(e.target.value)}
            value={loginUserName}
            placeholder="Enter email address"
            type="text"
          />
        </div>

        <div className={styles.row}>
          <div
            style={{
              textAlign: "left",
              width: "100%",
              margin: "10px 10px",
              color: "rgba(14, 56, 136, 0.9)",
              fontWeight: "bold",
              fontSize: "1.15rem",
            }}
          >
            Password
          </div>
          <input
            onChange={(e) => setLoginPassword(e.target.value)}
            value={loginPassword}
            placeholder="Enter your password"
            type="password"
          />
        </div>

        <div>
          <div
            style={{
              textAlign: "right",
              width: "100%",
              margin: "10px 10px",
              color: "rgb(128,128,128)",
              fontWeight: "bold",
              fontSize: "1rem",
              cursor: "pointer",
            }}
            onClick={() => {
              handleForgotPassword();
            }}
          >
            Forgot Password?
          </div>
        </div>

        <div style={{ marginTop: "2rem", paddingBottom: 0 }}>
          <button
            disabled={loading}
            type="submit"
            className={styles.rowButton}
            style={{ textAlign: "center" }}
          >
            {loading ? "Logging In" : "Log In"}
          </button>
        </div>

        {/* <div style={{ marginTop: "2rem", paddingBottom: 0 }}>
          <button
            className={styles.rowButton}
            style={{
              textAlign: "center",
              color: "#1e1e1e",
              background: "#ccc",
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex'
            }}
            onClick={loginWithGoogle}
            type="button"
          >
            Login with <img style={{ height: "30px", marginLeft: '5px' }} src={GoogleImage} />
            oogle
          </button>
        </div> */}

        <div
          style={{
            width: "100%",
            textAlign: "center",
            fontSize: "1.1rem",
            color: "#1e1e1e",
            fontWeight: "bold",
            marginTop: "2rem",
            marginBottom: isMobile ? "1rem" : "2rem",
          }}
        >
          Don't have an account?{" "}
          <span
            onClick={() => {
              navigate("/register");
            }}
            style={{ color: "#912d27", cursor: "pointer" }}
          >
            Register Now!
          </span>
        </div>
      </form>
    );
  };

  const loginWithGoogle = async () => {
    // window.location.href = `http://localhost:3001/login#kl1m24k53km35n64l6m234l253n4kj6l7kml54kn767n36n34=${encodeURIComponent("google")}&mk532m235iou235o6hn4jn633j46n34j6n=${encodeURIComponent("1dsgkmt353km5235")}`;
    const url = `https://portal.ibinnovators.com/login`;

    const newWindow = window.open(url, "_blank");
    // Optionally, focus on the new window
    if (newWindow) {
      newWindow.focus();
    }
    // try {

    //   const auth = getAuth(); // Import getAuth from firebase/auth if needed

    //   // Create a GoogleAuthProvider instance
    //   const provider = new GoogleAuthProvider();

    //   // Sign in with Google using a popup window
    //   const userCredential = await signInWithPopup(auth, provider);

    //   // Obtain the user's UID (userId) from Google Sign-In
    //   const userId = userCredential.user.uid;

    //   // Query Firestore to get the user's type and additional data based on UID
    //   const userListRef = collection(db, "userList");
    //   const q = query(userListRef, where("userId", "==", userId));
    //   const querySnapshot = await getDocs(q);

    //   if (querySnapshot.size === 1) {
    //     // Assuming each user has a unique document in "userList" based on UID
    //     querySnapshot.forEach((doc) => {
    //       const userType = doc.data().type;
    //       const userData = doc.data(); // Additional user data

    //       // // Check if userType is 'student' or 'parent'
    //       // if (userType === "student" || userType === "parent") {
    //       //   // Store data in Local Storage
    //       //   localStorage.setItem("userId", userId);
    //       //   localStorage.setItem("isLoggedIn", "true");

    //       //   // Update context state
    //       //   setIsUserLoggedIn(true);
    //       //   setUserDetails(userData); // Set user details in context
    //       //   setUserType(userType); // Set user type in context

    //       //   // Navigate to home page

    //       //   console.log("User logged in successfully with Google");
    //       // } else {
    //       //   alert("Only students and parents are allowed to log in.");
    //       // }
    //       window.location.href = `https://ibinnovators-5df85--portal-ibinnovators-3x45nepi.web.app/login?kl1m24k53km35n64l6m234l253n4kj6l7kml54kn767n36n34=${userId}&mk532m235iou235o6hn4jn633j46n34j6n=k124mkl1m53262nl6k264k6n&dgbfss43543646lkfmhk46k43m6436=rekltrekltmk6kl34m5324543`;

    //     });
    //   } else {
    //     alert("User doesn't exist.");
    //   }
    // } catch (error) {
    //   console.error("Error logging in user with Google: ", error);
    //   alert("Error logging in user with Google");
    // }
  };

  function yourCloseModalFunction() {
    setOpenModal(false);
  }

  const [isMobile, setIsMobile] = useState(false);

  const checkIsMobile = () => {
    setIsMobile(window.innerWidth <= 1200); // You can adjust the width threshold as needed
  };

  useEffect(() => {
    checkIsMobile(); // Initial check
    window.addEventListener("resize", checkIsMobile); // Add event listener

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", checkIsMobile);
    };
  }, []);

  return (
    <div className={styles.backgroundColor}>
      <Helmet>
        {thisPageSEO?.schema && createHelmetTags(thisPageSEO.schema)}
      </Helmet>

      <div
        style={{
          maxWidth: "1600px",
          margin: "0 auto",
        }}
      >
        <div
          style={{
            backgroundColor: "#fff",
            margin: isMobile ? "3vh" : "5vh",
            padding: isMobile ? "2vh" : "1vh",
            boxShadow: "0px 3px 15px rgba(0, 0, 0, 0.4)",
            minWidth: "300px",
          }}
        >
          <FormModal
            openModal={openModal}
            closeModalCallBack={yourCloseModalFunction}
          />

          <div
            style={{
              display: "flex",
              flex: 1,
              width: "100%",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {!isMobile && (
              <div
                style={{
                  flexDirection: "row",
                  height: "100%",
                  padding: "1rem",
                  display: "flex", // Added display: flex
                  flex: 1,
                }}
              >
                <img
                  style={{
                    width: "100%",
                    maxHeight: "100%",
                    alignSelf: "center",
                  }}
                  src={LoginImage}
                  alt="Image"
                />{" "}
                {/* Added maxHeight and alignSelf */}
              </div>
            )}

            {/* FORM */}
            <div
              style={{
                flex: 1,
                margin: "1rem",
                display: "flex",
                flexDirection: "column",
                alignItems: "center", // Center content horizontally
              }}
              className={styles.loginform}
            >
              <img
                src={LoginLogo}
                alt="Logo"
                style={{ height: "3rem", marginTop: "3rem" }}
              />{" "}
              {/* Center image using margin auto */}
              <h2
                style={{
                  color: "#912d27",
                  fontWeight: "bold",
                  paddingBottom: "10px",
                  paddingTop: "10px",
                }}
                className={styles.headerTitle}
              >
                Welcome
              </h2>
              <p style={{ textAlign: "center", fontSize: "1.2rem" }}>
                Please enter your Log-in Details
              </p>
              <LoginForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
