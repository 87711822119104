import React, { useState, useContext } from 'react'
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import { MyContext } from '../../../Context/MyContext';

export default function QueryFormFourthStep(props) {
    const { selectedCallBack, selectedProgram } = props
    const [validated, setValidated] = useState(false);
    const { studentForm, setStudentForm } = useContext(MyContext);

    // State variables for storing user input
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [school, setSchool] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zip, setZip] = useState('');
    const [address, setAddress] = useState('');
    const [relation, setRelation] = useState('');
    const [parentFirstName, setParentFirstName] = useState('');
    const [parentLastName, setParentLastName] = useState('');
    const [parentPhone, setParentPhone] = useState('');
    const [parentEmail, setParentEmail] = useState('');

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
         if (!firstName) {
            alert('First name is required');
          } else if (!lastName) {
            alert('Last name is required');
          } else if (!dateOfBirth) {
            alert('Date of birth is required');
          } else if (!phone) {
            alert('Phone number is required');
          } else if (!email) {
            alert('Email is required');
          } else if (!school) {
            alert('School is required');
          } else if (!city) {
            alert('City is required');
          } else if (!state) {
            alert('State is required');
          } else if (!zip) {
            alert('Zip code is required');
          } else if (!address) {
            alert('Address is required');
          } else if (!relation) {
            alert('Relation is required');
          } else if (!parentFirstName) {
            alert('Parent first name is required');
          } else if (!parentLastName) {
            alert('Parent last name is required');
          } else if (!parentPhone) {
            alert('Parent phone number is required');
          } else if (!parentEmail) {
            alert('Parent email is required');
          } 
           else {

        setValidated(true);

        // Storing all data in a variable
        const allData = {
            firstName,
            lastName,
            dateOfBirth,
            phone,
            email,
            school,
            city,
            state,
            zip,
            address,
            relation,
            parentFirstName,
            parentLastName,
            parentPhone,
            parentEmail
        };
        setStudentForm((prevStudentForm) => ({
            ...prevStudentForm,
            informationAboutTheStudent: allData,
            }))
        selectedCallBack(selectedProgram, 5)
    };
}

    return (
        <div>
            <div className='formContent'>
                <div className='sideSectionMargin mt-5'>
                    <div className="homeParaFont mt-4 text-center  text-dark p-2">
                        <b>
                            Sounds good.<br />
                            Now we just need to learn a bit about the student!
                        </b>
                    </div>
                    <Form noValidate validated={validated} onSubmit={handleSubmit} >
                        <div className='mt-4'>

                            <Row className="mb-3">
                                <Form.Group as={Col} md="4" controlId="validationCustom01">
                                    <Form.Label>First name</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="First name"
                                        size="sm"
                                        onChange={(e) => setFirstName(e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="validationCustom02">
                                    <Form.Label>Last name</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="Last name"
                                        size="sm"
                                        onChange={(e) => setLastName(e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="validationCustom03">
                                    <Form.Label>Date of birth</Form.Label>
                                    <Form.Control onChange={(e) => setDateOfBirth(e.target.value)} type="date" placeholder="City" size="sm" required />
                                </Form.Group>

                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col} md="4" controlId="validationCustom04">
                                    <Form.Label>Phone</Form.Label>
                                    <Form.Control onChange={(e) => setPhone(e.target.value)} type="text" placeholder="Phone" size="sm" required />
                                </Form.Group>
                                <Form.Group as={Col} md="4" >
    <Form.Label>Email</Form.Label>
    <InputGroup hasValidation size="sm">
        <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
        <Form.Control
            size="sm"
            type="email" 
            placeholder="Email"
            aria-describedby="inputGroupPrepend"
            required
            onChange={(e) => {setEmail(e.target.value)}}  
        />
        <Form.Control.Feedback type="invalid">
            Please enter a valid email.
        </Form.Control.Feedback>
    </InputGroup>
</Form.Group>
                                <Form.Group as={Col} md="4" controlId="validationCustom08">
                                    <Form.Label>School</Form.Label>
                                    <Form.Control onChange={(e) => setSchool(e.target.value)} type="text" placeholder="School" size="sm" required />
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col} md="4" controlId="validationCustom05">
                                    <Form.Label>City</Form.Label>
                                    <Form.Control onChange={(e) => setCity(e.target.value)} type="text" placeholder="City" size="sm" required />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="validationCustom06">
                                    <Form.Label>State</Form.Label>
                                    <Form.Control onChange={(e) => setState(e.target.value)} type="text" placeholder="State" size="sm" required />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="validationCustom07">
                                    <Form.Label>Zip</Form.Label>
                                    <Form.Control onChange={(e) => setZip(e.target.value)} type="text" placeholder="Zip" size="sm" required />
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col} md="12" controlId="validationCustom09">
                                    <Form.Label>Address</Form.Label>
                                    <Form.Control onChange={(e) => setAddress(e.target.value)} as="textarea" rows={3} />
                                </Form.Group>
                            </Row>

                        </div>

                        <div className="homeParaFont mt-4 text-center text-dark p-2">
                            <b>
                                Parent/Guardian Details
                            </b>
                        </div>
                        <div className='mt-4'>
                            <Row className="mb-3">
                                <Form.Group as={Col} md="4" controlId="validationCustom01">
                                    <Form.Label>Relation</Form.Label>
                                    <Form.Control
                                    onChange={(e) => setRelation(e.target.value)}
                                        required
                                        type="text"
                                        placeholder="Relation"
                                        size="sm"
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="validationCustom01">
                                    <Form.Label>First name</Form.Label>
                                    <Form.Control
                                    onChange={(e) => setParentFirstName(e.target.value)}
                                        required
                                        type="text"
                                        placeholder="First name"
                                        size="sm"
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="validationCustom02">
                                    <Form.Label>Last name</Form.Label>
                                    <Form.Control
                                    onChange={(e) => setParentLastName(e.target.value)}
                                        required
                                        type="text"
                                        placeholder="Last name"
                                        size="sm"
                                    />
                                </Form.Group>

                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col} md="6" controlId="validationCustom04">
                                    <Form.Label>Phone</Form.Label>
                                    <Form.Control onChange={(e) => setParentPhone(e.target.value)} type="text" placeholder="Phone" size="sm" required />
                                </Form.Group>
                                <Form.Group as={Col}  >
    <Form.Label>Email</Form.Label>
    <InputGroup hasValidation size="sm">
        <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
        <Form.Control
            size="sm"
            type="email" 
            placeholder="Email"
            aria-describedby="inputGroupPrepend"
            required
            onChange={(e) => {setParentEmail(e.target.value)}}  
        />
        <Form.Control.Feedback type="invalid">
            Please enter a valid email.
        </Form.Control.Feedback>
    </InputGroup>
</Form.Group>
                            </Row>

                        </div>
                    <div className='d-flex justifyEnd'>
                        <button
                            type='submit'
                            className='btn btn-sm btn-primary'
                        >
                            Next
                        </button>
                    </div>
                    </Form>

                   
                </div>
            </div >
        </div >
    )
}
