import React from 'react'
import { useContext } from 'react'
import { MyContext } from '../../../Context/MyContext'

export default function QueryFormFirstStep(props) {

    const {studentForm, setStudentForm}=useContext(MyContext);

    const { selectedCallBack } = props
    return (
        <div>
            <div className='formContent'>
                <div className='sideSectionMargin mt-5'>
                    <div className="homeParaFont mt-4 text-center text-dark">
                        <b>
                            Let's get started.<br />
                            Which programme are you studying?
                        </b>
                    </div>

                    <div className="d-grid gap-2 mt-4">
                        <button
                            className="btn btn-outline-info"
                            onClick={() => {
                                setStudentForm((prevStudentForm) => ({
                                    ...prevStudentForm,
                                    Whichprogrammeareyoustudying: 'IB',
                                  }));
                                selectedCallBack("IB", 2)}}
                        >
                            IB <i className="fa-solid fa-right-long ps-2"></i>
                        </button>
                        <button
                            className="btn btn-outline-info"
                            onClick={() => {
                                setStudentForm((prevStudentForm) => ({
                                    ...prevStudentForm,
                                    Whichprogrammeareyoustudying: 'MYP',
                                  }));
                                selectedCallBack("MYP", 2)}}>

                            MYP <i className="fa-solid fa-right-long ps-2"></i>
                        </button>
                        <button
                            className="btn btn-outline-info"
                            onClick={() => {setStudentForm((prevStudentForm) => ({
                                ...prevStudentForm,
                                Whichprogrammeareyoustudying: 'IGCSE',
                              }));
                              selectedCallBack("IGCSE", 2)}}>IGCSE
                            <i className="fa-solid fa-right-long ps-2"></i>
                        </button>
                    </div>

                </div>
            </div>
        </div>
    )
}
