import React, { useEffect, useState } from "react";
import "./index.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TutorCard from "./TutorCard";

export default function Tutors() {
  const [slidesToShow, setSlidesToShow] = useState(3);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1800) {
        setSlidesToShow(3);
      } else if (window.innerWidth >= 992) {
        setSlidesToShow(2);
      } else {
        setSlidesToShow(1);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [isMobile, setIsMobile] = useState(false);

  const checkIsMobile = () => {
    setIsMobile(window.innerWidth <= 1200); // You can adjust the width threshold as needed
  };

  useEffect(() => {
    checkIsMobile(); // Initial check
    window.addEventListener("resize", checkIsMobile); // Add event listener

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", checkIsMobile);
    };
  }, []);

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "flex", // Use flexbox for centering
          justifyContent: "center", // Align horizontally to the center
          alignItems: "center", // Align vertically to the center
          background: "#1e1e1e",
          zIndex: 1, // Ensure the arrow is on top of the slider
          transform: "scale(2.5)", // Use 'transform' to scale the element,
          borderRadius: "50%",
        }}
        onClick={onClick}
      >
        {/* Content inside the arrow */}
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "flex", // Use flexbox for centering
          justifyContent: "center", // Align horizontally to the center
          alignItems: "center", // Align vertically to the center
          background: "#1e1e1e",
          zIndex: 1, // Ensure the arrow is on top of the slider
          transform: "scale(2.5)", // Use 'transform' to scale the element,
          borderRadius: "50%",
        }}
        onClick={onClick}
      >
        {/* Content inside the arrow */}
      </div>
    );
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    // fade: true,
  };

  const profiles = [
    {
      subName: "Biology and Chemistry Teacher",
      firstName: "Alex",
      lastName: "Rodriguez",
      subjectsTaught: "Biology (SL/HL) and Chemistry (SL/HL)",
      qualification: "PhD in Biochemistry",
      iBExaminer: "Yes, for both Biology and Chemistry",
      tutorRating: 4,
      years: "12",
      backgroundColor: "rgba(173, 216, 230, 0.8)",
    },
    {
      subName: "Math AA Teacher",
      firstName: " Jonathan",
      lastName: " Baker",
      subjectsTaught: "Math AA (SL/HL)",
      qualification: " PhD in Mathematics",
      iBExaminer: "Yes, for Math AA",
      tutorRating: 5,
      years: " 9",
      backgroundColor: "rgba(255, 182, 193, 0.8)",
    },
    {
      subName: "Math AI Teacher",
      firstName: " Emily",
      lastName: " Collins",
      subjectsTaught: " Math AI (SL/HL)",
      qualification: "Master's in Applied Mathematics",
      iBExaminer: "Yes, for Math AI",
      tutorRating: 4,
      years: " 8",
      backgroundColor: "rgba(255, 239, 213, 0.8)",
    },
    {
      subName: "Physics Teacher",
      firstName: " Michael",
      lastName: " Anderson",
      subjectsTaught: "Physics (SL/HL)",
      qualification: "PhD in Physics",
      iBExaminer: " Yes, for Physics",
      tutorRating: 4,
      years: " 11",
      backgroundColor: "rgba(152, 251, 152, 0.8)",
    },
    {
      subName: "Psychology Teacher",
      firstName: " Rachel",
      lastName: " Mitchell",
      subjectsTaught: "Psychology (SL/HL)",
      qualification: "Psychology (SL/HL)",
      iBExaminer: " Yes, for Psychology",
      tutorRating: 5,
      years: " 7",
      backgroundColor: "rgba(221, 160, 221, 0.8)",
    },
    {
      subName: "Extended Essay and Business Management Teacher",
      firstName: " David",
      lastName: " Carter",
      subjectsTaught: "Business Management (SL/HL) and Extended Essay (EE)",
      qualification: " PhD in Business Administration",
      iBExaminer: " Yes, for Business Management and EE",
      tutorRating: 4,
      years: " 8",
      backgroundColor: "rgba(240, 230, 140, 0.8)",
    },
    {
      subName: "Theory of Knowledge and Chemistry Teacher",
      firstName: " Elizabeth",
      lastName: " Walker",
      subjectsTaught: " Theory of Knowledge (TOK) and Chemistry (SL)",
      qualification: " Master's in Chemistry",
      iBExaminer: "Yes, for TOK and Chemistry",
      tutorRating: 5,
      years: " 6",
      backgroundColor: "rgba(176, 224, 230, 0.8)",
    },
    {
      subName: "Environmental Systems and Societies (ESS) and Biology Teacher",
      firstName: " Daniel",
      lastName: " White",
      subjectsTaught: "ESS (SL/HL) and Biology (SL/HL)",
      qualification: " Master's in Environmental Science",
      iBExaminer: " Yes, for ESS and Biology",
      tutorRating: 4,
      years: " 9",
      backgroundColor: "rgba(255, 218, 185, 0.8)",
    },
    {
      subName: "Math AI/AA Teacher",
      firstName: " Olivia",
      lastName: " Martin",
      subjectsTaught: "Math AI/AA (SL/HL)",
      qualification: "PhD in Mathematics",
      iBExaminer: "Yes, for Math AI/AA",
      tutorRating: 5,
      years: " 11",
      backgroundColor: "rgba(255, 248, 220, 0.8)",
    },

    // Add more profiles as needed
  ];
  return (
    <div>
      <div
        style={{ marginTop: "5rem" }}
        className="homePageHeadings text-center textTheme"
      >
        <div
          style={{
            fontSize: isMobile ? "2rem" : "2.5rem",
            color: "rgb(14, 56, 136)",
            fontWeight: "bold",
          }}
        >
          Featured Tutors
        </div>
      </div>
      <div className="mt-5">
        <Slider  {...settings}>
          {profiles.map((profile, index) => (
            <div   key={index}>
              <TutorCard profile={profile} />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}
