import React from "react";
import "./index.css";
import { useNavigate } from "react-router-dom";
import { MyContext } from "../../Context/MyContext";
import { useContext } from "react";

export default function FindATutor() {

  const {isUserLoggedIn} = useContext(MyContext);

  const navigtePages = useNavigate();

  // handle change pages
  const handleChange = (e, navValue) => {
    navigtePages(navValue);
  };
  return (
    <div id="findATutor">
      <div className="findTutor-container">
        {/* <div className="bg-image"></div> */}
        <div className="thisContainer">
        <div style={{ color: "white" }} >
          <h1 style={{fontSize: '2.2rem'}}>
            <b>
              IB tutoring is our specialty!
              
            </b>
          </h1> 
          <h1 style={{fontSize: '2.2rem', marginBottom: '2rem'}}>
            <b>
            Let’s get you started!
            </b>
          </h1>
          <div style={{ marginTop: "1rem" }}>
            <button
            onClick={(e) => handleChange(e, "/pricing")}
              className="mainButtonSecond"
            >
              Book a Tutor
            </button>
            <button
              className="mainButtonOne"
              onClick={(e) => handleChange(e, "/becomeTutor")}
            >
              Become a Tutor
            </button>
          </div>
        </div>
        </div>
      </div>
    </div>
  );
}
