import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Pages/home/home";
import NavBar from "./Components/navbar";
import Footer from "./Components/footer";
import ComingSoon from "./Components/ComingSoon";
import BecomeTutor from "./Pages/BecomeTutor/BecomeTutor";
import HowItsWork from "./Pages/HowItsWork/HowItsWork";
import WhyIBI from "./Pages/WhyIBI/WhyIBI";
// import SocialMedia from "./Components/SocialMediaIcons";
import Pricing from "./Pages/Pricing/Pricing";
import RevisionCourses from "./Pages/RevisionCourses/RevisionCourses";
import Blogs from "./Pages/Blog/Blogs";
import Details from "./Pages/Blog/Details";
import ScrollToTop from "./Components/ScrollToTop";
import Login from "./Pages/Login/Login";
import ContactUs from "./Pages/ContactUs/ContactUs";
import Register from "./Pages/Login/Register";
import { IgcseTutoring } from "./Pages/Igcse/IgcseTutoring";
import { Toaster } from "react-hot-toast";
import RequestMyCourse from "./Pages/RequestMyCourse/RequestMyCourse";
import RevisionCourseForm from "./Pages/RevisionCourseForm/RevisionCourseForm";
import UpcomingCourseFormPage from "./Pages/UpcomingCourseFormPage/UpcomingCourseFormPage";
import RegisterStudentGuidance from "./Pages/RegisterStudentGuidance/RegisterStudentGuidance";
import TermsAndConditions from "./Pages/Legal/TermsAndConditions";
import PrivacyPolicy from "./Pages/Legal/PrivacyPolicy";
import Disclaimer from "./Pages/Legal/Disclaimer";
import CompanyDetails from "./Pages/Legal/CompanyDetails";
// import SocialMedia from "./Components/SocialMediaIcons";

function App() {
  return (
    <>
      <Router>
        <NavBar />
        <Toaster />
        <ScrollToTop />
        <div style={{ marginTop: "80px" }}>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/whyIBI" element={<WhyIBI />} />
            <Route path="/howitworks" element={<HowItsWork />} />
            <Route path="/revisionCourses" element={<RevisionCourses />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/*" element={<ComingSoon />} />
            <Route path="/contactUs" element={<ContactUs />} />
            <Route path="/igsceTutoring" element={<IgcseTutoring />} />

            <Route path="/blogs" element={<Blogs />} />
            <Route path="/blogs/:id" element={<Details />} />

            {/* USER ONLY PATHS */}
            <Route exact path="/register" element={<Register />} />
            <Route
              exact
              path="/guidance-and-support-form"
              element={<RegisterStudentGuidance />}
            />
            <Route exact path="/login" element={<Login />} />
            <Route path="/becomeTutor" element={<BecomeTutor />} />

            {/* FORMS */}
            <Route
              path="/request-my-own-course"
              element={<RequestMyCourse />}
            />
            <Route
              path="/revision-courses-form"
              element={<RevisionCourseForm />}
            />
            <Route
              path="/upcoming-courses-form/:id"
              element={<UpcomingCourseFormPage />}
            />

            {/* LEGAL */}

            <Route
              path="/terms-and-conditions"
              element={<TermsAndConditions />}
            />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/disclaimer" element={<Disclaimer />} />
            <Route path="/company-details" element={<CompanyDetails />} />
          </Routes>
        </div>
        {/* <SocialMedia /> */}

        <Footer />
      </Router>
    </>
  );
}

export default App;
