import React, {useState, useEffect} from 'react';
import Typewriter from 'typewriter-effect';
import classes from './MainImage.module.css';
import { useNavigate } from 'react-router-dom';
import globe from "./img/globe.png"
import MainProduct from "../../assets/homeVector.png"

function MainImage() {


    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 1400) {
                setIsMobile(false)
            } else {
                setIsMobile(true)
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const navigate=useNavigate();
    return (
        <div className={classes.thisContainer}>
        <div className={classes.mainImage}>
            <div className={classes.mainImageComponent}>
                <div className={classes.mainImageText}>
                    <h2 className={classes.heading}>
                        {!isMobile ? 
                        <Typewriter
                            options={{
                                strings: ['IB INNOVATORS'],
                                autoStart: true,
                                loop: true,
                                delay: 100, // Delay between each character
                            }}
                        />
                        :
                        "IB INNOVATORS"
                    }
                    </h2>
                    <p className={classes.para}>Ace Your IBDP Exams with Certified IB Examiners</p>
                    <button onClick={() => {navigate('/pricing')}} className={classes.mainButton}>Book a Tutor</button>
                </div>
            </div>
            <div className={classes.mainImageComponentSecond}>
                <img src={globe} alt='MainProduct' className={classes.mainProductImage} />
            </div>
            </div>
        </div>
    );
}

export default MainImage;
