import React from "react";
import "./index.css";
import tutorAvatar from "../../../images/tutorCard.png";
import { useState, useEffect } from "react";

export default function TutorCard(props) {


  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Function to handle window resize
    const handleResize = () => {
      if (window.innerWidth < 600) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    // Initial check on component mount
    handleResize();

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const { profile } = props;
  const ratingStar = (index) => {
    if (index <= profile.tutorRating) {
      return (
        <li key={index}>
          <i
            style={{ fontSize: "20px" }}
            className="fa fa-star text-warning homePageParaFont"
          ></i>
        </li>
      );
    } else {
      return (
        <li key={index}>
          <i
            style={{ fontSize: "20px" }}
            className="fa fa-star text-secondary homePageParaFont"
          ></i>
        </li>
      );
    }
  };

  const cellStyle = {
    width: "200px",
    height: "50px",
  };

  return (
    <section style={{ flex: 1 }} id="tutorCard">
      <div className="card testimonial-card card-flip"
       style={{margin: '5px'}}
       >
        <div className="card-inner">
          {/* Front of the card */}
          <div style={{height: 'max-content', minHeight: isMobile?"30rem" : "20rem" }}  className="card-front">
            <div
              style={{
                flexDirection: isMobile ? "column" : "row",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flex: 1,
                width: "100%",
              }}
            >
              <div style={{ flex: isMobile ? 0.5 : 1, padding: '10px'}}>
                <img
                  src={tutorAvatar}
                  className="rounded-circle img-fluid"
                  alt="SomeImage"
                  style={{height: isMobile ? '10rem' : '100%'}}
                />
              </div>
              <div style={{ flex: 1}}>
                <div
                  style={{ fontSize: "1.5rem" }}
                  className="mb-1 homePageSubHeadings"
                >
                  {profile.firstName + " " + profile.lastName}
                </div>

                {profile.years && <div 
                  style={{ fontSize: "1.2rem" }}>{profile.years} Years Experience</div>}

                <div style={{background: '#fff', padding: '10px', marginTop: '10px', borderRadius: '10px'}}>
                  {profile.tutorRating && (
                    <div
                    style={{ fontSize: "2rem", marginBottom: '10px', fontWeight: 'bold' }}>{profile.tutorRating.toFixed(1)}</div>
                  )}

                  <ul className="list-unstyled d-flex justify-content-center">
                    {[1, 2, 3, 4, 5].map((index) => ratingStar(index))}
                  </ul>
                  <div style={{ fontSize: "1.2rem", marginTop: 0 }}>Average Rating</div>
                </div>
              </div>
            </div>
          </div>
          {/* Back of the card */}
          <div style={{height: 'max-content', minHeight: isMobile?"30rem" : "20rem" }}  className="card-back">
            <div style={{fontSize: '1.85rem', color: 'rgb(156, 16, 16)', fontWeight: 'bold', marginBottom: '8px'}}>{profile.subjectsTaught}</div>
            <div style={{fontSize: '1.6rem', color: 'rgba(14, 56, 136, 0.9)', fontWeight: 'bold', marginBottom: '8px'}}>IBDP TUTOR</div>

            <div style={{fontSize: '1.3rem', color: '#198c8c', marginBottom: '8px' }}>
              Guiding Minds, Shaping Futures, Igniting
              Potential for a Bright Tomorrow! #EducationMatters
              #EmpoweringYouth
            </div>
            
            <div style={{fontSize: '1.3rem', color: '#198c8c' }}>
              TEACHER TAG LINE
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
