import React from "react";
import "./RevisionCoursesDetails.css";
import groupPeople from "./../../assets/Revision.png";
import background from "../../assets/WhyIbI.jpg";
import background2 from "../../assets/WhyIBIMobile.jpg";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import mainImage from "../../assets/mainImage.svg";
import secondImage from "../../assets/whyIbiSecond.svg";
import thirdImage from "../../assets/whyIbiThird.jpg";

import umbrella from "../../assets/image045.png";
import sun from "../../assets/image028.png";
import tutor from "../../assets/image010.png";
import compBook from "../../assets/image004.png";
import compCursor from "../../assets/image005.png";

import manRunning from "../../assets/image006.png";
import play from "../../assets/image007.png";

import robotHead from "../../assets/image009.png";
import checkBox from "../../assets/image011.png";
import tickBox from "../../assets/image012.png";

import snowFlake from "../../assets/image037.png";
import tree from "../../assets/image038.png";

import plant from "../../assets/image042.png";
import flower from "../../assets/image043.png";

import paperBoard from "../../assets/image018.png";
import pen from "../../assets/image019.png";

import rigntImage from "../../assets/image184.png";

import { useContext } from "react";
import { MyContext } from "../../Context/MyContext";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import RevisionCoursesForm from "../../Components/RevisionCoursesForm/RevisionCoursesForm";
import RequestCoursesForm from "../../Components/RequestCoursesForm/RequestCoursesForm";
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../../firebase";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  maxHeight: "90%",
  overflow: "scroll",
  maxWidth: "1000px",
};

export default function RevisionCoursesDetails({ upcommingRef, requestRef }) {
  const [registerModalOpen, setRegisterModalOpen] = useState(false);
  // const [requestModalOpen, setRequestModalOpen] = useState(false);

  // const { isUserLoggedIn } = useContext(MyContext);

  const [isMobile, setIsMobile] = useState(false);

  const checkIsMobile = () => {
    setIsMobile(window.innerWidth <= 800); // You can adjust the width threshold as needed
  };

  // Listen for window resize events to update isMobile state
  useEffect(() => {
    checkIsMobile(); // Initial check
    window.addEventListener("resize", checkIsMobile); // Add event listener

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", checkIsMobile);
    };
  }, []);

  const navigate = useNavigate();

  const [receivedData, setReceivedData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const blogsRef = collection(db, "UpcomingCourses");
      const unsubscribe = onSnapshot(blogsRef, (snapshot) => {
        const blogsArray = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setReceivedData(blogsArray);
      });

      return () => {
        // Unsubscribe from the snapshot listener when component unmounts
        unsubscribe();
      };
    };

    fetchData();
  }, []);

  const formatDateString = (dateString) => {
    const date = new Date(dateString);
    const month = date.toLocaleString("default", { month: "short" });
    const day = String(date.getDate()).padStart(2, "0"); // Ensures two digits with leading zero if needed
    return `${month} ${day}`;
  };

  const formatDateStringYear = (dateString) => {
    const date = new Date(dateString);
    const month = date.toLocaleString("default", { month: "short" });
    const day = String(date.getDate()).padStart(2, "0"); // Ensures two digits with leading zero if needed
    const year = date.getFullYear();
    return `${month} ${day}, ${year}`;
};


  return (
    <>
      <div
        style={{
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          paddingTop: "5rem",
        }}
      >
        <div style={{ paddingRight: "5vw", paddingLeft: "5vw" }}>
          {/* First Section: TEXT on Right */}

          {receivedData.length > 0 && (
            <div
              ref={upcommingRef}
              style={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                alignItems: "center",
                marginBottom: "50px",
              }}
            >
              {/* Text Content */}
              <div
                style={{
                  flex: 0.2,
                  paddingRight: !isMobile ? "2rem" : "0rem",
                  marginBottom: "1rem",
                }}
              >
                <div
                  style={{
                    fontSize: isMobile ? "2rem" : "3.5rem",
                    textAlign: "center",
                    color: "rgb(14, 56, 136)",
                    fontWeight: "bolder",
                  }}
                  className="homePageSubHeadings mt-3"
                >
                  Upcoming
                  {
                    !isMobile ?
                    <br />
                    :
                    " "
                  }
                  Courses
                </div>
              </div>

              <div
                style={{ flex: 1, borderLeft: "10px solid rgb(14, 56, 136)" }}
              >
                {receivedData.map((item, index) => {
                  const formattedStartDate = formatDateString(item.startDate);
                  const formattedEndDate = formatDateString(item.endDate);
                  const formattedLastDate = formatDateStringYear(item.lastDate);

                  return (
                    <div
                      key={index}
                      style={{
                        margin: isMobile ? "1rem" : "3rem",
                        fontSize: isMobile ? "1rem" : "1.3rem",
                        paddingTop: "2.5rem",
                        borderTop: index !== 0 ? "3px dotted #ccc" : "none",
                      }}
                    >
                      <span
                        style={{ fontSize: isMobile ? "1.15rem" : "1.5rem" }}
                      >
                        <b>{item.heading}:</b> <br />
                      </span>
                      Session {item.sessionNumber}: {item.tagline}
                      <p
                        style={{
                          color: "rgb(121, 121, 121)",
                          lineHeight: "30px",
                        }}
                      >
                        Starting from {formattedStartDate} till{" "}
                        {formattedEndDate}, to enroll click on{" "}
                        <Button
                          style={{
                            color: "white",
                            backgroundColor: "rgb(14, 56, 136)",
                            padding: "5px 15px",
                            borderRadius: "5px",
                            whiteSpace: "nowrap",
                            cursor: "pointer",
                            fontSize: isMobile ? "0.8rem" : '1.1rem'
                          }}
                          onClick={() => {
                            navigate(`/upcoming-courses-form/${item?.id}`);
                          }}
                          variant="contained"
                        >
                          Register Now!
                        </Button>{" "}
                        <span style={{ color: "red" }}>
                          (Last date to Register {formattedLastDate})
                        </span>
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          {/* First Section: Image on Right */}
          <div
            style={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              alignItems: "center",
              marginBottom: "50px",
            }}
            ref={requestRef}
          >
            {/* Text Content */}
            <div
              style={{
                flex: 1.2,
                paddingRight: !isMobile ? "2rem" : "0rem",
                marginBottom: "1rem",
              }}
            >
              <div
                style={{
                  fontSize: isMobile ? "2rem" : "3rem",
                  textAlign: "center",
                  color: "rgb(14, 56, 136)",
                }}
                className="homePageSubHeadings mt-3"
              >
                Request Your Own Course
              </div>
              <br />
              <p style={{ fontSize: "1.5rem" }}>
                Master your IB exams with our personalized 1-on-1 revision
                courses, crafted for your needs. Our Certified IB examiners
                ensure success, reducing stress. Enroll today for guaranteed
                exam success!
              </p>
              <br />
              <p
                style={{
                  fontSize: "1.5rem",
                  color: "rgb(14, 56, 136)",
                  textAlign: "center",
                }}
              >
                "Revise smarter, not harder! Boost your exam preparation with
                our tailored 1-on-1 customised exam courses."
              </p>

              <br />
              <div style={{ flex: 1, textAlign: "center" }}>
                <Button
                  onClick={() => {
                    navigate("/request-my-own-course");
                  }}
                  style={{
                    alignSelf: "center",
                    fontSize: "1.2rem",
                    backgroundColor: "rgb(156, 16, 16)",
                    padding: '5px 15px'
                  }}
                  variant="contained"
                >
                  Request My Course Now
                </Button>
              </div>
            </div>

            <div style={{ flex: 1 }}>
              <img src={rigntImage} alt="Image" style={{ width: "100%" }} />
            </div>
          </div>
        </div>
      </div>

      {/* REGISTER MODAL OPEN */}
      <Modal
        open={registerModalOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <RevisionCoursesForm setRegisterModalOpen={setRegisterModalOpen} />
        </Box>
      </Modal>
    </>
  );
}
