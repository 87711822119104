import React, { useContext } from "react";
import "./HowItsWork.css";
import ImageByContentCard from "../../Components/ImageByContentCard";
import ContentByImageHeroSection from "../../Components/ContentByImageHeroSection";
import HeaderCard from "../../Components/headerCard";
import UnborderedList from "../../Components/UnOrderedList";
import { useState, useEffect } from "react";
import howItWorksImage from "../../assets/web.png";
import howItWorksImageMobile from "../../assets/webMobile.png";
import { useNavigate } from "react-router-dom";
import mainImage from "../../assets/2887991.svg";

import image2 from "../../assets/image2.png";
import first from "../../assets/1st.png";
import second from "../../assets/2nd.png";
import third from "../../assets/3rd.png";

import image from "../../assets/image13.png";
import { Helmet } from "react-helmet";
import { MyContext } from "../../Context/MyContext";

export default function HowItsWork() {
  const navigate = useNavigate();
  const {seoData} = useContext(MyContext)
  const [thisPageSEO, setThisPageSEO] = useState({});
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    if(seoData){
      setThisPageSEO(seoData?.find((e) => e?.pageName === "How it works"))
    }
  }, [seoData])

  const successJourney = [
    {
      title: "Select Your Tailored Package",
      content:
        "At IB Innovators, we offer flexible tutoring packages designed to cater to your exact requirements. Whether you need one-time sessions for specific assignments or continuous support throughout the academic year, we've got you covered. Our expert team ensures that you receive the ideal package, setting you on the path to academic triumph.",
      icon: <i className="fa fa-comments"></i>,
    },
    {
      title: "Your Perfect Tutor Match",
      content:
        "After booking your package, the search is on us! Drawing from our extensive network of tutors, we meticulously match you with the tutor best suited to your needs. Rest assured, our tutors are not only experts in their respective fields but have also mastered the IB program themselves. Your personal tutor will provide tailored academic support, valuable resources, and time-tested tips to help you overcome any obstacles on your journey to success.",
      icon: <i className="fa-solid fa-thumbs-up"></i>,
    },

    {
      title: "Meet Your Tutor - Ignite the Journey",
      content:
        "Once your perfect tutor match is found, you'll connect with them through an online video call, allowing you to interact and embark on your learning journey. During this introduction, you will discuss your objectives, create a plan of action, and set off confidently on the path to achieving your goals. With IB Innovators, you have a dedicated partner on your side.",
      icon: <i className="fa fa-star"></i>,
    },
  ];
  const guide = [
    {
      title: "Schedule Your Sessions - Flexibility at Your Fingertips",
      content:
        "Convenience is key to effective learning. You can connect with your tutor for online sessions wherever and whenever it works best for you. Our aim is to make the learning process seamless and comfortable.",
    },
    {
      title: "Set Your Goals - Charting Your Course",
      content:
        "During the introductory call, your tutor will work with you to define clear objectives and map out a plan to achieve them together. Your goals become our mission.",
    },
    {
      title: "Discuss, Improve, Repeat - Your Learning Companion",
      content:
        "Engage in fruitful discussions with your tutor, seeking solutions to challenges and clarifying questions. Together, you will make steady progress and conquer obstacles.",
    },
    {
      title: "Use Our Resources - Enriching Your Experience",
      content:
        "Our online tutoring platform and digital whiteboard enable your tutor to share crucial resources, including study guides, past papers, and solutions to IB exam questions. These valuable tools empower your learning journey.",
    },
    {
      title: "Feedback and Guidance - Empowering Your Growth",
      content:
        "Your tutor provides regular feedback to monitor your progress and offer guidance. Empowering you to achieve your goals is their utmost priority.",
    },
    {
      title: "Rewatch and Review - Accessing Your Sessions",
      content:
        "We believe in empowering you beyond the sessions. All tutoring sessions are accessible for review, allowing you to reinforce your understanding.",
    },
  ];

  const garantee = [
    {
      title: "Expert Tutors - Unparalleled Support",
      content:
        "Our tutors have earned at least 40 points on their IB exams and achieved a 7 in the IB subjects they guide you through. Expect exceptional explanatory and interpersonal skills to receive the best support possible.",
    },
    {
      title: "100% Satisfaction - Your Trust Matters",
      content:
        "Your satisfaction is paramount to us. If, after two hours of your booked package, you are not entirely satisfied with the support received, we offer a complete refund.",
    },
    {
      title: "Quality Control - Your Learning Experience Matters",
      content:
        "Our commitment extends to the quality of your learning experience. We personally check in with all students after their first session to ensure satisfaction. If expectations are not met, rest assured, we'll find a solution immediately.",
    },
  ];

  const [isMobile, setIsMobile] = useState(false);

  const checkIsMobile = () => {
    setIsMobile(window.innerWidth <= 600); // You can adjust the width threshold as needed
  };

  // Listen for window resize events to update isMobile state
  useEffect(() => {
    checkIsMobile(); // Initial check
    window.addEventListener("resize", checkIsMobile); // Add event listener

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", checkIsMobile);
    };
  }, []);


  const createHelmetTags = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const headTags = Array.from(doc.head.children);
  
    return headTags.map((tag, index) => {
      const tagName = tag.tagName.toLowerCase();
  
      if (tagName === 'title') {
        return <title key={index}>{tag.innerText}</title>;
      } else if (tagName === 'meta') {
        const attributes = Array.from(tag.attributes).reduce((acc, attr) => {
          acc[attr.name] = attr.value;
          return acc;
        }, {});
        return <meta key={index} {...attributes} />;
      } else if (tagName === 'link') {
        const attributes = Array.from(tag.attributes).reduce((acc, attr) => {
          acc[attr.name] = attr.value;
          return acc;
        }, {});
        return <link key={index} {...attributes} />;
      } else if (tagName === 'script' && tag.type === 'application/ld+json') {
        return <script key={index} type="application/ld+json">{tag.innerText}</script>;
      } else {
        return null;
      }
    });
  };

  return (
    <>
      {/* <div style={{padding: '5vw', background: 'linear-gradient(109.6deg, rgb(61, 121, 176) 11.3%, rgb(35, 66, 164) 91.1%)', marginBottom: '5rem'}}>
            <ContentByImageHeroSection />
            </div> */}


<Helmet>
      {thisPageSEO?.schema && createHelmetTags(thisPageSEO.schema)}
    </Helmet>



    <div style={{flex: 1, width: '100%', background: 'red', height: imageLoaded ? "max-content" : '600px', background: '#14549f'}}>
      <div style={{ maxWidth: '1800px', flex: 1, margin: '0px auto' }}>
        {isMobile ? (
          <img
            src={howItWorksImageMobile}
            width="100%"
            alt=""
            onLoad={() => setImageLoaded(true)}
            height="auto"
            style={{maxWidth: '1800px'}}
          />
        ) : (
          <img src={howItWorksImage} onLoad={() => setImageLoaded(true)} width="100%" alt="" height="auto" 
          />
        )}
      </div>
      </div>

      {/* <div style={{ flex: 1 }}>
        {isMobile ? (
          <img
            src={howItWorksImageMobile}
            width="100%"
            alt="How it works"
            height="auto"
          />
        ) : (
          <img
            src={howItWorksImage}
            width="100%"
            alt="How it works"
            height="auto"
          />
        )}
      </div> */}
      <div
        style={{
          maxWidth: "1800px",
          margin: "0 auto",
        }}
      >
        <div style={{ marginTop: "5rem" }}>
          <div style={{marginRight: isMobile ? "2rem": '8rem', marginLeft: isMobile ? "2rem": '8rem'}}>
            <div className="row">
              <div
                className="homePageHeadings textTheme mb-5 text-center"
                style={{
                  fontSize: isMobile ? "2rem" : "2.5rem",
                  color: "rgb(14, 56, 136)",
                  fontWeight: "bold",
                }}
              >
                Your Journey to Success - Just 3 Steps Away
              </div>

              {/* RESPONSIVE ROW */}
              <div
                style={{
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                  alignItems: "center",
                  marginBottom: "50px",
                }}
              >
                {/* Text Content */}
                <div
                  style={{
                    flex: 1.2,
                    paddingRight: !isMobile ? "2rem" : "0rem",
                    marginBottom: "1rem",
                  }}
                >
                  <div
                    style={{ fontSize: isMobile ? "1.8rem" : "2rem" }}
                    className="homePageSubHeadings mt-3"
                  >
                    01. Select Your Tailored Package
                  </div>
                  <div
                    style={{
                      fontSize: "1.5rem",
                    }}
                    className="p-3"
                  >
                    At IB Innovators, we offer flexible tutoring packages
                    designed to cater to your exact requirements. Whether you
                    need one-time sessions for specific assignments or
                    continuous support throughout the academic year, we've got
                    you covered. Our expert team ensures that you receive the
                    ideal package, setting you on the path to academic triumph.
                  </div>
                </div>

                <div style={{ flex: 1 }}>
                  <div
                    style={{
                      position: "relative",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={first}
                      alt="IBI Image"
                      style={{
                        width: "90%",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                </div>
              </div>

              {!isMobile ? (
                <>
                  {/* RESPONSIVE ROW */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: isMobile ? "column" : "row",
                      alignItems: "center",
                      marginBottom: "50px",
                    }}
                  >
                    <div style={{ flex: 1 }}>
                      <div
                        style={{
                          position: "relative",
                          height: "100%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={second}
                          alt="IBI Image"
                          style={{
                            width: "90%",
                            objectFit: "cover",
                          }}
                        />
                      </div>
                    </div>
                    {/* Text Content */}
                    <div
                      style={{
                        flex: 1.2,
                        paddingRight: !isMobile ? "2rem" : "0rem",
                        marginBottom: "1rem",
                      }}
                    >
                      <div
                        style={{fontSize: isMobile ? "1.8rem" : "2rem"  }}
                        className="homePageSubHeadings mt-3"
                      >
                        02. Your Perfect Tutor Match
                      </div>
                      <div
                        style={{
                          fontSize: "1.5rem",
                        }}
                        className="p-3"
                      >
                        After booking your package, the search is on us! Drawing
                        from our extensive network of tutors, we meticulously
                        match you with the tutor best suited to your needs. Rest
                        assured, our tutors are not only experts in their
                        respective fields but have also mastered the IB program
                        themselves. Your personal tutor will provide tailored
                        academic support, valuable resources, and time-tested
                        tips to help you overcome any obstacles on your journey
                        to success.
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {/* RESPONSIVE ROW */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: isMobile ? "column" : "row",
                      alignItems: "center",
                      marginBottom: "50px",
                    }}
                  >
                    {/* Text Content */}
                    <div
                      style={{
                        flex: 1.2,
                        paddingRight: !isMobile ? "2rem" : "0rem",
                        marginBottom: "1rem",
                      }}
                    >
                      <div
                        style={{ fontSize: isMobile ? "1.8rem" : "2rem"  }}
                        className="homePageSubHeadings mt-3"
                      >
                        02. Your Perfect Tutor Match
                      </div>
                      <div
                        style={{
                          fontSize: "1.5rem",
                        }}
                        className="p-3"
                      >
                        After booking your package, the search is on us! Drawing
                        from our extensive network of tutors, we meticulously
                        match you with the tutor best suited to your needs. Rest
                        assured, our tutors are not only experts in their
                        respective fields but have also mastered the IB program
                        themselves. Your personal tutor will provide tailored
                        academic support, valuable resources, and time-tested
                        tips to help you overcome any obstacles on your journey
                        to success.
                      </div>
                    </div>

                    <div style={{ flex: 1 }}>
                      <div
                        style={{
                          position: "relative",
                          height: "100%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={second}
                          alt="IBI Image"
                          style={{
                            width: "90%",
                            objectFit: "cover",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}

              {/* RESPONSIVE ROW */}
              <div
                style={{
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                  alignItems: "center",
                  marginBottom: "50px",
                }}
              >
                {/* Text Content */}
                <div
                  style={{
                    flex: 1.2,
                    paddingRight: !isMobile ? "2rem" : "0rem",
                    marginBottom: "1rem",
                  }}
                >
                  <div
                    style={{ fontSize: isMobile ? "1.8rem" : "2rem"  }}
                    className="homePageSubHeadings mt-3"
                  >
                    03. Meet Your Tutor - Ignite the Journey
                  </div>
                  <div
                    style={{
                      fontSize: "1.5rem",
                    }}
                    className="p-3"
                  >
                    Once your perfect tutor match is found, you'll connect with
                    them through an online video call, allowing you to interact
                    and embark on your learning journey. During this
                    introduction, you will discuss your objectives, create a
                    plan of action, and set off confidently on the path to
                    achieving your goals. With IB Innovators, you have a
                    dedicated partner on your side.
                  </div>
                </div>

                <div style={{ flex: 1 }}>
                  <div
                    style={{
                      position: "relative",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={third}
                      alt="IBI Image"
                      style={{
                        width: "90%",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                </div>
              </div>

              {/* {successJourney.map((item, i) => {
            return (
              <div className='col-md-12 mt-4' key={i}>
                <ImageByContentCard
                  colDes={{
                    image: "2",
                    content: "10"
                  }}
                  title={item.title}
                  content={item.content}
                  imageTag={item.icon}
                />
              </div>
            )
          })} */}
            </div>
            <div className="row  mt-5 mb-5 ">
              <div
                className="homePageHeadings textTheme text-center"
                style={{
                  fontSize: isMobile ? "2rem" : "2.5rem",
                  color: "rgb(14, 56, 136)",
                  fontWeight: "bold",
                }}
              >
                What Happens Next - Guided to Excellence
              </div>
              <div
                className=" text-center mt-2 mb-5"
                style={{ fontSize: isMobile ? "1.5rem":"2rem", color: "rgb(14, 56, 136)" }}
              >
                At{" "}
                <b>
                  IB{" "}
                  <span style={{ color: "rgb(156, 16, 16)" }}>INNOVATORS</span>
                </b>
                , our commitment to your success doesn't end with the
                introductory session. Our well-structured process ensures
                continuous support and progress
              </div>
              {guide.map((item, i) => {
                return (
                  <div key={i}>
                    <HeaderCard title={item.title} content={item.content} />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          padding: "2rem",
        }}
      >
        <div
          style={{
            maxWidth: "1800px",
            margin: "0 auto",
          }}
        >
          <div
            style={{
              fontSize: isMobile ? "2rem" : "2.5rem",
              fontWeight: "bold",
              color: "rgb(14, 56, 136)",
            }}
            className=" textTheme text-center"
          >
            Our Guarantee - A Commitment to Your Success
          </div>
          <div
            style={{ marginBottom: "1.8rem", fontSize: "1.5rem" }}
            className=" mt-3 text-center"
          >
            At IB Innovators, we are driven by your success, and we back it up
            with our guarantee
          </div>

          <div
            style={{
              flexDirection: isMobile ? "column" : "row",
              flex: 1,
              display: "flex",
            }}
          >
            {/* #FIRST PART */}
            <div
              style={{
                flex: 1,
                border: "3px solid rgb(14, 56, 136)",
                borderRadius: "2rem",
                margin: isMobile ? "0.5rem" : "1rem",
                padding: "1rem",
                position: "relative", // Make the parent container a positioning context
                color: " rgb(14, 56, 136)",
                flexDirection: "row",
                display: "flex",
              }}
            >
              <div>
                <div style={{ position: "absolute", top: 10, right: 10 }}>
                  <img src={image2} style={{ height: "4rem" }} alt="Tick" />
                </div>
                <div style={{ marginTop: isMobile? '60px' :  "20px" }}>
                  <h2
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: isMobile ? "2rem" : "2.5rem",
                    }}
                  >
                    Expert Tutors
                  </h2>
                </div>
                <div>
                  <p style={{ textAlign: "center", fontSize: isMobile ? "1.5rem":"1.8rem", marginTop: '10px' }}>
                    Our tutors have earned at least 40 points on their IB exams
                    and achieved a 7 in the IB subjects they guide you through.
                    Expect exceptional explanatory and interpersonal skills to
                    receive the best support possible.
                  </p>
                </div>
              </div>
            </div>

            {/* SECOND PART */}
            <div
              style={{
                flex: 1,
                border: "3px solid rgb(14, 56, 136)",
                borderRadius: "2rem",
                margin: isMobile ? "0.5rem" : "1rem",
                padding: "1rem",
                position: "relative", // Make the parent container a positioning context
                color: " rgb(14, 56, 136)",
                flexDirection: "row",
                display: "flex",
              }}
            >
              <div>
                <div style={{ position: "absolute", top: 10, right: 10 }}>
                  <img src={image2} style={{ height: "4rem" }} alt="Tick" />
                </div>
                <div style={{ marginTop: isMobile? '60px' :  "20px"  }}>
                  <h2
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: isMobile ? "2rem" : "2.5rem",
                    }}
                  >
                    100% Satisfaction
                  </h2>
                </div>
                <div>
                  <p style={{ textAlign: "center", fontSize: isMobile ? "1.5rem":"1.8rem", marginTop: '10px' }}>
                    Your satisfaction is paramount to us. If, after two hours of
                    your booked package, you are not entirely satisfied with the
                    support received, we offer a complete refund.
                  </p>
                </div>
              </div>
            </div>

            {/* THIRD PART */}
            <div
              style={{
                flex: 1,
                border: "3px solid rgb(14, 56, 136)",
                borderRadius: "2rem",
                margin: isMobile ? "0.5rem" : "1rem",
                padding: "1rem",
                position: "relative", // Make the parent container a positioning context
                color: " rgb(14, 56, 136)",
                flexDirection: "row",
                display: "flex",
              }}
            >
              <div>
                <div style={{ position: "absolute", top: 10, right: 10 }}>
                  <img src={image2} style={{ height: "4rem" }} alt="Tick" />
                </div>
                <div style={{ marginTop: isMobile? '60px' :  "20px" }}>
                  <h2
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: isMobile ? "2rem" : "2.5rem",
                    }}
                  >
                    Quality Control
                  </h2>
                </div>
                <div>
                  <p style={{ textAlign: "center", fontSize: isMobile ? "1.5rem":"1.8rem", marginTop: '10px' }}>
                    Our commitment extends to the quality of your learning
                    experience. We personally check in with all students after
                    their first session to ensure satisfaction. If expectations
                    are not met, rest assured, we'll find a solution
                    immediately.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style={{ padding: "1rem", backgroundColor: "#191919" }}>
        <ol className="list-group" style={{maxWidth: '1800px', margin: '0px auto'}}>
          <li
            style={{ padding: "1rem", borderRadius: "1.5rem" }}
            className="list-group-item d-flex justify-content-between align-items-start"
          >
            <div className="ms-2 me-auto">
              <div
                className="fw-bold  textTheme mt-2"
                style={{
                  fontSize: "1.8rem",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img src={image} style={{ height: "3rem" }} alt="Tick" />{" "}
                Customer Support - Long-Term Partnership
              </div>
              <div className=" mt-3" style={{ fontSize: "1.4rem" }}>
                <React.Fragment>
                  With{" "}
                  <span style={{ color: "rgb(14, 56, 136)" }}>
                    {" "}
                    <b>
                      IB{" "}
                      <span style={{ color: "rgb(156, 16, 16)" }}>
                        INNOVATORS
                      </span>
                    </b>
                  </span>
                  , you are never alone on your IB journey. Our customer support
                  team pledges to be by your side throughout your entire
                  academic odyssey. When you reach out to us, expect a response
                  within 24 hours.
                  <br />
                  <button
                    onClick={() => {
                      navigate("/login");
                    }}
                    className="mainButton"
                    style={{
                      marginBottom: "0.5rem",
                      marginTop: "1rem",
                      borderTopLeftRadius: "0px",
                      padding: "0.5rem",
                      backgroundColor: "rgb(14, 56, 136)",
                    }}
                  >
                    JOIN NOW
                  </button>
                </React.Fragment>
              </div>
            </div>
          </li>
        </ol>
      </div>
    </>
  );
}
