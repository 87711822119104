import React from 'react'
import { useContext } from 'react'
import { MyContext } from '../../../Context/MyContext'

export default function QueryFormSecondStep(props) {

    const {studentForm, setStudentForm}=useContext(MyContext);

    const { selectedCallBack, selectedProgram } = props
    const renderElement = () => {
        if (selectedProgram === "IB") {
            return (
                <React.Fragment>
                    <div className="d-grid gap-2 mb-4">
                        <div className='btn-group'>
                            <button
                                className="btn btn-outline-info"
                                onClick={() => {
                                    setStudentForm((prevStudentForm) => ({
                                        ...prevStudentForm,
                                        Andwhenwillyousityourfinalexams: 'First Year May 2024',
                                        }));
                                        selectedCallBack("IB", 3)}}
                            >
                                First Year May 2024  <i className="fa-solid fa-right-long ps-2"></i>
                            </button>
                            <button
                                className="btn btn-outline-info"
                                onClick={() => {setStudentForm((prevStudentForm) => ({
                                    ...prevStudentForm,
                                    Andwhenwillyousityourfinalexams: 'Final Year November 2023',
                                    }));
                                    selectedCallBack("IB", 3)}}>
                                Final Year November 2023  <i className="fa-solid fa-right-long ps-2"></i>
                            </button>
                        </div>
                        <div className='btn-group'>
                            <button
                                className="btn btn-outline-info"
                                onClick={() => {
                                    setStudentForm((prevStudentForm) => ({
                                        ...prevStudentForm,
                                        Andwhenwillyousityourfinalexams: 'First Year May 2025',
                                        }));selectedCallBack("IB", 3)}}>

                                First Year May 2025  <i className="fa-solid fa-right-long ps-2"></i>
                            </button>
                            <button
                                className="btn btn-outline-info"
                                onClick={() => {
                                    setStudentForm((prevStudentForm) => ({
                                        ...prevStudentForm,
                                        Andwhenwillyousityourfinalexams: 'Final Year November 2024',
                                        }));
                                        selectedCallBack("IB", 3)}}>

                                Final Year November 2024  <i className="fa-solid fa-right-long ps-2"></i>
                            </button>
                        </div>
                        <div className='btn-group'>
                            <button
                                className="btn btn-outline-info"
                                onClick={() => {
                                    setStudentForm((prevStudentForm) => ({
                                        ...prevStudentForm,
                                        Andwhenwillyousityourfinalexams: 'First Year May 2026',
                                        }));
                                        selectedCallBack("IB", 3)}}>
                                First Year May 2026  <i className="fa-solid fa-right-long ps-2"></i>
                            </button>
                            <button
                                className="btn btn-outline-info"
                                onClick={() => {
                                    setStudentForm((prevStudentForm) => ({
                                        ...prevStudentForm,
                                        Andwhenwillyousityourfinalexams: 'Final Year November 2025',
                                        }));
                                        selectedCallBack("IB", 3)}}>
                                Final Year November 2025  <i className="fa-solid fa-right-long ps-2"></i>
                            </button>
                        </div>
                    </div>
                </React.Fragment>

            )
        } else if (selectedProgram === "MYP") {
            return (
                <React.Fragment>
                    <div className="d-grid gap-2 mb-4">
                        <button
                            className="btn btn-outline-info"
                            onClick={() => selectedCallBack("MYP", 3)}
                        >
                            MYP 5 (Final Year)  <i className="fa-solid fa-right-long ps-2"></i>
                        </button>
                        <button
                            className="btn btn-outline-info"
                            onClick={() => selectedCallBack("MYP", 3)}>
                            MYP 4  <i className="fa-solid fa-right-long ps-2"></i>
                        </button>
                        <button
                            className="btn btn-outline-info"
                            onClick={() => selectedCallBack("MYP", 3)}>
                            MYP 3 <i className="fa-solid fa-right-long ps-2"></i>
                        </button>
                        <button
                            className="btn btn-outline-info"
                            onClick={() => selectedCallBack("MYP", 3)}>
                            MYP 2  <i className="fa-solid fa-right-long ps-2"></i>
                        </button>
                        <button
                            className="btn btn-outline-info"
                            onClick={() => selectedCallBack("MYP", 3)}>
                            MYP 1  <i className="fa-solid fa-right-long ps-2"></i>
                        </button>
                    </div>
                </React.Fragment>
            )
        } else if (selectedProgram === "IGCSE") {
            return (
                <React.Fragment>
                    <div className="d-grid gap-2 mb-4">
                        <button
                            className="btn btn-outline-info"
                            onClick={() => selectedCallBack("IGCSE", 3)}
                        >
                            Final Year  <i className="fa-solid fa-right-long ps-2"></i>
                        </button>
                        <button
                            className="btn btn-outline-info"
                            onClick={() => selectedCallBack("IGCSE", 3)}>
                            First Year  <i className="fa-solid fa-right-long ps-2"></i>
                        </button>

                    </div>
                </React.Fragment>
            )
        }
    }

    return (
        <React.Fragment>
            <div className='formContent'>
                <div className='sideSectionMargin mt-5'>
                    <div className="homeParaFont mt-4 text-center text-dark">
                        <b>
                            Awesome.<br />
                            And when will you sit your final exams?
                        </b>
                    </div>
                    <div className='mt-4'>
                        {renderElement()}
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
