import { useState, useEffect } from 'react';
import { MyContext } from '../../Context/MyContext';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import { useContext } from 'react';
import { getDocs, collection, query, where, doc, updateDoc, setDoc, addDoc } from 'firebase/firestore';
import { db, storage } from '../../firebase';
import { useNavigate } from 'react-router-dom';
import { ref, uploadBytes, getDownloadURL } from '@firebase/storage';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import emailjs from "emailjs-com";
import { toast } from 'react-hot-toast';


export default function TutorForm() {

    
  const { subjectsArray, adminAddNotification } = useContext(MyContext);
  const [open, setOpen] = useState(false);


    const [validated, setValidated] = useState(false);

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [city, setCity] = useState('')
    const [state, setState] = useState('')
    const [zip, setZip] = useState('')
    const [subjects, setSubjects] = useState([])
    const [assignments, setAssignments] = useState([])
    const [curricula, setCurricula] = useState([])
    const [programmes, setProgrammes] = useState([])
    const [resume, setResume] = useState(null)
    const [agreed, setAgreed] = useState(false);
    const [error, setError] = useState(false)

    
  const [showModal, setShowModal] = useState(false);

    const navigate=useNavigate()




    const [isMobile, setIsMobile] = useState(false);

    const checkIsMobile = () => {
      setIsMobile(window.innerWidth <= 600); // You can adjust the width threshold as needed
    };


    
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isMobile?'90%':'60%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    maxHeight: '90%',
    overflow: 'scroll'
    
  };
  
    // Listen for window resize events to update isMobile state
    useEffect(() => {
      checkIsMobile(); // Initial check
      window.addEventListener("resize", checkIsMobile); // Add event listener
  
      // Clean up the event listener when the component unmounts
      return () => {
        window.removeEventListener("resize", checkIsMobile);
      };
    }, []);


    const handleSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setError(true);
        } else if (agreed === false) {
            event.preventDefault();
            event.stopPropagation();
            setError(true);
        } else if (resume === null) {
            event.preventDefault();
            event.stopPropagation();
            setError(true);
        } else {
            setValidated(true);
    
            // Upload the resume to Firebase Storage
            const storageRef = ref(storage, 'resumes/' + resume.name);
            await uploadBytes(storageRef, resume);
            const downloadURL = await getDownloadURL(storageRef);
    
            const formData = {
                firstName: firstName,
                lastName: lastName,
                email: email,
                city: city,
                state: state,
                zip: zip,
                subjects: subjects,
                assignments: assignments,
                curricula: curricula,
                programmes: programmes,
                resume: downloadURL, submittedAt: new Date
            };

            const tableRows = Object.entries(formData)
      .map(([key, value]) => {
          if (typeof value === 'object' && !Array.isArray(value)) {
              value = JSON.stringify(value);
          } else if (Array.isArray(value)) {
              value = value.join(', ');
          }
          return `
              <tr>
                  <td>${key}</td>
                  <td>${value}</td>
              </tr>
          `;
      })
      .join("");
    
            console.log('Form Data:', formData);
            setError(false);
    
            // POST DATA
            const ordersRef = collection(db, 'adminPanel');
    const customDocRef = doc(ordersRef, 'teacherForm');
    const teacherCollectionRef = collection(customDocRef, 'teacherForm');
    
            try {
                // Create the document and get the generated ID
                const docRef = await addDoc(teacherCollectionRef, formData);
                const id = docRef.id;
    
                // Update the document to include the ID
                await updateDoc(docRef, { id: id });
                await adminAddNotification(`Tutor Form was just submitted by ${formData?.email}`)

                const serviceId = process.env.REACT_APP_EMAILSERVICEID;
                const templateId = process.env.REACT_APP_EMAILTEMPLATEID;
                const userId = process.env.REACT_APP_EMAILUSERID;

      const emailParams = {
        from_name: "IBInnovators",
        to_name: "IBInnovators",
        send_to: "hr@ibinnovators.com", // Replace with your recipient's email address
        subject: "New Tutor Form Submission",
        message: `
        <html>
        <head>
        </head>
        <body>
        <p>A person just submitted Tutor Form:</p>
        <table style="border: 1px solid #000; border-collapse: collapse; width: 100%;">
        <tbody>
           ${tableRows}
        </body>
        </html>
        
    `,
      };
  
      emailjs
        .send(serviceId, templateId, emailParams, userId)
        .then((response) => {
          
            setShowModal(true);
        })
        .catch((error) => {
          console.error("Error sending email");
        });
    
            } catch (error) {
                toast.error("Error submitting form");
            }
        }
    }
    
    
    


    const handleSubjectsCheckboxChange = (event) => {
        const { value, checked } = event.target;
    
        if (checked) {
          // Add the selected subject to the array
          setSubjects((prevSubjects) => [...prevSubjects, value]);
        } else {
          // Remove the deselected subject from the array
          setSubjects((prevSubjects) => prevSubjects.filter((subject) => subject !== value));
        }
      };

      const handleAssignmentsCheckboxChange = (event) => {
        const { value, checked } = event.target;
    
        if (checked) {
          // Add the selected subject to the array
          setAssignments((prevSubjects) => [...prevSubjects, value]);
        } else {
          // Remove the deselected subject from the array
          setAssignments((prevSubjects) => prevSubjects.filter((subject) => subject !== value));
        }
      };

      const handleCurriculaCheckboxChange = (event) => {
        const { value, checked } = event.target;
    
        if (checked) {
          // Add the selected subject to the array
          setCurricula((prevSubjects) => [...prevSubjects, value]);
        } else {
          // Remove the deselected subject from the array
          setCurricula((prevSubjects) => prevSubjects.filter((subject) => subject !== value));
        }
      };

      const handleProgrammesCheckboxChange = (event) => {
        const { value, checked } = event.target;
    
        if (checked) {
          // Add the selected subject to the array
          setProgrammes((prevSubjects) => [...prevSubjects, value]);
        } else {
          // Remove the deselected subject from the array
          setProgrammes((prevSubjects) => prevSubjects.filter((subject) => subject !== value));
        }
      };

    return (
        <div className='tutorForm mb-5'>
            <div className="homePageHeadings text-center textTheme">
                <div>Academic Tutor at IBI</div>
            </div>
            <div className="p-5 border mt-3">
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="4" controlId="validationCustom01">
                            <Form.Label>First name</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                placeholder="First name"
                                size="sm"
                                onChange={(e) => {setFirstName(e.target.value)}}
                            />
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="validationCustom02">
                            <Form.Label>Last name</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                placeholder="Last name"
                                size="sm"
                                onChange={(e) => {setLastName(e.target.value)}}
                            />
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="validationCustomEmail">
    <Form.Label>Email</Form.Label>
    <InputGroup hasValidation size="sm">
        <Form.Control
            size="sm"
            type="email" 
            placeholder="Email"
            aria-describedby="inputGroupPrepend"
            required
            onChange={(e) => {setEmail(e.target.value)}}  
        />
        <Form.Control.Feedback type="invalid">
            Please enter a valid email.
        </Form.Control.Feedback>
    </InputGroup>
</Form.Group>

                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="6" controlId="validationCustom03">
                            <Form.Label>City</Form.Label>
                            <Form.Control onChange={(e) => {setCity(e.target.value)}} type="text" placeholder="City" size="sm" required />
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid city.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="3" controlId="validationCustom04">
                            <Form.Label>State</Form.Label>
                            <Form.Control onChange={(e) => {setState(e.target.value)}} type="text" placeholder="State" size="sm" required />
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid state.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="3" controlId="validationCustom05">
                            <Form.Label>Zip</Form.Label>
                            <Form.Control onChange={(e) => {setZip(e.target.value)}} type="text" placeholder="Zip" size="sm" required />
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid zip.
                            </Form.Control.Feedback>
                        </Form.Group>


                        <Form.Group as={Col} md="12" controlId="validationCustom05" className='mt-4'>
                            <Form.Label><b>IB Subjects you teach? (select all that apply)</b> <span className='text-danger'>*</span></Form.Label>
                            <div className='row mt-4'>
                            {subjectsArray.map((item, index) => 
                            <div className='col-md-4'>
                            <Form.Check
                                default
                                label={item}
                                value={item}
                                name="group1"
                                type={"checkbox"}
                                id={`default-checkbox-${index}`}
                                onChange={handleSubjectsCheckboxChange}
                            />
                        </div>
                        )}

                            </div>
                        </Form.Group>



                        <Form.Group as={Col} md="12" controlId="validationCustom05" className='mt-4'>
                            <Form.Label><b>Assignments you teach? (select all that apply)</b> <span className='text-danger'>*</span></Form.Label>
                            <div className='row mt-4 '>
                                <div className='col-md-4'>
                                    <Form.Check
                                        default
                                        label="Internal Assessment (IA)"
                                        value="Internal Assessment (IA)"
                                        name="group1"
                                        type={"checkbox"}
                                        id={`default-checkbox-51`}
                                        onChange={handleAssignmentsCheckboxChange}
                                    />
                                </div>
                                <div className='col-md-4'>
                                    <Form.Check
                                        default
                                        label="Individual Oral (IO)"
                                        value="Individual Oral (IO)"
                                        name="group1"
                                        type={"checkbox"}
                                        id={`default-checkbox-52`}
                                        onChange={handleAssignmentsCheckboxChange}
                                    />
                                </div>
                                <div className='col-md-4'>
                                    <Form.Check
                                        default
                                        label="Extended Essay (EE)"
                                        value="Extended Essay (EE)"
                                        name="group1"
                                        type={"checkbox"}
                                        id={`default-checkbox-53`}
                                        onChange={handleAssignmentsCheckboxChange}
                                    />
                                </div>
                                <div className='col-md-4'>
                                    <Form.Check
                                        default
                                        label="Personal Project MYP"
                                        value="Personal Project MYP"
                                        name="group1"
                                        type={"checkbox"}
                                        id={`default-checkbox-54`}
                                        onChange={handleAssignmentsCheckboxChange}
                                    />
                                </div>
                                <div className='col-md-4'>
                                    <Form.Check
                                        default
                                        label="HL Essay"
                                        value="HL Essay"
                                        name="group1"
                                        type={"checkbox"}
                                        id={`default-checkbox-55`}
                                        onChange={handleAssignmentsCheckboxChange}
                                    />
                                </div>
                                <div className='col-md-4'>
                                    <Form.Check
                                        default
                                        label="None"
                                        value="None"
                                        name="group1"
                                        type={"checkbox"}
                                        id={`default-checkbox-56`}
                                        onChange={handleAssignmentsCheckboxChange}
                                    />
                                </div>
                            </div>
                        </Form.Group>

                        <Form.Group as={Col} md="12" controlId="validationCustom05" className='mt-4'>
                            <Form.Label><b>Curricula taught (select all that apply)</b> <span className='text-danger'>*</span></Form.Label>
                            <div className='row mt-4'>
                                <div className='col-md-3'>
                                    <Form.Check
                                        default
                                        label="IBDP"
                                        value="IBDP"
                                        name="group1"
                                        type={"checkbox"}
                                        id={`default-checkbox-57`}
                                        onChange={handleCurriculaCheckboxChange}
                                    />
                                </div>
                                <div className='col-md-3'>
                                    <Form.Check
                                        default
                                        label="MYP"
                                        value="MYP"
                                        name="group1"
                                        type={"checkbox"}
                                        id={`default-checkbox-58`}
                                        onChange={handleCurriculaCheckboxChange}
                                    />
                                </div>
                                <div className='col-md-3'>
                                    <Form.Check
                                        default
                                        label="IGCSE"
                                        value="IGCSE"
                                        name="group1"
                                        type={"checkbox"}
                                        id={`default-checkbox-59`}
                                        onChange={handleCurriculaCheckboxChange}
                                    />
                                </div>
                            </div>
                        </Form.Group>



                        <Form.Group controlId="formFileSm" className="mb-3 mt-4">
  <Form.Label><b>Resume/CV</b> <span className='text-danger'>*</span></Form.Label>
  <Form.Control onChange={(e) => {setResume(e.target.files[0])}} type="file" size="sm" />
</Form.Group>
                    </Row>
                    <Form.Group className="mb-3">
                    <Form.Check
    required
    label="Agree to terms and conditions"
    feedback="You must agree before submitting."
    feedbackType="invalid"
    id={`default-checkbox-60`}
    onChange={(event) => {
        const checked = event.target.checked;
        setAgreed(checked);
        if (checked) {
            setOpen(true);
        } else {
            setOpen(false); // Optionally, set 'Open' to false when unchecked
        }
    }}
/>

                    </Form.Group>
                    <button  type="submit" style={{borderTopLeftRadius: '0px', padding: '0.5rem'}} className='mainButton'>Submit form</button>
                    {error && <div style={{color: 'red'}}>Please fill out all the fields.</div>}
                </Form>
            </div>
            {
  showModal && (
    <div
      style={{
        position: "fixed",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.8)",
        zIndex: "9999",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "300px",
          padding: "20px",
          borderRadius: "10px",
          backgroundColor: "#fff",
          textAlign: "center",
          boxShadow: "0px 0px 10px 2px rgba(0, 0, 0, 0.2)",
        }}
      >
        <h2
          style={{
            margin: "0 0 20px",
            color: "#333",
            fontSize: "24px",
          }}
        >
          Thank You!
        </h2>
        <p
          style={{
            margin: "0 0 20px",
            color: "#555",
            fontSize: "16px",
          }}
        >
          We have received your form and will be in contact with you soon.
        </p>
        <button
          onClick={() => {
            setShowModal(false);
            navigate('/', {replace: true})
          }}
          style={{
            padding: "10px 20px",
            backgroundColor: "rgb(156, 16, 16)",
            border: "none",
            borderRadius: "5px",
            color: "#fff",
            fontSize: "16px",
          }}
        >
          OK
        </button>
      </div>
    </div>
  )
}




<Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
            
          <h1>Terms and Conditions</h1>
          <div style={{}}>
          Sample Website Terms and Conditions Template
Please read these terms and conditions ("terms and conditions", "terms") carefully before using [website URL] website (“website”, "service") operated by [company name] ("us", 'we", "our").
Conditions of use
By using this website, you certify that you have read and reviewed this Agreement and that you agree to comply with its terms. If you do not want to be bound by the terms of this Agreement, you are advised to stop using the website accordingly. [company name] only grants use and access of this website, its products, and its services to those who have accepted its terms.
Privacy policy
Before you continue using our website, we advise you to read our privacy policy [link to privacy policy] regarding our user data collection. It will help you better understand our practices.
Age restriction
You must be at least 18 (eighteen) years of age before you can use this website. By using this website, you warrant that you are at least 18 years of age and you may legally adhere to this Agreement. [company name] assumes no responsibility for liabilities related to age misrepresentation.
Intellectual property
You agree that all materials, products, and services provided on this website are the property of [company name], its affiliates, directors, officers, employees, agents, suppliers, or licensors including all copyrights, trade secrets, trademarks, patents, and other intellectual property. You also agree that you will not reproduce or redistribute the [company name]’s intellectual property in any way, including electronic, digital, or new trademark registrations.
You grant [company name] a royalty-free and non-exclusive license to display, use, copy, transmit, and broadcast the content you upload and publish. For issues regarding intellectual property claims, you should contact the company in order to come to an agreement.
User accounts
As a user of this website, you may be asked to register with us and provide private information. You are responsible for ensuring the accuracy of this information, and you are responsible for maintaining the safety and security of your identifying information. You are also responsible for all activities that occur under your account or password.
If you think there are any possible issues regarding the security of your account on the website, inform us immediately so we may address them accordingly.
We reserve all rights to terminate accounts, edit or remove content and cancel orders at our sole discretion.
Applicable law
By using this website, you agree that the laws of the [your location], without regard to principles of conflict laws, will govern these terms and conditions, or any dispute of any sort that might come between [company name] and you, or its business partners and associates.
Disputes
Any dispute related in any way to your use of this website or to products you purchase from us shall be arbitrated by state or federal court [your location] and you consent to exclusive jurisdiction and venue of such courts.
Indemnification
You agree to indemnify [company name] and its affiliates and hold [company name] harmless against legal claims and demands that may arise from your use or misuse of our services. We reserve the right to select our own legal counsel. 
Limitation on liability
[company name] is not liable for any damages that may occur to you as a result of your misuse of our website.
[company name] reserves the right to edit, modify, and change this Agreement at any time. We shall let our users know of these changes through electronic mail. This Agreement is an understanding between [company name] and the user, and this supersedes and replaces all prior agreements regarding the use of this website.

PRO TIP: Save your time and take the guesswork out of the legal jargon with the professional terms and conditions generator trusted by thousands of small businesses.


          </div>
        <button style={{marginTop: '1rem'}} onClick={() => {setOpen(false)}}>Agree to All</button>
        </Box>
      </Modal>


        </div>
    )
}
