import React from 'react';
import App from './App';
import ReactDOM from 'react-dom';
import './index.css'
import './style/font-awesome/css/all.min.css'
import { ContextProvider } from './Context/MyContext';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';



export const stripePromise = loadStripe(
  'pk_test_OKDSvqXf1ehDBRLacTm9DQsT00QoSQbq2h'
);

ReactDOM.render(
  <React.StrictMode>
    <ContextProvider>
      <Elements stripe={stripePromise}>
    <App />
      </Elements>
    </ContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

