import React, { useState, useEffect } from "react";
import {
  Button,
  FormControlLabel,
  FormGroup,
  Typography,
  Container,
  TextField,
  Box,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Checkbox,
} from "@mui/material";

export function RevPageNoTwo({
  setPageNo,
  pageNo,
  setActivePage,
  setRegisterModalOpen,
  fullFormData,
  setFullFormData,
  isMobile
}) {
  useEffect(() => {
    setActivePage(3);
  }, []);


  const isPredefinedDate = (date) => {
    if (!date || typeof date !== "string") {
      return false;
    }
  
    const predefinedMonths = ["May", "November"];
    const predefinedYears = ["2023", "2024", "2025", "2026", "2027", "2028", "2029", "2030"];
    const [month, year] = date.split(" ");
  
    return predefinedMonths.includes(month) && predefinedYears.includes(year);
  };
  

  const initialOtherChecked = !isPredefinedDate(fullFormData.finalExamDate);
 const initialMonth = initialOtherChecked ? "" : fullFormData?.finalExamDate?.split(" ")[0];
  const initialYear = initialOtherChecked ? "" : fullFormData?.finalExamDate?.split(" ")[1];
  const initialOtherText = initialOtherChecked ? fullFormData?.finalExamDate : "";

  const [finalExamDate, setFinalExamDate] = useState(fullFormData?.finalExamDate || "");
  const [otherChecked, setOtherChecked] = useState((fullFormData.finalExamDate && fullFormData.finalExamDate!=="") ? initialOtherChecked : false);
  const [otherText, setOtherText] = useState(initialOtherText);
  const [month, setMonth] = useState(initialMonth);
  const [year, setYear] = useState(initialYear);

  useEffect(() => {
    if (month && year) {
      setFinalExamDate(`${month} ${year}`);
    }
  }, [month, year]);

  const handleOptionChange = (event) => {
    const { value, checked } = event.target;
    if (value === "Other") {
      setOtherChecked(checked);
      if (!checked) {
        setOtherText("");
      }
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    let updatedFinalExamDate = finalExamDate;

    if (finalExamDate.length !== 0) {
      // if (otherChecked && otherText) {
      //   updatedFinalExamDate = otherText;
      // }

      setFullFormData({ ...fullFormData, finalExamDate: updatedFinalExamDate });
      setPageNo(pageNo + 1);
    } else {
      alert("Please select an option");
    }
  };

  return (
    <div>
      <Typography variant="h5" sx={{  mb: isMobile ? 2 : 4, fontWeight: "bold", fontSize: isMobile ? "1.1rem" : "1.3rem"  }}>
        When are you due to finish your academic program?
      </Typography>
      
      <Typography variant="h6" sx={{fontSize: isMobile ? "1.1rem" : "1.3rem"  }}>
      When is your final exam date? <span style={{ color: 'red' }}>*</span>
        </Typography>

      <form onSubmit={handleSubmit}>
        {otherChecked ? (
          <TextField
            required
            fullWidth
            value={finalExamDate}
            onChange={(e) => setFinalExamDate(e.target.value)}
            label="Enter other exam date"
            sx={{ mt: 2 }}
          />
        ) : (
          <>
            <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel id="demo-simple-select-label">Select Month</InputLabel>
              <Select
                label="Select Month"
                value={month}
                fullWidth
                onChange={(e) => setMonth(e.target.value)}
              >
                <MenuItem value="May">May Session</MenuItem>
                <MenuItem value="November">November Session</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel >Select Year</InputLabel>
              <Select
                label="Select Year"
                value={year}
                fullWidth
                onChange={(e) => setYear(e.target.value)}
              >
                {Array.from({ length: 8 }, (_, i) => 2023 + i).map((year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        )}

        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={otherChecked}
                onChange={handleOptionChange}
                value="Other"
              />
            }
            label="Other"
          />
        </FormGroup>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            gap: "10px",
            justifyContent: "flex-end",
            mt: 4,
            flexWrap: "wrap",
          }}
        >
          <Button
            variant="outlined"
            sx={{ fontSize: '1rem' }}
            type="button"
            onClick={() => {
              setPageNo(pageNo - 1);
            }}
          >
            Previous
          </Button>
          <Button
            disabled={finalExamDate.length === 0 || (!otherChecked && (!month || !year))}
            variant="contained"
            sx={{ background: "#14549f", padding: '5px 20px', fontSize: '1rem' }}
            type="submit"
          >
            Next
          </Button>
        </Box>
      </form>
    </div>
  );
}
