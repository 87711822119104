import React, { useState, useEffect, useContext } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import { addDoc, collection, doc, updateDoc } from "firebase/firestore";
import emailjs from "emailjs-com";
import { db } from "../../firebase";
import { MyContext } from "../../Context/MyContext";
import {
  Radio,
  RadioGroup,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Slider,
  TextField,
  Typography,
} from "@mui/material";
import { GuidanceAndSupport } from "./GuidanceAndSupport";
import toast from "react-hot-toast";

export function RevPageNoFive({
  setRegisterModalOpen,
  setPageNo,
  pageNo,
  setActivePage,
  fullFormData,
  setFullFormData,
  isMobile,
}) {
  useEffect(() => {
    setActivePage(7);
  }, []);

  const [objective, setObjective] = useState([]);
  const [otherText, setOtherText] = useState("");

  const handleCurriculaCheckboxChange = (event) => {
    const { value, checked } = event.target;

    if (checked) {
      // Add the selected subject to the array
      setObjective((prevSubjects) => [...prevSubjects, value]);
    } else {
      // Remove the deselected subject from the array
      setObjective((prevSubjects) =>
        prevSubjects.filter((subject) => subject !== value)
      );
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let updatedObjective = objective; // Create a copy to prevent mutation

    if (objective.length !== 0) {
      if (objective.includes("Other")) {
        updatedObjective = objective.filter((item) => item !== "Other");
        if (otherText) {
          updatedObjective = [...updatedObjective, otherText];
        }
      }

      // Update state variables
      setObjective(updatedObjective);

      // Set fullFormData
      setFullFormData({
        ...fullFormData,
        objective: updatedObjective,
      });

      setPageNo((prev) => prev + 1);
    } else {
      toast.error("Please fill all fields");
    }
  };


  return (
    <div>

      
        <div
          style={{
            marginBottom: "2rem",
            paddingBottom: "3rem",
            borderBottom: "1px solid #ddd",
          }}
        >
          <div style={{ fontSize: "1.3rem", marginBottom: '10px' }}>
            <b>Review your Session Pricing:</b>
          </div>
          <div>
            <b>One-on-One Tutoring Session</b>
          </div>
          
          <div
            style={{
              display: "flex",
              flex: 1,
              gap: "10px",
              justifyContent: "space-between",
            }}
          >
            <div>Tutor Support Type</div>
            <div>Certified IB Examiner</div>
          </div>


          <div
            style={{
              display: "flex",
              flex: 1,
              gap: "10px",
              justifyContent: "space-between",
            }}
          >
            <div>Subjects</div>
            <div>
              <b>
                {fullFormData?.desiredCourse?.subjects < 10
                  ? `0${fullFormData?.desiredCourse?.subjects}`
                  : fullFormData?.desiredCourse?.subjects}
              </b>
            </div>
          </div>

          

          <div
            style={{
              display: "flex",
              flex: 1,
              gap: "10px",
              justifyContent: "space-between",
            }}
          >
            <div>Course duration</div>
            <div>
              <b>
                {fullFormData?.desiredCourse?.courseHours}
              </b>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flex: 1,
              gap: "10px",
              justifyContent: "space-between",
              padding: "0.5rem 0rem",
              borderBottom: "1px solid #6e6e6e",
              borderTop: "1px solid #6e6e6e",
              fontWeight: "bolder",
              marginTop: '5px'
            }}
          >
            <div>Price (£)</div>
            <div>{fullFormData?.desiredCourse?.price} GBP</div>
          </div>
        </div>

      <Form onSubmit={handleSubmit}>
        <Form.Group
          as={Col}
          md="12"
          controlId="validationCustom05"
          className="mt-4"
        >
          <Form.Label style={{ fontSize: "1.2rem" }}>
            What's your fundamental objective?{" "}
            <span className="text-danger">*</span>
          </Form.Label>
          <Grid container spacing={0} style={{ flex: 1, minWidth: "300px" }}>
            {[
              "Improve my Grade",
              "Prepare for my Final Exams",
              "Boost my Grip on Subjects",
              "Fill in Subject Gaps",
              "Other",
            ].map((label, index) => (
              <Grid item xs={12} sm={isMobile ? 12 : 6} key={index}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={objective.includes(label)}
                      onChange={handleCurriculaCheckboxChange}
                      value={label}
                    />
                  }
                  label={label}
                  style={{ fontSize: "1.2rem" }}
                />
                {label === "Other" && objective.includes("Other") && (
                  <TextField
                    style={{ fontSize: "1.2rem" }}
                    onChange={(e) => {
                      setOtherText(e.target.value);
                    }}
                    required
                    type={"text"}
                    label="Enter here"
                    fullWidth
                  />
                )}
              </Grid>
            ))}
          </Grid>
        </Form.Group>

        {/* <Form.Group
          as={Col}
          md="12"
          controlId="validationCustom05"
          className="mt-4"
        >
          <Form.Label style={{ fontSize: "1.3rem" }}>
          Do you also want guidance and support for your internal assessment or extended essay for your chosen subjects?{" "}
            <span className="text-danger">*</span>
          </Form.Label>
          <RadioGroup
            aria-label="guidance and support"
            name="guidanceAndSupport"
            value={wantGuidanceAndSupport}
            onChange={handleRadioChange}
          >
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="no" control={<Radio />} label="No" />
          </RadioGroup>
        </Form.Group> */}

        <GuidanceAndSupport
          setRegisterModalOpen={setRegisterModalOpen}
          setPageNo={setPageNo}
          pageNo={pageNo}
          setActivePage={setActivePage}
          fullFormData={fullFormData}
          setFullFormData={setFullFormData}
          isMobile={isMobile}
        />

        <Box
          sx={{
            width: "100%",
            display: "flex",
            gap: "10px",
            justifyContent: "flex-end",
            mt: 4,
            flexWrap: "wrap",
          }}
        >
          <Button
            variant="outlined"
            // color="error"
            sx={{ fontSize: "1rem" }}
            type="button"
            onClick={() => {
              setPageNo(pageNo - 1);
            }}
          >
            Previous
          </Button>
          <Button
            variant="contained"
            sx={{
              background: "#14549f",
              padding: "5px 20px",
              fontSize: "1rem",
            }}
            disabled={
              objective?.length === 0 ||
              !fullFormData?.wantGuidanceAndSupport ||
              fullFormData?.wantGuidanceAndSupport === "" ||
              (fullFormData?.wantGuidanceAndSupport === "yes" &&
                fullFormData?.guidancePrice <= 0)
            }
            type="submit"
          >
            Next
          </Button>
        </Box>
      </Form>
    </div>
  );
}
