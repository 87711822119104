import { useState, useEffect, useContext } from "react";
import React from "react";
import ComingSoon from "../../Components/ComingSoon";
import igcseImage from '../../assets/igcseTutoring.png'
import { MyContext } from "../../Context/MyContext";
import { Helmet } from "react-helmet";

export function IgcseTutoring(){

    const [isRealMobile, setIsRealMobile] = useState(false)

    const {seoData} = useContext(MyContext)
    const [thisPageSEO, setThisPageSEO] = useState({});
    const [imageLoaded, setImageLoaded] = useState(false);
  
    useEffect(() => {
      if(seoData){
        setThisPageSEO(seoData?.find((e) => e?.pageName === "IGCSE Tutoring"))
      }
    }, [seoData])
  

    const checkIsMobile = () => {
      setIsRealMobile(window.innerWidth <= 800)
    };
  
    useEffect(() => {
      checkIsMobile(); // Initial check
      window.addEventListener("resize", checkIsMobile); // Add event listener
  
      // Clean up the event listener when the component unmounts
      return () => {
        window.removeEventListener("resize", checkIsMobile);
      };
    }, []);



    
  const createHelmetTags = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const headTags = Array.from(doc.head.children);
  
    return headTags.map((tag, index) => {
      const tagName = tag.tagName.toLowerCase();
  
      if (tagName === 'title') {
        return <title key={index}>{tag.innerText}</title>;
      } else if (tagName === 'meta') {
        const attributes = Array.from(tag.attributes).reduce((acc, attr) => {
          acc[attr.name] = attr.value;
          return acc;
        }, {});
        return <meta key={index} {...attributes} />;
      } else if (tagName === 'link') {
        const attributes = Array.from(tag.attributes).reduce((acc, attr) => {
          acc[attr.name] = attr.value;
          return acc;
        }, {});
        return <link key={index} {...attributes} />;
      } else if (tagName === 'script' && tag.type === 'application/ld+json') {
        return <script key={index} type="application/ld+json">{tag.innerText}</script>;
      } else {
        return null;
      }
    });
  };

  

    return(
        <>
<Helmet>
      {thisPageSEO?.schema && createHelmetTags(thisPageSEO.schema)}
    </Helmet>


    <div style={{flex: 1, width: '100%', background: 'red', height: imageLoaded ? "max-content" : '600px', background: '#14549f'}}>
      <div style={{ maxWidth: '1800px', flex: 1, margin: '0px auto' }}>
        {isRealMobile ? (
          <img
            src={igcseImage}
            width="100%"
            alt=""
            onLoad={() => setImageLoaded(true)}
            height="auto"
            style={{maxWidth: '1800px'}}
          />
        ) : (
          <img src={igcseImage} onLoad={() => setImageLoaded(true)} width="100%" alt="" height="auto" 
          />
        )}
      </div>
      </div>

        {/* <div style={{ flex: 1 }}>
        {!isRealMobile ? (
          <img
          src={igcseImage} alt="IGCSE TUTORING" 
            width="100%"
            height="auto"
          />
        ) : (
          <img
            src={igcseImage}
            width="100%"
            alt="IGCSE TUTORING" 
            height="auto"
          />
        )}
        
      </div> */}
      <ComingSoon />
      </>
    )
}