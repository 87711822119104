import { Autocomplete, Box, Button, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import { MuiTelInput } from "mui-tel-input";
import { MyContext } from "../../Context/MyContext";

export function RevPageNoFour({
  setRegisterModalOpen,
  setPageNo,
  pageNo,
  setActivePage,
  fullFormData,
  setFullFormData,
  isMobile
}) {
  useEffect(() => {
    setActivePage(5);
  }, []);

  const [validated, setValidated] = useState(false);
  const { countries, timezones } = useContext(MyContext);
  const [firstName, setFirstName] = useState(
    fullFormData?.userDetails?.firstName
  );
  const [lastName, setLastName] = useState(fullFormData?.userDetails?.lastName);
  const [dateOfBirth, setDateOfBirth] = useState(
    fullFormData?.userDetails?.dateOfBirth
  );
  const [phone, setPhone] = useState(fullFormData?.userDetails?.phone);
  const [email, setEmail] = useState(fullFormData?.userDetails?.email);
  const [school, setSchool] = useState(fullFormData?.userDetails?.school);
  const [city, setCity] = useState(fullFormData?.userDetails?.city);
  const [state, setState] = useState(fullFormData?.userDetails?.state);
  const [zip, setZip] = useState(fullFormData?.userDetails?.zip);
  const [address, setAddress] = useState(fullFormData?.userDetails?.address);
  const [addressLineTwo, setAddressLineTwo] = useState(
    fullFormData?.userDetails?.addressLineTwo
  );
  const [country, setCountry] = useState(fullFormData?.userDetails?.country);
  const [gmtTimezone, setGmtTimezone] = useState(fullFormData?.userDetails?.gmtTimezone || "")

  const [relation, setRelation] = useState(fullFormData?.userDetails?.relation);
  const [parentFirstName, setParentFirstName] = useState(
    fullFormData?.userDetails?.parentFirstName
  );
  const [parentLastName, setParentLastName] = useState(
    fullFormData?.userDetails?.parentLastName
  );
  const [parentPhone, setParentPhone] = useState(
    fullFormData?.userDetails?.parentPhone
  );
  const [parentEmail, setParentEmail] = useState(
    fullFormData?.userDetails?.parentEmail
  );

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (!phone) {
      alert("Student Phone number is required");
    } else if (!email) {
      alert("Student Email is required");
    } else if (!relation) {
      alert("Parent Relation is required");
    } else if (!parentPhone) {
      alert("Parent phone number is required");
    } else if (!parentEmail) {
      alert("Parent email is required");
    } else {
      setValidated(true);

      // Storing all data in a variable
      const allData = {
        firstName,
        lastName,
        phone,
        email,
        city,
        state,
        zip,
        country,
        address,
        addressLineTwo,
        relation,
        parentFirstName,
        parentLastName,
        parentPhone,
        parentEmail,
        gmtTimezone
      };

      setFullFormData({ ...fullFormData, userDetails: allData });
      setPageNo(pageNo + 1);
    }
  };

  useEffect(() => {
    const allData = {
      firstName,
      lastName,
      phone,
      email,
      city,
      state,
      zip,
      country,
      address,
      addressLineTwo,
      relation,
      parentFirstName,
      parentLastName,
      parentPhone,
      parentEmail,
      gmtTimezone,
    };

    const handler = setTimeout(() => {
      setFullFormData(prevData => ({ ...prevData, userDetails: allData }));
    }, 500); // 1 second delay

    return () => {
      clearTimeout(handler);
    };
  }, [
    firstName,
    lastName,
    phone,
    email,
    city,
    state,
    zip,
    country,
    address,
    addressLineTwo,
    relation,
    parentFirstName,
    parentLastName,
    parentPhone,
    parentEmail,
    gmtTimezone,
  ]);

  return (
    <div>
      <div
        style={{
          fontSize: "1.3rem",
          marginBottom: "1rem",
          textAlign: "left",
          borderBottom: "1px solid #ccc",
          padding: '10px 0px'
        }}
        className="homeParaFont text-dark"
      >
        <b>Student Information</b>
      </div>
      <form onSubmit={handleSubmit}>
        <div>
          <Form.Label style={{ fontSize: "1.2rem" }}>
            Contact Information
          </Form.Label>
          <div
            style={{ gap: "10px", display: "flex", flexWrap: "wrap", flex: 1 }}
          >
            <TextField
              label="First Name"
              required
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              style={{ flex: 1, minWidth: "300px" }}
            />

            <TextField
              label="Last Name"
              required
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              style={{ flex: 1, minWidth: "300px" }}
            />
          </div>

          <div
            style={{
              gap: "10px",
              marginTop: "10px",
              display: "flex",
              flexWrap: "wrap",
              flex: 1,
            }}
          >
            <TextField
              label="Email"
              value={email}
              type="email"
              required
              onChange={(e) => setEmail(e.target.value)}
              style={{ flex: 1, minWidth: "300px" }}
            />

            <MuiTelInput
              required
              value={phone}
              label="Phone"
              style={{ flex: 1, minWidth: "300px" }}
              onChange={(e) => setPhone(e)}
            />
          </div>

          <Form.Label style={{ fontSize: "1.2rem", marginTop: "2rem" }}>
            Address
          </Form.Label>
          <div
            style={{ gap: "10px", display: "flex", flexWrap: "wrap", flex: 1 }}
          >
            <TextField
              label="Street Address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              style={{ flex: 1, minWidth: "300px" }}
            />
          </div>

          {/* <div style={{gap: '10px', marginTop: '10px', display: 'flex', flexWrap: "wrap" , flex: 1}}>
            <TextField 
              label="Address Line 2"
              value={addressLineTwo}
              onChange={(e) => setAddressLineTwo(e.target.value)}
              style={{flex: 1, minWidth: '300px'}}
              />
          </div> */}

          <div
            style={{
              gap: "10px",
              marginTop: "10px",
              display: "flex",
              flexWrap: "wrap",
              flex: 1,
            }}
          >
            <TextField
              label="City"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              style={{ flex: 1, minWidth: "200px" }}
            />

            <TextField
              label="Zip"
              value={zip}
              onChange={(e) => setZip(e.target.value)}
              style={{ flex: 1, minWidth: "200px", width: "100%" }}
            />

            <Autocomplete
              id="country-select-demo"
              options={countries}
              style={{ flex: 1, minWidth: "200px" }}
              value={country}
              onChange={(item, newValue) => setCountry(newValue)}
              autoHighlight
              getOptionLabel={(option) => option.label}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  <img
                    loading="lazy"
                    width="20"
                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                    alt=""
                  />
                  {option.label}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Country"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password", // disable autocomplete and autofill
                  }}
                />
              )}
            />

          </div>

          
          <FormControl fullWidth style={{ minWidth: "200px",
            marginTop: "10px", }}>
            <InputLabel>Select TimeZone</InputLabel>
            <Select
              value={gmtTimezone}
              label="Select TimeZone"
              onChange={(e) => {
                setGmtTimezone(e.target.value)
              }}
            >
              {timezones.map((item, index) => (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

        </div>

        <div
          style={{
            fontSize: "1.3rem",
            marginTop: "2rem",
            marginBottom: "1rem",
            textAlign: "left",
            borderBottom: "1px solid #ccc",
            padding: '10px 0px'
          }}
          className="homeParaFont mt-4  text-dark "
        >
          <b>Parent Information</b>
        </div>
        <div className="mt-4">
          <div
            style={{ gap: "10px", display: "flex", flexWrap: "wrap", flex: 1 }}
          >
            <TextField
              required
              label="Relation"
              value={relation}
              onChange={(e) => setRelation(e.target.value)}
              style={{ flex: 1, minWidth: "300px", width: "100%" }}
            />
          </div>

          <div
            style={{
              gap: "10px",
              marginTop: "10px",
              display: "flex",
              flexWrap: "wrap",
              flex: 1,
            }}
          >
            <TextField
              label="First Name"
              required
              value={parentFirstName}
              onChange={(e) => setParentFirstName(e.target.value)}
              style={{ flex: 1, minWidth: "300px" }}
            />

            <TextField
              label="Last Name"
              required
              value={parentLastName}
              onChange={(e) => setParentLastName(e.target.value)}
              style={{ flex: 1, minWidth: "300px" }}
            />
          </div>

          <div
            style={{
              gap: "10px",
              marginTop: "10px",
              display: "flex",
              flexWrap: "wrap",
              flex: 1,
            }}
          >
            <TextField
              label="Email"
              value={parentEmail}
              required
              type="email"
              onChange={(e) => setParentEmail(e.target.value)}
              style={{ flex: 1, minWidth: "300px" }}
            />

            <MuiTelInput
              required
              value={parentPhone}
              label="Phone"
              style={{ flex: 1, minWidth: "300px" }}
              onChange={(e) => setParentPhone(e)}
            />
          </div>
        </div>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            gap: "10px",
            justifyContent: "flex-end",
            mt: 4,
            flexWrap: "wrap",
          }}
        >
          <Button
            variant="outlined"
            // color="error"
            sx={{ fontSize: "1rem" }}
            type="button"
            onClick={() => {
              setPageNo(pageNo - 1);
            }}
          >
            Previous
          </Button>
          <Button
            variant="contained"
            sx={{
              background: "#14549f",
              padding: "5px 20px",
              fontSize: "1rem",
            }}
            type="submit"
          >
            Next
          </Button>
        </Box>
      </form>
    </div>
  );
}
