import React, { useState, useEffect } from "react";
import "./styles.css";
import Logo from "../../images/IBI/IBILogo.png";
import { useLocation, useNavigate } from "react-router-dom";
import LoginImage from "../../assets/login.png";
import SignupImage from "../../assets/signup.png";
import NavDropdown from "react-bootstrap/NavDropdown";

const NavBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [currentTab, setCurrentTab] = useState("/");

  const [isMobile, setIsMobile] = useState(false);

  const checkIsMobile = () => {
    setIsMobile(window.innerWidth <= 1300);
  };

  useEffect(() => {
    checkIsMobile();
    window.addEventListener("resize", checkIsMobile);

    return () => {
      window.removeEventListener("resize", checkIsMobile);
    };
  }, []);

  useEffect(() => {
    if(location.pathname){
      setCurrentTab(location.pathname)
    }
  }, [location.pathname])

  return (
    <div className="navbarbottom">
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div
          className="container-fluid"
          style={{ maxWidth: "1800px", margin: "0px auto" }}
        >
          <div
            className="navbar-brand LogoLink"
            onClick={() => navigate("/")}
          >
            <img src={Logo} className="LogoImg" alt="SomeImage" />
          </div>
          <button
            style={{ backgroundColor: "white" }}
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse navElement justifyEnd ps-1"
            id="navbarSupportedContent"
          >
            <ul
              style={{ alignItems: isMobile ? "left" : "center" }}
              className="navbar-nav ml-auto"
            >
              {!isMobile && (
                <li
                  className={
                    "nav-item " + (currentTab === "/" ? " active" : "")
                  }
                  onClick={() => navigate("/")}
                >
                  <div className="nav-link text-nowrap">
                    Home
                  </div>
                </li>
              )}

              <>
                <li
                  className={
                    "nav-item" + (currentTab === "/whyIBI" ? " active" : "")
                  }
                  onClick={() => navigate("/whyIBI")}
                >
                  <div className="nav-link text-nowrap" >
                    Why IBI?
                  </div>
                </li>
              </>

              <>
                <li
                  className={
                    "nav-item" + (currentTab === "/howitworks" ? " active" : "")
                  }
                  onClick={() => navigate("/howitworks")}
                >
                  <div className="nav-link text-nowrap" >
                    How it works?
                  </div>
                </li>
              </>

              <>
                {!isMobile && (
                  <li
                    className={
                      "nav-item " + (currentTab === "/igsceTutoring" ? " active" : "")
                    }
                    onClick={() => navigate("/igsceTutoring")}
                  >
                    <div className="nav-link text-nowrap">
                      IGSCE Tutoring
                    </div>
                  </li>
                )}
              </>

              <li
                className={
                  "nav-item" +
                  (currentTab === "/revisionCourses" ? " active" : "")
                }
                onClick={() => navigate("/revisionCourses")}
              >
                <div className="nav-link text-nowrap">
                  Revision courses
                </div>
              </li>
              <li
                className={
                  "nav-item" + (currentTab === "/pricing" ? " active" : "")
                }
                onClick={() => navigate("/pricing")}
              >
                <div className="nav-link text-nowrap">
                  Pricing
                </div>
              </li>

              {!isMobile && (
                <li
                  className={
                    "nav-item" + (currentTab === "/contactUs" ? " active" : "")
                  }
                  onClick={() => navigate("/contactUs")}
                >
                  <div className="nav-link text-nowrap">
                    Contact Us
                  </div>
                </li>
              )}
              {!isMobile && (
                <li
                  className={
                    "nav-item" + (currentTab === "/blogs" ? " active" : "")
                  }
                  onClick={() => window.open("https://education.ibinnovators.com/blogs/")}
                >
                  <div
                    style={{ marginRight: "10px" }}
                    className="nav-link text-nowrap"
                  >
                    Blogs
                  </div>
                </li>
              )}

              {isMobile && (
                <NavDropdown title="Menu" id="basic-nav-dropdown">
                  <NavDropdown.Item
                  onClick={() => window.open("https://education.ibinnovators.com/blogs/")}
                  >
                    Blogs
                  </NavDropdown.Item>

                  <NavDropdown.Divider />
                  <NavDropdown.Item
                  onClick={() => navigate("/igsceTutoring")}
                  >
                    IGSCE Tutoring
                  </NavDropdown.Item>

                  <NavDropdown.Divider />
                  <NavDropdown.Item
                  onClick={() => navigate("/contactUs")}
                  >
                    Contact Us
                  </NavDropdown.Item>
                </NavDropdown>
              )}

              <>
                <li
                  className={
                    "nav-item" + (currentTab === "/login" ? " active" : "")
                  }
                  onClick={() => navigate("/login")}
                >
                  <div
                    style={{
                      textAlign: "center",
                      cursor: "pointer",
                      marginRight: "10px",
                    }}
                  >
                    <img
                      src={LoginImage}
                      alt="login Image"
                      style={{ height: "2rem" }}
                    />
                    <br />
                    Log In
                  </div>
                </li>
                <li
                  className={
                    "nav-item" + (currentTab === "/register" ? " active" : "")
                  }
                  onClick={() => navigate("/register")}
                >
                  <div style={{ textAlign: "center", cursor: "pointer" }}>
                    <img
                      src={SignupImage}
                      alt="Signup Image"
                      style={{ height: "2rem", margin: "0px 5px" }}
                    />
                    <br />
                    Sign Up
                  </div>
                </li>
              </>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default NavBar;
