import React, { useState, useEffect, useContext } from "react";
import PricingTable from "../../Components/PricingTable";
import PlainHeroSection from "../../Components/PlainHeroSection";
import ReviewSection from "../../Components/ReviewSection";
import FormModal from "../../Components/Modal";
import PricingTopContainer from "./PricingTopContainer";
import PricingCardNew from "./PricingCardNew";
import axios from "axios";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import PricingImage from "../../assets/pricing.png";
import PricingImageMobile from "../../assets/pricingMobile.png";
import RevisionImage from "../../assets/Revision.png";
import { Helmet } from "react-helmet";
import { MyContext } from "../../Context/MyContext";
import "./Pricing.css";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { GiCheckMark } from "react-icons/gi";

export default function Pricing() {
  const navigate = useNavigate();

  const [currency, setCurrency] = useState("£");
  const [exchangeRates, setExchangeRates] = useState(null);
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    // Fetch exchange rates when the component mounts
    const fetchExchangeRates = async () => {
      try {
        const response = await axios.get(
          "https://api.exchangerate-api.com/v4/latest/GBP"
        );
        setExchangeRates(response.data.rates);
      } catch (error) {
        console.error("Error fetching exchange rates:", error);
      }
    };
    fetchExchangeRates();
  }, []);

  const handleChange = (event) => {
    setCurrency(event.target.value);
  };

  function convertPrice(price, symbol) {
    if (!exchangeRates) return price; // Return original price if exchange rates are not fetched yet

    let rate = 1; // Default rate for GBP
    switch (symbol) {
      case "£":
        rate = exchangeRates.GBP;
        break;
      case "$":
        rate = exchangeRates.USD;
        break;
      case "€":
        rate = exchangeRates.EUR;
        break;
      default:
        rate = exchangeRates.GBP;
    }

    return (price * rate).toFixed(2); // Convert price and fix to 2 decimal points
  }

  const { seoData } = useContext(MyContext);
  const [thisPageSEO, setThisPageSEO] = useState({});

  useEffect(() => {
    if (seoData) {
      setThisPageSEO(seoData?.find((e) => e?.pageName === "Pricing"));
    }
  }, [seoData]);

  const [selectedPricing, setSelectedPricing] = useState("lessonPlan");

  const [openPricingForm, setopenPricingForm] = useState(false);
  const [payAsYouGo, setPayAsYouGo] = useState(false);

  const courses = [
    {
      id: 1,
      name: "Winter Revision Course",
      purpose: "Preparation for May Examination",
      duration: "2-weeks",
      hours: "12 hours",
      fee: "£680",
    },
    {
      id: 2,
      name: "Spring Revision Course",
      purpose: "Preparation for May Examination",
      duration: "1-week",
      hours: "12 hours",
      fee: "£680",
    },
    {
      id: 3,
      name: "Mid IB Course - Summer",
      purpose: "Summer Break Courses",
      duration: "1-month",
      hours: "12 hours",
      fee: "£680",
    },
    {
      id: 4,
      name: "Rapid Revision Course",
      purpose: "Preparation for November Examination",
      duration: "1-month",
      hours: "16 hours",
      fee: "£950",
    },
  ];
  const localIsUserLoggedIn = localStorage.getItem("isLoggedIn");

  const [openModal, setOpenModal] = useState(false);

  const [isMobile, setIsMobile] = useState(false);

  const checkIsMobile = () => {
    setIsMobile(window.innerWidth <= 600); // You can adjust the width threshold as needed
  };

  // Listen for window resize events to update isMobile state
  useEffect(() => {
    checkIsMobile(); // Initial check
    window.addEventListener("resize", checkIsMobile); // Add event listener

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", checkIsMobile);
    };
  }, []);

  function pricingButtonClick(hash) {
    navigate(`/register#${hash}`);
  }

  function yourCloseModalFunction() {
    setOpenModal(false);
  }

  const createHelmetTags = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    const headTags = Array.from(doc.head.children);

    return headTags.map((tag, index) => {
      const tagName = tag.tagName.toLowerCase();

      if (tagName === "title") {
        return <title key={index}>{tag.innerText}</title>;
      } else if (tagName === "meta") {
        const attributes = Array.from(tag.attributes).reduce((acc, attr) => {
          acc[attr.name] = attr.value;
          return acc;
        }, {});
        return <meta key={index} {...attributes} />;
      } else if (tagName === "link") {
        const attributes = Array.from(tag.attributes).reduce((acc, attr) => {
          acc[attr.name] = attr.value;
          return acc;
        }, {});
        return <link key={index} {...attributes} />;
      } else if (tagName === "script" && tag.type === "application/ld+json") {
        return (
          <script key={index} type="application/ld+json">
            {tag.innerText}
          </script>
        );
      } else {
        return null;
      }
    });
  };

  const lessonPackagePlans = [
    {
      symbol: "£",
      price: 35.99,
      lessonPlans: [285, 430, 575],
      learningBenefits: [
        "IB Graduate Tutor Support",
        "Support for Self-Starters",
        "Personalized Study Plans",
        "Exam Preparation Tips",
        "Subject-Specific Expertise",
      ],
    },
    {
      symbol: "£",
      price: 48.99,
      lessonPlans: [390, 585, 780],
      learningBenefits: [
        "IB World School Tutor Support",
        "Support for Aspiring Achievers",
        "Personalized Study Plans",
        "Exam Preparation Tips",
        "Subject-Specific Expertise",
      ],
    },
    {
      symbol: "£",
      price: 56.99,
      lessonPlans: [455, 685, 910],
      learningBenefits: [
        "Certified IB Examiner Support",
        "Support for High-Grade Seekers",
        "Personalized Study Plans",
        "Exam Preparation Tips",
        "Subject-Specific Expertise",
      ],
    },
  ];

  const payAsYouGoPlans = [
    {
      price: 39.99,
      learningBenefits: [
        "IB Graduate Tutor Support",
        "Support for Self-Starters",
        "Personalized Study Plans",
        "Exam Preparation Tips",
        "Subject-Specific Expertise",
      ],
    },
    {
      price: 53.99,
      learningBenefits: [
        "IB World School Tutor Support",
        "Support for Aspiring Achievers",
        "Personalized Study Plans",
        "Exam Preparation Tips",
        "Subject-Specific Expertise",
      ],
    },

    {
      price: 62.99,
      learningBenefits: [
        "Certified IB Examiner Support",
        "Support for High-Grade Seekers",
        "Personalized Study Plans",
        "Exam Preparation Tips",
        "Subject-Specific Expertise",
      ],
    },
  ];

  const guidanceSupportPlans = [
    {
      price: 40.99,
      learningBenefits: [
        "IB Graduate Tutor Support",
        "Support for Collaborative Learners",
        "Critical Reflection",
        "Progressive Feedback",
        "Resourceful Guidance",
      ],
      description:
        "For collaborative learners who enjoy bouncing ideas off of others, engaging in peer review sessions, and working together to solve problems.",
    },
    {
      price: 55.99,
      learningBenefits: [
        "IB World School Tutor Support",
        "Support for Innovative Explorers",
        "Critical Reflection",
        "Progressive Feedback",
        "Resourceful Guidance",
      ],
      description:
        "For innovative explorers who have a knack for thinking outside the box and are always looking for innovative approaches to their internal assessments and extended essays.",
    },

    {
      price: 65.99,
      learningBenefits: [
        "Certified IB Examiner Support",
        "Support for Practical Implementers",
        "Critical Reflection",
        "Progressive Feedback",
        "Resourceful Guidance",
      ],
      description:
        "For practical implementers who are highly focused on real-world applications and practical implications of their internal assessments and extended essays.",
    },
  ];

  function ItemRenderer({ item, last }) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: "10px",
          borderBottom: last ? "none" : "1px solid #5e5e5e",
          paddingBottom: "5px",
        }}
      >
        <div
          style={{
            flex: 1,
            textAlign: "center",
            marginLeft: "10px",
          }}
          dangerouslySetInnerHTML={{ __html: item }}
        ></div>
      </div>
    );
  }

  return (
    <div>
      <Helmet>
        {thisPageSEO?.schema && createHelmetTags(thisPageSEO.schema)}
      </Helmet>

      <FormModal
        openModal={openModal}
        closeModalCallBack={yourCloseModalFunction}
      />
      {/* <div>
                <PricingTopContainer />
            </div> */}
      <div
        style={{
          flex: 1,
          width: "100%",
          background: "red",
          height: imageLoaded ? "max-content" : "600px",
          background: "#14549f",
        }}
      >
        <div style={{ maxWidth: "1800px", flex: 1, margin: "0px auto" }}>
          {isMobile ? (
            <img
              src={PricingImageMobile}
              width="100%"
              alt=""
              onLoad={() => setImageLoaded(true)}
              height="auto"
              style={{ maxWidth: "1800px" }}
            />
          ) : (
            <img
              src={PricingImage}
              onLoad={() => setImageLoaded(true)}
              width="100%"
              alt=""
              height="auto"
            />
          )}
        </div>
      </div>
      <div
        style={{
          padding: "2rem 2vw 2rem 2vw ",
          textAlign: "center",
          background: "#eee",
        }}
      >
        <div style={{ flex: 1, maxWidth: "1800px", margin: "0px auto" }}>
          <div
            style={{
              margin: "10px 0px",
              fontSize: isMobile ? "1rem" : "1.3rem",
              textAlign: "center",
              flex: 1,
            }}
          >
            At{" "}
            <b style={{ color: "#14549f" }}>
              IB <span style={{ color: "rgb(156, 16, 16)" }}>INNOVATORS</span>
            </b>{" "}
            we have tutors for everyone.
            {/* <span
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    color: "rgb(0,112,192)",
                  }}
                  onClick={() => navigate("/revisionCourses")}
                >
                  Group Studying Sessions
                </span> */}
            .
          </div>

          {/* <Option
            style={{
              fontSize: isMobile ? "1.2rem" : "1.8rem",
              fontWeight: "bold",
              textAlign: "center",
              color: "#1e1e1e",
              flex: 1,
              textAlign: "left",
            }}
          >
            One-on-One Tutoring Sessions
          </Option>

          <div
            style={{
              fontSize: isMobile ? "1rem" : "1.3rem",
              textAlign: "left",
              flex: 1,
            }}
          >
            Start achieving top IB grades with our personalized lesson plans.
            Whether you prefer flexible sessions or focused assistance on
            Extended Essay and Internal Assessments, <span
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    color: "rgb(0,112,192)",
                  }}
                  onClick={() => navigate("/ourTutors")}
                >
                  our tutors
                </span> are here to
            demystify the boundaries of IB education for you.
          </div> */}

          <div
            style={{
              display: "flex",
              alignItems: "stretch",
              justifyContent: "center",
              flexDirection: isMobile ? "column" : "row",
              textAlign: "center",
              gap: "20px",
              maxWidth: "1400px",
              margin: "20px auto",
              fontSize: isMobile ? "1rem" : "1.2rem",
              background: "rgba(221,221,221, 0.35)",
              padding: "1rem",
            }}
          >
            <div
              style={
                !isMobile
                  ? {
                      flex: 1,
                      borderRight: "2px solid #ccc",
                      paddingRight: "20px",
                    }
                  : {
                      flex: 1,
                      borderBottom: "2px solid #ccc",
                      paddingBottom: "20px",
                    }
              }
            >
              <div
                style={{
                  fontWeight: "bold",
                }}
              >
                Tutors for Self-Starters
              </div>
              <div>
                Our Foundation Level Tutors are accomplished IB diploma
                graduates who possess a wealth of knowledge and expertise,
                making them well-equipped to assist and guide self-motivated
                learners who just need support for their independent study.
              </div>
            </div>

            <div
              style={
                !isMobile
                  ? {
                      flex: 1,
                      borderRight: "2px solid #ccc",
                      paddingRight: "20px",
                    }
                  : {
                      flex: 1,
                      borderBottom: "2px solid #ccc",
                      paddingBottom: "20px",
                    }
              }
            >
              <div
                style={{
                  fontWeight: "bold",
                }}
              >
                Tutors for Aspiring Achievers
              </div>
              <div>
                Meet our Advanced Level Tutors, a group of certified IB tutors
                and IB Worldschool teachers. They are dedicated to helping
                students aiming for high IB grades and mastering any challenges
                they are facing.
              </div>
            </div>

            <div style={{ flex: 1 }}>
              <div
                style={{
                  fontWeight: "bold",
                }}
              >
                Tutors for High-Grade Seekers
              </div>
              <div>
                At the pinnacle of our tutoring expertise, we proudly present
                our Expert Level Tutors. Comprising seasoned IB examiners and IB
                tutors, they bring a wealth of knowledge and experience for
                students striving for top IB grades and deep understanding in
                every subject.
              </div>
            </div>
          </div>

          <div
            style={{
              fontSize: isMobile ? "1rem" : "1.3rem",
              flex: 1,
            }}
          >
            You can opt for <b>One–on–One</b> Tutoring Sessions or you can join
            our{" "}
            <span
              style={{
                cursor: "pointer",
                textDecoration: "underline",
                color: "rgb(0,112,192)",
              }}
              onClick={() => navigate("/revisionCourses")}
            >
              Group Studying Sessions
            </span>
          </div>

          <div
            style={{
              margin: "10px auto",
              display: "flex",
              // gap: isMobile ? "10px" : "10px",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "20px",
              maxWidth: "800px",
            }}
          >
            <div
              className="topPricingButton"
              style={{
                border: "2px solid #14549f",
                opacity: selectedPricing === "guidanceSupport" ? 0.7 : 1,
                background: '#14549f',
                color: '#fff',
                transform: selectedPricing === "guidanceSupport" ? "scale(0.9)" : "scale(1)",
                boxShadow: selectedPricing === "lessonPlan" ? "2px 5px 20px rgba(0,0,0,0.3)" : "0px 0px 0px rgba(0,0,0,0.3)",
              }}
              onClick={() => {
                setSelectedPricing("lessonPlan");
              }}
            >
              <div
                style={{
                  fontWeight: "bold",
                  color: "#fff",
                  fontSize: isMobile ? "1rem" : "1.1rem",
                }}
              >
                Lesson Plan Sessions
              </div>
              <div>Comprehensive in-depth tutoring sessions</div>
            </div>

            <div
              className="topPricingButton"
              style={{
                border: "2px solid #9c1010",
                opacity: selectedPricing === "lessonPlan" ? 0.7 : 1,
                background: '#9c1010',
                color: '#fff',
                transform: selectedPricing === "lessonPlan" ? "scale(0.9)" : "scale(1)",
                boxShadow: selectedPricing === "guidanceSupport" ? "2px 5px 20px rgba(0,0,0,0.3)" : "0px 0px 0px rgba(0,0,0,0.3)",
              }}
              onClick={() => {
                setSelectedPricing("guidanceSupport");
              }}
            >
              <div
                style={{
                  fontWeight: "bold",
                  color: "#fff",
                  fontSize: isMobile ? "1rem" : "1.1rem",
                }}
              >
                Guidance and Support Sessions
              </div>
              <div>For Extended Essay and Internal Assessment</div>
            </div>
          </div>
        </div>
      </div>

      {selectedPricing === "lessonPlan" ? (
        <div
          style={{
            padding: "2vw",
            display: "flex", // Add this
            flexDirection: "column", // Add this to ensure children align vertically
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ maxWidth: "1800px", flex: 1, margin: "0px auto" }}>
            <div
              style={{
                flex: 1,
                width: "100%",
                flexWrap: "wrap",
                marginTop: "30px",
              }}
            >
              <div style={{ flex: 1, textAlign: "center", width: "100%" }}>
                <ToggleContainer style={{ flexDirection: "row" }}>
                  <Option
                    style={{
                      fontSize: isMobile ? "1.2rem" : "1.8rem",
                      fontWeight: "bold",
                      textAlign: "center",
                      color: "#1e1e1e",
                    }}
                  >
                    Lesson Package Plan
                  </Option>
                  {/* <Checkbox type="checkbox"   /> */}
                  <label className="switch" style={{ margin: "0px 10px" }}>
                    <input
                      checked={payAsYouGo}
                      onChange={(e) => {
                        setPayAsYouGo(!payAsYouGo);
                      }}
                      type="checkbox"
                    />
                    <span className="slider"></span>
                  </label>
                  <Option
                    style={{
                      fontSize: isMobile ? "1.2rem" : "1.8rem",
                      fontWeight: "bold",
                      textAlign: "center",
                      color: "#1e1e1e",
                    }}
                  >
                    Pay as you Go
                  </Option>
                </ToggleContainer>
              </div>
            </div>

            {exchangeRates && (
              <div
                style={{
                  margin: "20px auto",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flex: 1,
                }}
              >
                <FormControl style={{ minWidth: "300px" }}>
                  <InputLabel id="demo-simple-select-label">
                    Choose currency
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={currency}
                    label="Choose currency"
                    onChange={handleChange}
                  >
                    <MenuItem value={"£"}>GBP (£)</MenuItem>
                    <MenuItem value={"$"}>USD ($)</MenuItem>
                    <MenuItem value={"€"}>EUR (€)</MenuItem>
                  </Select>
                </FormControl>
              </div>
            )}

            {payAsYouGo ? (
              <div
                style={{
                  maxWidth: "1800px",
                  margin: "0 auto",
                }}
              >
                <div
                  style={{
                    margin: "10px auto",
                    fontSize: isMobile ? "1rem" : "1.3rem",
                    textAlign: "center",
                  }}
                >
                  The <b>Pay As You Go</b> plan suits students who prefer
                  flexibility and occasional assistance rather than a long-term
                  tutoring commitment. Achieve top IB grades with top IB tutors
                  if you have varying schedules or need help on specific topics.
                </div>

                {/* RESPONSIVE PRICING */}
                <div
                  style={{
                    flexDirection: isMobile ? "column" : "row",
                    flex: 1,
                    display: "flex",
                    gap: isMobile ? "10px" : "15px",
                    maxWidth: "1200px",
                    margin: "10px auto",
                    alignItems: "stretch",
                    marginTop: "20px",
                  }}
                >
                  {payAsYouGoPlans?.map((item, index) => (
                    <div
                      key={index}
                      className="cardStyle"
                      style={{
                        textAlign: "center",
                        padding: "1rem",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "row",
                        display: "flex",
                        borderRadius: "2rem",
                        border: "4px solid #2b4f8c",
                        color: "#1e1e1e",
                        flex: 1,
                        transform: isMobile
                          ? "scale(1)"
                          : index === 1
                          ? "scale(1.05)"
                          : "scale(1)",
                      }}
                    >
                      <div style={{ flex: 1 }}>
                        <div
                          style={{
                            fontWeight: "bolder",
                            margin: "10px auto",
                            fontSize: isMobile ? "1.1rem" : "1.3rem",
                          }}
                        >
                          {currency} {convertPrice(item.price, currency)} per
                          hourly session
                        </div>

                        <div
                          style={{
                            fontSize: isMobile ? "1rem" : "1.15rem",
                            flex: 1,
                          }}
                        >
                          <div
                            style={{
                              textDecoration: "underline",
                              textAlign: "left",
                              flex: 1,
                              marginTop: "10px",
                            }}
                          >
                            Learning Benefits
                          </div>

                          {item?.learningBenefits?.map((benefit, index) => (
                            <div
                              key={index}
                              style={{
                                display: "flex",
                                margin: isMobile ? "10px 5px" : "10px 15px",
                                textAlign: "left",
                                flex: 1,
                                gap: "10px",
                              }}
                            >
                              <GiCheckMark style={{ color: "green" }} />
                              <div>{benefit}</div>
                            </div>
                          ))}

                          <button
                            className="pricingPageButton"
                            style={{
                              margin: "1rem auto",
                              content: "Register Now",
                            }}
                            onClick={() => pricingButtonClick("Pay As You Go")}
                          >
                            Register Now
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div
                style={{
                  maxWidth: "1800px",
                  margin: "0 auto",
                }}
              >
                <div
                  style={{
                    margin: "10px auto",
                    fontSize: isMobile ? "1rem" : "1.3rem",
                    textAlign: "center",
                    marginBottom: isMobile ? "10px" : "30px",
                  }}
                >
                  The <b>Lesson Plan Packages</b> are designed to provide
                  comprehensive, in-depth tutoring sessions to students for all
                  IB subject groups. Achieve top IB grades with top IB tutors by
                  investing your time in our personalized lesson plans.
                </div>

                {/* RESPONSIVE PRICING */}
                <div
                  style={{
                    flexDirection: isMobile ? "column" : "row",
                    flex: 1,
                    display: "flex",
                    gap: isMobile ? "10px" : "15px",
                    maxWidth: "1200px",
                    margin: "10px auto",
                    alignItems: "stretch",
                    marginTop: "20px",
                  }}
                >
                  {lessonPackagePlans?.map((item, index) => (
                    <div
                      className="cardStyle"
                      key={index}
                      style={{
                        textAlign: "center",
                        padding: "1rem",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "row",
                        display: "flex",
                        borderRadius: "2rem",
                        border: "4px solid #2b4f8c",
                        color: "#1e1e1e",
                        flex: 1,
                        transform: isMobile
                          ? "scale(1)"
                          : index === 1
                          ? "scale(1.05)"
                          : "scale(1)",
                      }}
                    >
                      <div style={{ flex: 1 }}>
                        <div
                          style={{
                            fontWeight: "bolder",
                            margin: "10px auto",
                            fontSize: isMobile ? "1.1rem" : "1.3rem",
                          }}
                        >
                          {currency} {convertPrice(item.price, currency)} per
                          hourly session
                        </div>

                        <div
                          style={{
                            fontSize: isMobile ? "1rem" : "1.15rem",
                            flex: 1,
                          }}
                        >
                          <div
                            style={{
                              textDecoration: "underline",
                              textAlign: "left",
                              flex: 1,
                            }}
                          >
                            Lesson Plans
                          </div>
                          {item?.lessonPlans?.map((lessonPlan, index) => (
                            <ItemRenderer
                              key={index}
                              last={
                                index === item?.lessonPlans?.length - 1
                                  ? true
                                  : false
                              }
                              item={`${
                                index === 0 ? 8 : index === 1 ? 12 : 16
                              }-hours sessions for <b>${currency} ${convertPrice(
                                lessonPlan,
                                currency
                              )}</b> `}
                            />
                          ))}

                          <div
                            style={{
                              textDecoration: "underline",
                              textAlign: "left",
                              flex: 1,
                              marginTop: "10px",
                            }}
                          >
                            Learning Benefits
                          </div>

                          {item?.learningBenefits?.map((benefit, index) => (
                            <div
                              key={index}
                              style={{
                                display: "flex",
                                margin: isMobile ? "10px 5px" : "10px 15px",
                                textAlign: "left",
                                flex: 1,
                                gap: "10px",
                              }}
                            >
                              <GiCheckMark style={{ color: "green" }} />
                              <div>{benefit}</div>
                            </div>
                          ))}

                          <button
                            className="pricingPageButton"
                            style={{
                              margin: "1rem auto",
                              content: "Register Now",
                            }}
                            onClick={() => pricingButtonClick("Lesson Plan (08 hours)")}
                          >
                            Register Now
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div
          style={{
            padding: "2vw",
            display: "flex", // Add this
            flexDirection: "column", // Add this to ensure children align vertically
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ maxWidth: "1800px", flex: 1, margin: "0px auto" }}>
            <div
              style={{
                flex: 1,
                width: "100%",
                flexWrap: "wrap",
                marginTop: "30px",
              }}
            >
              <div style={{ flex: 1, textAlign: "center", width: "100%" }}>
                <ToggleContainer style={{ flexDirection: "row" }}>
                  <Option
                    style={{
                      fontSize: isMobile ? "1.2rem" : "1.8rem",
                      fontWeight: "bold",
                      textAlign: "left",
                      color: "#1e1e1e",
                    }}
                  >
                    Guidance and Support
                  </Option>
                </ToggleContainer>
              </div>
            </div>

            {exchangeRates && (
              <div
                style={{
                  margin: "20px auto",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flex: 1,
                }}
              >
                <FormControl style={{ minWidth: "300px" }}>
                  <InputLabel id="demo-simple-select-label">
                    Choose currency
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={currency}
                    label="Choose currency"
                    onChange={handleChange}
                  >
                    <MenuItem value={"£"}>GBP (£)</MenuItem>
                    <MenuItem value={"$"}>USD ($)</MenuItem>
                    <MenuItem value={"€"}>EUR (€)</MenuItem>
                  </Select>
                </FormControl>
              </div>
            )}

            <div
              style={{
                maxWidth: "1800px",
                margin: "0 auto",
              }}
            >
              <div
                style={{
                  margin: "10px auto",
                  fontSize: isMobile ? "1rem" : "1.3rem",
                  textAlign: "center",
                  marginBottom: isMobile ? "20px" : "30px",
                }}
              >
                Our online Extended Essay and Internal Assessment supervision
                assists students in completing this significant part of their IB
                Diploma.{" "}
                <span
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    color: "rgb(0,112,192)",
                  }}
                  onClick={() => navigate("/ourTutors")}
                >
                  Our tutors
                </span>{" "}
                provide the opportunities for students to review and reflect on
                their work and achieve higher grades through hourly session
                arrangements
                <br />
                <br />
                If you wish to know in detail about our Guidance and Support
                Program, please read our{" "}
                <span
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    color: "rgb(0,112,192)",
                  }}
                  onClick={() => navigate("/blogs")}
                >
                  blog page
                </span>
                .
              </div>

              {/* RESPONSIVE PRICING */}

              <div
                style={{
                  flexDirection: isMobile ? "column" : "row",
                  flex: 1,
                  display: "flex",
                  gap: isMobile ? "10px" : "15px",
                  maxWidth: "1200px",
                  margin: "10px auto",
                  alignItems: "stretch",
                  marginTop: "20px",
                }}
              >
                {guidanceSupportPlans?.map((item, index) => (
                  <div
                    className="cardStyle"
                    key={index}
                    style={{
                      textAlign: "center",
                      padding: "1rem",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "row",
                      display: "flex",
                      borderRadius: "2rem",
                      border: "4px solid #9c1010",
                      color: "#1e1e1e",
                      flex: 1,
                      background: "#fff",
                      transform: isMobile
                        ? "scale(1)"
                        : index === 1
                        ? "scale(1.05)"
                        : "scale(1)",
                    }}
                  >
                    <div style={{ flex: 1 }}>
                      <div
                        style={{
                          fontWeight: "bolder",
                          margin: "10px auto",
                          fontSize: isMobile ? "1.1rem" : "1.3rem",
                        }}
                      >
                        {currency} {convertPrice(item.price, currency)} per
                        hourly session
                      </div>

                      <div
                        style={{
                          fontSize: isMobile ? "1rem" : "1.15rem",
                          flex: 1,
                        }}
                      >
                        <div
                          style={{
                            textDecoration: "underline",
                            textAlign: "left",
                            flex: 1,
                            marginTop: "10px",
                          }}
                        >
                          Learning Benefits
                        </div>

                        {item?.learningBenefits?.map((benefit, index) => (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              margin: isMobile ? "10px 5px" : "10px 15px",
                              textAlign: "left",
                              flex: 1,
                              gap: "10px",
                            }}
                          >
                            <GiCheckMark style={{ color: "green" }} />
                            <div>{benefit}</div>
                          </div>
                        ))}

                        <div
                          style={{
                            textAlign: "center",
                            flex: 1,
                            marginTop: "20px",
                          }}
                        >
                          {item?.description}
                        </div>

                        <button
                          className="getStartedButton"
                          style={{
                            margin: "1rem auto",
                          }}
                          onClick={() => navigate("/guidance-and-support-form")}
                        >
                          Get Started
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

const CardContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  flex: 1;
  max-width: 1100px;

  @media (max-width: 1000px) {
    width: 95%;
  }
  @media (max-width: 1150px) {
    flex-direction: column;
  }
`;

const Title = styled.h2`
  color: #5f5f5f;
  font-size: 3rem;
  margin-bottom: 1rem;
  @media (max-width: 500px) {
    margin-top: 2rem;
  }
`;

const ToggleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Option = styled.span`
  font-size: 1rem;
  color: #aaaaaa;
`;

const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 3.1rem;
  height: 1.6rem;
  margin: 0 0.5rem;
`;

const Checkbox = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  background: #fff;
  border-radius: 0.8rem;
  border: 2px solid #d8dadd;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  transition: 0.4s;

  &:hover {
    opacity: 0.5;
  }

  &::before {
    content: "";
    position: absolute;
    background-color: rgb(14, 56, 136);
    border-radius: 50%;
    height: 1.2rem;
    width: 1.2rem;
    left: 0.2rem;
    top: 0.2rem;
    transition: 0.4s;
  }

  ${Checkbox}:checked + &::before {
    transform: translateX(1.4rem);
  }
`;

const Table = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: auto; // For horizontal scrolling on smaller screens;
  background-color: rgb(255, 255, 255, 0.8);
  border-radius: 1rem;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;

  &:not(:last-child) {
    border-bottom: 1px solid white;
  }

  > div {
    flex: 1;
    padding: 10px;
    text-align: center;
  }
`;

const Header = styled(Row)`
  font-weight: bold;
  background-color: rgba(255, 255, 255, 0.1);
`;
