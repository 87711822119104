import React from 'react';
import './index.css'
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import QueryForm from '../QueryForm';

export default function FormModal(props) {
    const { openModal, closeModalCallBack } = props

    const toggle = () => {
        closeModalCallBack(!openModal)
    }

    return (
        <div>
            <Modal style={{borderRadius: '2rem', overflow: 'hidden'}} isOpen={openModal} toggle={toggle} size="xl" backdrop="static">
                <ModalHeader toggle={toggle}  className='modalHeader'>
                    <div className="homePageSubHeadings text-white">
                        The ideal tutor is just a few steps away.
                    </div>
                </ModalHeader>
                <ModalBody style={{background: '#eee'}}>
                    <QueryForm closeModal={toggle}/>
                </ModalBody>
            </Modal>

        </div>
    )
}
