import React from "react";
import styled from "styled-components";

const PricingCardNew = ({
  background,
  centerCard,
  tier,
  toggled,
  monthlyPrice,
  annualPrice,
  storage,
  users,
  limit,
  discountPrice,
  eightHour,
  twelveHour,
  sixteenHour,
  payAsYouGo,
  buttonColor,
  buttonText,
  onClick,
  buttonValue,
  image,
}) => {
  console.log(background);
  return (
    <Card style={{ background: background }} centerCard={centerCard}>

{discountPrice && 
<DiscountBadge>10% OFF</DiscountBadge>
}
      <div
        style={{
          padding: "2rem",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          color: "white",
        }}
      >
        <Title>{tier}</Title>

        {annualPrice && (
          <div
            style={{
              backgroundColor: "#fff",
              borderRadius: "1rem",
              padding: "0.5rem",
              marginTop: "1rem",
            }}
          >
            <Price dataPlan="annually" hidden={toggled}>
              {annualPrice}{" "}
            </Price>
          </div>
        )}
        {discountPrice && (
          <>
            <div
              style={{
                backgroundColor: "#fff",
                borderRadius: "1rem",
                padding: "0.5rem",
                marginTop: "1rem",
              }}
            >
              <Price dataPlan="annually" hidden={toggled}>
                {discountPrice}{" "}
              </Price>
            </div>
          </>
        )}

        <div
          style={{
            border: "2px solid #fff",
            borderRadius: "1rem",
            padding: "0.5rem",
            marginBottom: "1rem",
            marginTop: "1rem",
            color: 'white',
            background: 'rgba(0,0,0,0.7)'
          }}
        >
          <Detail>{storage}</Detail>
          {users && <Detail>{users}</Detail>}
        </div>
        <Button
          onClick={onClick}
          background={buttonColor}
          color={"#1e1e1e"}
          width="90%"
        >
          {buttonValue ? buttonValue : "Get Started"}
        </Button>
      </div>
    </Card>
  );
};


const Card = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  text-align: center;
  border-radius: 1rem;
  color: initial;
  scale: 1;
  transition: 0.3s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  z-index: ${(props) => props.centerCard && "99"};
  overflow: hidden;
  height: 100%;
  flex: 1;
  transform: ${(props) => props.centerCard && "scale(1.03)"};

  &:hover {
    transform: scale(
      1.05
    ); /* corrected from 'scale(1.1)' to 'transform: scale(1.1);' */
  }


  @media (max-width: 700px) {
    margin-bottom: 2rem;
  }
`;

const Header = styled.div`
  padding: 1rem 0;
  display: flex;
  flex-direction: column;

  width: 100%;
`;

const Title = styled.h4`
  font-size: 2.5rem;
  font-weight: bold;
`;

const Price = styled.span`
  font-size: 1.5rem;
  font-weight: 600;
  border-radius: 2rem;
  color: black;

  &:not([hidden]) {
    display: flex;
    justify-content: center;
    align-items: baseline;
  }

`;

const Discount = styled.span`
  font-size: 1.5rem;
  font-weight: 600;

  &:not([hidden]) {
    display: flex;
    justify-content: center;
    align-items: baseline;
  }

  &::before {
    content: "discount at £ ";
    font-size: 1rem;
    margin-right: 0.4rem;
  }
  &::after {
    content: " per hour";
    font-size: medium;
    margin-left: 0.4rem; // Changed from margin-right to margin-left for after pseudo-element
  }
`;

const Detail = styled.p`
  font-size: 1.2rem;
  font-kerning: none;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1rem;
`;

const DiscountBadge = styled.div`
  background-color: #ffd700;
  color: #000;
  font-size: 1.5rem;
  padding: 0.5rem;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%
`;

export default PricingCardNew;

const Button = styled.button`
  width: 100%;
  padding: 0.6rem;
  background: transparent;
  border-radius: 1rem;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.1rem;
  transition: 0.3s;
  color: #fff;
  border: 2px solid white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

  &:hover {
    background: #fff;
    color: #444444;
  }

  &:active {
    background: #fff;
    color: #444444;
  }
`;
