import React from "react";
import classes from "./TopDetails.module.css";

function TopDetails(props) {

  console.log("DATE", props?.createdOn)

  function formatFirestoreTimestamp(timestamp) {
    const milliseconds = timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000;

    const date = new Date(milliseconds);

    const options = {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true,
    };

    return date.toLocaleString('en-US', options);
}

  return (
    <div className={classes.containerMain}>
    <div className={classes.mainImage} style={{ minHeight: "5rem" }}>
      <div className={classes.mainImageComponentText}>
        <div className={classes.mainImageText}>
            <div style={{flex: 1}}>
          <div style={{ color: "white" }} className={classes.heading}>
            {props.header}
          </div>
          <div className={classes.description}>
          {props?.description}
          </div>
          </div>
          <div style={{borderTop: '1px solid rgba(255,255,255,0.3)', paddingTop: '1rem', flex: 1, width: '100%'}}>
          <div className={classes.content}>Written By: {props?.writtenBy} | {props?.duration} read</div>
          <div className={classes.content}>
         {props?.createdOn &&  `Published: ${formatFirestoreTimestamp(props?.createdOn)}` }{props?.updatedOn && ` | Updated: ${formatFirestoreTimestamp(props?.updatedOn)}`}</div>
          </div>
        </div>
      </div>
      <div className={classes.mainImageComponent} style={{ flex: 1 }}>
        <img
          style={{
            maxHeight: "300px",
            objectFit: "cover",
            objectPosition: "center center",
          }}
          src={props.image}
          alt="Blog"
          className={classes.mainProductImage}
        />
      </div>
    </div>
    </div>
  );
}

export default TopDetails;
