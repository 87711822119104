import React, { useEffect, useState, useRef, useCallback } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import styles from "./Details.module.css"; // Import the CSS module
import TopDetails from "./TopDetails";
import {
  Firestore,
  collection,
  addDoc,
  getDoc,
  getDocs,
} from "firebase/firestore";
import { db } from "../../firebase";
import "react-quill/dist/quill.snow.css"; // Import styles
import { Helmet } from "react-helmet";
import { Button } from "@mui/material";
import RelatedBlogCard from "./RelatedBlogCard";

const Details = () => {
  const [reqData, setReqData] = useState({});
  const navigate = useNavigate();
  const params = useParams();
  const [data, setData] = useState([]);

  const fetchBlogs = async () => {
    const blogsRef = collection(db, "Blogs");
    const snapshot = await getDocs(blogsRef);

    const blogsArray = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    setData(blogsArray);
  };

  // Using useEffect to call fetchBlogs on component mount
  useEffect(() => {
    fetchBlogs();
  }, []);

  useEffect(() => {
    const reqData = data?.find((item) => item?.url === params.id);
    setReqData(reqData);
  }, [params.id, data]);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Function to handle window resize
    const handleResize = () => {
      if (window.innerWidth < 990) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    // Initial check on component mount
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const createHelmetTags = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    const headTags = Array.from(doc.head.children);

    return headTags.map((tag, index) => {
      const tagName = tag.tagName.toLowerCase();

      if (tagName === "title") {
        return <title key={index}>{tag.innerText}</title>;
      } else if (tagName === "meta") {
        const attributes = Array.from(tag.attributes).reduce((acc, attr) => {
          acc[attr.name] = attr.value;
          return acc;
        }, {});
        return <meta key={index} {...attributes} />;
      } else if (tagName === "link") {
        const attributes = Array.from(tag.attributes).reduce((acc, attr) => {
          acc[attr.name] = attr.value;
          return acc;
        }, {});
        return <link key={index} {...attributes} />;
      } else if (tagName === "script" && tag.type === "application/ld+json") {
        return (
          <script key={index} type="application/ld+json">
            {tag.innerText}
          </script>
        );
      } else {
        return null;
      }
    });
  };


  


  return (
    <div style={{ minHeight: "100vh" }}>
      <Helmet>{reqData?.seoTags && createHelmetTags(reqData?.seoTags)}</Helmet>

      {reqData && (
        <TopDetails
          header={reqData?.header}
          image={reqData?.image}
          duration={reqData?.duration}
          writtenBy={reqData?.writtenBy}
          updatedOn={reqData?.updatedOn}
          createdOn={reqData?.createdOn}
          description={reqData?.description}
        />
      )}
      <div className={styles.container}>
        <div
          className={`${styles["blog-container"]} ${
            isMobile ? styles.mobile : ""
          }`}
        >
          <div style={{ fontSize: "larger", flex: 1, textAlign: "left" }}>
            <b>Tag(s) of Blog:</b> {reqData?.selectedTags?.join(", ")}
          </div>
          <div
            style={{
              fontSize: "larger",
              flex: 1,
              textAlign: "left",
              marginTop: "20px",
            }}
          >
            <b>Summary of Blog:</b>{" "}
          </div>
          <div
            className="ql-editor"
            style={{ margin: "0px", padding: "0px" }}
            dangerouslySetInnerHTML={{ __html: reqData?.content }}
          />
          <div>
            <button
              onClick={() => {
                window.open(
                  `https://education.ibinnovators.com/${params.id}/`,
                  "_blank"
                );
              }}
              className={styles.blogsButton}
            >
              Read Full
            </button>
          </div>
        </div>
        <div className={styles.relatedBlogsContainer}>
          <div
            style={{
              fontSize: "larger",
              fontWeight: "bolder",
              flex: 1,
              textAlign: "center",
            }}
          >
            Related Articles
          </div>
          {data?.filter((e) => e?.header !== reqData?.header)?.slice(0, 3)?.map((item, index) => (
            <RelatedBlogCard
              key={index}
              date={item?.createdOn}
              title={item?.header}
              description={item?.description}
              link={`/blogs/${item.url}`}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Details;
