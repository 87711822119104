import React, { useState, useContext } from 'react'
import './index.css'
import { MyContext } from '../../../Context/MyContext';
import { addDoc, collection, doc } from 'firebase/firestore';
import { db } from '../../../firebase';
import PricingCardNew from '../../../Pages/Pricing/PricingCardNew';
import styled from 'styled-components';
import ReviewImage from '../../../assets/Revision.png'

import emailjs from 'emailjs-com';

export default function QueryFormSeventhStep(props) {
    const { selectedCallBack, selectedProgram } = props;
    const [selectedPlan, setSelectedPlan] = useState("");
    const [selectedPrice, setSelectedPrice] = useState("");
    const { studentForm, setStudentForm } = useContext(MyContext);

    const handlePlanSelection = (price, plan) => {
        setSelectedPlan(plan);
        setSelectedPrice(price);
        handleSubmit(price, plan)
    };

    const [payAsYouGo, setPayAsYouGo] = useState(true)

    const planSelect = (selectPlan) => {
        setSelectedPlan(selectPlan);
    };

    // Save all the state information into a variable
    const allData = {
        selectedPlan
    };

    console.log("All Data:", allData);

    const handleSubmit = async (price, plan) => {
    
        // Construct the new state directly.
        const updatedStudentForm = {
            ...studentForm,
            selectedPlan: plan,
            selectedPrice: price,
        };
    
        const ordersRef = collection(db, 'adminPanel');
        const customDocRef = doc(ordersRef, 'studentForm');
        const orderCollectionRef = collection(customDocRef, 'studentForm');
    
        try {
            await addDoc(orderCollectionRef, updatedStudentForm); // Use the updated form data here


            const emailParams = {
              from_name: "IBInnovators",
              to_name: "IBInnovators",
              to: 'contact@ibinnovators.com', // Replace with your recipient's email address
              subject: 'New Student Form Submission',
              message: `A new Student Form just Got Submitted`,
        };
            // Use your email service credentials here
            const serviceId = 'service_94jepxc';
            const templateId = 'template_a3htpzi';
            const userId = 'ccjdlLBwrBG_K7JJE';
          
            emailjs.send(serviceId, templateId, emailParams, userId)
              .then((response) => {
                console.log('Email sent successfully:', response);
              })
              .catch((error) => {
                console.error('Error sending email:', error);
              });
          


            selectedCallBack(selectedProgram, 8);
        } catch (error) {
            alert("Error creating document:", error);
        }
    
        // Optionally update the state after sending data to Firebase
        setStudentForm(updatedStudentForm);
    };
    

    const courses = [
        {
            id: 1,
            name: 'Winter Revision Course',
            purpose: 'Preparation for May Examination',
            duration: '2-weeks',
            hours: '12 hours',
            fee: 680,
        },
        {
            id: 2,
            name: 'Spring Revision Course',
            purpose: 'Preparation for May Examination',
            duration: '1-week',
            hours: '12 hours',
            fee: 680,
        },
        {
            id: 3,
            name: 'Mid IB Course - Summer',
            purpose: 'Summer Break Courses',
            duration: '1-month',
            hours: '12 hours',
            fee: 680,
        },
        {
            id: 4,
            name: 'Rapid Revision Course',
            purpose: 'Preparation for November Examination',
            duration: '1-month',
            hours: '16 hours',
            fee: 950,
        }
    ];


    return (
        <div >
            <div className='formContent'>
                <div style={{padding: '1rem'}} className='mt-5'>
                    <div style={{color: 'white'}} className="homeParaFont mt-4 text-center text-dark">
                        <b>
                            Sounds Great! <br />
                            Here are the subscriptions we think is best for you!
                        </b>
                    </div>


                    {/* <div className="row mt-5">
                        <div className='col-md-4'>
                            <div className={"card border border-warning pricingCard cursorPointer " + (selectedPlan === "45" ? "selectPlan" : "")} onClick={() => planSelect("45")}>
                                <div className="card-header homePageParaFont text-center bg-warning text-white">
                                    <b>Foundation Level</b>
                                </div>
                                <div className="card-body pricingCardHeight">
                                    <div className="card-title homePageParaFont text-center">

                                    </div>
                                    <div className="card-subtitle mb-2 text-center homePageParaFont">Starting from</div>
                                    <div className='text-warning homePageHeadings text-center price'><b>£45</b></div>
                                    <p className="card-text text-muted text-center">per hourly session</p>
                                    <p className="card-text text-muted text-center">
                                        Foundation Level Tutors are accomplished IB graduates who possess a wealth of knowledge and expertise.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className={"card border border-primary pricingCard cursorPointer " + (selectedPlan === "55" ? "selectPlan" : "")} onClick={() => planSelect("55")}>
                                <div className="card-header homePageParaFont text-center bg-primary text-white">
                                    <b>Advanced Level Tutor</b>
                                </div>
                                <div className="card-body pricingCardHeight">
                                    <div className="card-title homePageParaFont text-center">

                                    </div>
                                    <div className="card-subtitle mb-2 text-center homePageParaFont">Starting from</div>
                                    <div className='text-primary homePageHeadings text-center price'><b>£55</b></div>
                                    <p className="card-text text-muted text-center">per hourly session</p>
                                    <p className="card-text text-muted text-center">
                                        Meet our Advanced Level Tutors, a group of postgraduate IB students, their dedication to helping students.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className={"card border border-danger pricingCard cursorPointer " + (selectedPlan === "65" ? "selectPlan" : "")} onClick={() => planSelect("65")}>
                                <div className="card-header homePageParaFont text-center bg-danger text-white">
                                    <b>Expert Level Tutor</b>
                                </div>
                                <div className="card-body pricingCardHeight">
                                    <div className="card-title homePageParaFont text-center">

                                    </div>
                                    <div className="card-subtitle mb-2 text-center homePageParaFont">Starting from</div>
                                    <div className='text-danger homePageHeadings text-center price'><b>£65</b></div>
                                    <p className="card-text text-muted text-center">per hourly session</p>
                                    <p className="card-text text-muted text-center">
                                        At the pinnacle of our tutoring expertise, we proudly present our Expert Level. Comprising seasoned IB teachers and examiners.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div> */}



{/* A - PRICING PER LEVEL */}
<div style={{
    display: 'flex', // Add this
    flexDirection: 'column', // Add this to ensure children align vertically
    justifyContent: 'center',
    alignItems: 'center',
}}>
  
        <ToggleContainer>
          <Option style={{color: 'black'}}>Pay as you Go</Option>
          <Switch>
        <Checkbox type="checkbox" checked={!payAsYouGo} onChange={(e) => {setPayAsYouGo(!payAsYouGo)}}  />
        <Slider background={payAsYouGo} />
      </Switch>
          <Option style={{color: 'black'}}>Lesson Package Plan</Option>
        </ToggleContainer>
            {payAsYouGo ? 
<>
<Title style={{color: 'black', textAlign: 'center', fontSize: '1.5rem'}}>Book a session and get a free consultation session with IB World School Counsellor</Title>
            <CardContainer>
          <PricingCardNew
            tier="Foundation Level Tutor"
            annualPrice="40"
            buttonColor="linear-gradient(to right, #6a11cb 0%, #2575fc 100%)"
            background="linear-gradient(to right, #6a11cb 0%, #2575fc 100%)"
            payAsYouGo={payAsYouGo}
            buttonValue="Choose"
            buttonText="rgb(61, 121, 176)"
            image={ReviewImage}
            onClick={() => handlePlanSelection(40, "Pay as you Go")}
            storage="Foundation Level Tutors, skilled IB graduates, bring ample knowledge and expertise, perfectly suiting them to guide students academically."
          />
          <PricingCardNew
            tier="Advanced Level Tutor"
            annualPrice="55"
            onClick={() => handlePlanSelection(55, "Pay as you Go")}
            payAsYouGo={payAsYouGo}
            buttonValue="Choose"
            storage="Meet our Advanced Level Tutors, a group of postgraduate IB students, their dedication to helping students and their friendly approach creates a supportive environment where learning becomes enjoyable and rewarding.
            "
            image={ReviewImage}
            buttonColor="linear-gradient(180deg, #2af598 0%, #009efd 100%)"
            background="linear-gradient(180deg, #2af598 0%, #009efd 100%)"
            centerCard
            buttonText="rgb(61, 121, 176)"
          />
          <PricingCardNew
            tier="Expert Level Tutor"
            annualPrice="65"
            onClick={() => handlePlanSelection(65, "Pay as you Go")}
            background="linear-gradient(to top, #c471f5 0%, #fa71cd 100%)"
            buttonColor="linear-gradient(to top, #c471f5 0%, #fa71cd 100%)"
            buttonValue="Choose"
            payAsYouGo={payAsYouGo}
            image={ReviewImage}
            buttonText="rgb(61, 121, 176)"
            storage="At the pinnacle of our tutoring, we present our Expert Level Tutors. Comprising seasoned IB teachers and examiners, they offer unmatched knowledge and support."
          />
        </CardContainer>
        </>
:

<>
<Title style={{color: 'black', textAlign: 'center', fontSize: '1.5rem'}}>Book a lesson plan and get a free counselling session with IB World School Counsellor
</Title>

<Title style={{color: 'black', textAlign: 'center', fontSize: '1.2rem'}}>Hours once purchased will be valid for a maximum of 2-months from the date of fee payment
</Title>
<CardContainer>
          <PricingCardNew
            tier="Foundation Level Tutor"
            discountPrice="35"
            onClick={() => handlePlanSelection(35, "Lesson Package Plan")}
            storage="Foundation Level Tutors, skilled IB graduates, bring ample knowledge and expertise, perfectly suiting them to guide students academically."
            eightHour="8-hour session for £ 280"
            twelveHour="12-hour session for £ 420"
            sixteenHour="16-hours session for £ 560"
            image={ReviewImage}
            buttonValue="Choose"
            buttonText="rgb(154, 27, 69)"
            buttonColor="linear-gradient(to right, #6a11cb 0%, #2575fc 100%)"
            background="linear-gradient(to right, #6a11cb 0%, #2575fc 100%)"
            payAsYouGo={payAsYouGo}
          />
          <PricingCardNew
            tier="Advanced Level Tutor"
            discountPrice="48"
            onClick={() => handlePlanSelection(48, "Lesson Package Plan")}
            storage="Meet our Advanced Level Tutors, a group of postgraduate IB students, their dedication to helping students and their friendly approach creates a supportive environment where learning becomes enjoyable and rewarding.
            "
            buttonText="rgb(154, 27, 69)"
            image={ReviewImage}
            buttonValue="Choose"
            buttonColor="linear-gradient(180deg, #2af598 0%, #009efd 100%)"
            background="linear-gradient(180deg, #2af598 0%, #009efd 100%)"
            eightHour="8-hour session for £ 384"
            twelveHour="12-hour session for £ 576"
            sixteenHour="16-hours session for £ 768"
            centerCard
            payAsYouGo={payAsYouGo}
          />
          <PricingCardNew
            tier="Expert Level Tutor"
            discountPrice="56"
            onClick={() => handlePlanSelection(56, "Lesson Package Plan")}
            storage="At the pinnacle of our tutoring, we present our Expert Level Tutors. Comprising seasoned IB teachers and examiners, they offer unmatched knowledge and support."
            buttonText="rgb(154, 27, 69)"
          eightHour="8-hour session for £ 448"
            twelveHour="12-hour session for £ 672"
            sixteenHour="16-hours session for £ 896"
            image={ReviewImage}
            buttonValue="Choose"
            background="linear-gradient(to top, #c471f5 0%, #fa71cd 100%)"
            buttonColor="linear-gradient(to top, #c471f5 0%, #fa71cd 100%)"
            payAsYouGo={payAsYouGo}
          />
        </CardContainer>
</>
}



            
{/* B - PRICING PER Course */}
            <div style={{
    padding: '3vw',
    display: 'flex', // Add this
    flexDirection: 'column', // Add this to ensure children align vertically
    justifyContent: 'center',
    alignItems: 'center',
}}>
    {/* <Title style={{color: 'black', textAlign: 'center'}}>B. Pricing per Course</Title> */}
    <Table>
            <Header>
                <div style={{textAlign: 'left'}}>Course Name</div>
                <div style={{textAlign: 'left'}}>Purpose</div>
                <div style={{textAlign: 'left'}}>Duration</div>
                <div style={{textAlign: 'left'}}>Teaching Hours</div>
                <div style={{textAlign: 'left'}}>Fee per Subject</div>
                <div style={{textAlign: 'left'}}></div>
            </Header>
            {courses.map(course => (
                <Row key={course.id}>
                <div style={{textAlign: 'left'}}>{course.name}</div>
                <div style={{textAlign: 'left'}}>{course.purpose}</div>
                <div style={{textAlign: 'left'}}>{course.duration}</div>
                <div style={{textAlign: 'left'}}>{course.hours}</div>
                <div style={{textAlign: 'left'}}>£{course.fee}</div>
                <div>
                <Button
                
            onClick={() => handlePlanSelection(course.fee, course.name)}
          width="90%"
        >
                        Choose
                    </Button>
                </div>
            </Row>
            ))}
        </Table>
            </div>


            {/* C - PRICING PER SESSION */}
            <div style={{
    padding: '3vw',
    display: 'flex', // Add this
    flexDirection: 'column', // Add this to ensure children align vertically
    justifyContent: 'center',
    alignItems: 'center',
}}>
    {/* <Title style={{color: 'black', textAlign: 'center'}}>C. Pricing per Session</Title> */}
    <Title style={{color: 'black'}}><span style={{color: 'black', fontSize: '1.5rem'}}>IA + EE Guidance and Support</span></Title>
    
    
<>
<Title style={{color: 'black', textAlign: 'center', fontSize: '1.5rem'}}>Book a support session and get a free consultation session with IB World School Counsellor
</Title>
            <CardContainer >
          <PricingCardNew
            tier="Foundation Level Tutor"
            annualPrice="40"
            onClick={() => handlePlanSelection(40, "Pricing per Session")}
            buttonColor="linear-gradient(to right, #6a11cb 0%, #2575fc 100%)"
            background="linear-gradient(to right, #6a11cb 0%, #2575fc 100%)"
            payAsYouGo={payAsYouGo}
            buttonText="rgb(61, 121, 176)"
            buttonValue="Choose"
            image={ReviewImage}
            storage="Foundation Level Tutors, skilled IB graduates, bring ample knowledge and expertise, perfectly suiting them to guide students academically."
          />
          <PricingCardNew
            tier="Advanced Level Tutor"
            annualPrice="55"
            onClick={() => handlePlanSelection(55, "Pricing per Session")}
            payAsYouGo={payAsYouGo}
            storage="Meet our Advanced Level Tutors, a group of postgraduate IB students, their dedication to helping students and their friendly approach creates a supportive environment where learning becomes enjoyable and rewarding.
            "
            image={ReviewImage}
            buttonValue="Choose"
            buttonColor="linear-gradient(180deg, #2af598 0%, #009efd 100%)"
            background="linear-gradient(180deg, #2af598 0%, #009efd 100%)"
            centerCard
            buttonText="rgb(61, 121, 176)"
          />
          <PricingCardNew
            tier="Expert Level Tutor"
            annualPrice="65"
            onClick={() => handlePlanSelection(65, "Pricing per Session")}
            background="linear-gradient(to top, #c471f5 0%, #fa71cd 100%)"
            buttonColor="linear-gradient(to top, #c471f5 0%, #fa71cd 100%)"
            payAsYouGo={payAsYouGo}
            buttonValue="Choose"
            image={ReviewImage}
            buttonText="rgb(61, 121, 176)"
            storage="At the pinnacle of our tutoring, we present our Expert Level Tutors. Comprising seasoned IB teachers and examiners, they offer unmatched knowledge and support."
          />
        </CardContainer>
        </>
            </div>


        </div>




                    
                </div>
                
            </div>
            
        
    </div >

        
    )
}



const CardContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media (max-width: 1000px) {
    width: 95%;
  }
  @media (max-width: 700px) {
    flex-direction: column;
  }
`;

const Title = styled.h2`
  color: #5f5f5f;
  font-size: 3rem;
  margin-bottom: 1rem;
  @media (max-width: 500px) {
    margin-top: 2rem;
  }
`;

const ToggleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
`;

const Option = styled.span`
  font-size: 1rem;
  color: #aaaaaa;
`;


const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 3.1rem;
  height: 1.6rem;
  margin: 0 0.5rem;
`

const Checkbox = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  background: linear-gradient(to top, #c471f5 0%, #fa71cd 100%);
  border-radius: 0.8rem;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  transition: 0.4s;

  &:hover {
    opacity: 0.5;
  }
  
  &::before {
    content: "";
    position: absolute;
    background-color: #ffffff;
    border-radius: 50%;
    height: 1.2rem;
    width: 1.2rem;
    left: 0.2rem;
    top: 0.2rem;
    transition: 0.4s;
  }

  ${Checkbox}:checked + &::before {
    transform: translateX(1.4rem);
  }

`


const Table = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-x: auto; // For horizontal scrolling on smaller screens;
    background-color: rgb(255,255,255, 0.8);
    border-radius: 1rem
`;

const Row = styled.div`
    display: flex;
    justify-content: space-between;

    &:not(:last-child) {
        border-bottom: 1px solid white;
    }

    > div {
        flex: 1;
        padding: 10px;
        text-align: center;
    }
`;

const Header = styled(Row)`
    font-weight: bold;
    background-color: rgba(255, 255, 255, 0.1);
`;


const Button = styled.button`
  margin:0.5rem;
  padding: 0.6rem;
  background:  #fff;
  color: #000;
  border-radius: 0.4rem;
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: 600;
  letter-spacing: 0.1rem;
  border: 1px rgba(0, 0, 0, 0) solid;
  transition: 0.3s;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    background: none;
    border: rgba(151,156,237,1) solid;
    color: rgb(151,156,237);
  }
`;