import React from 'react'
import './index.css'
import ReviewCard from '../ReviewCard'
export default function RotateCard(props) {
    const { review } = props
    const { name, comment, rating, from } = review
    
    return (

        <div id="rotatingCard" className='me-3'>
            <div className="Rcard">
                <div className="Rcard-side front">
                    <div>
                        <ReviewCard
                            face={"front"}
                            name={name}
                            rating={rating}
                            from={from}
                        />
                    </div>
                </div>
                <div className="Rcard-side back" style={{height: 'max-content'}}>
                    <div>
                        <ReviewCard
                            content={comment}
                            backside={true}
                            name={name}
                            rating={rating}
                            from={from}
                        />
                    </div>
                </div>
            </div>
        </div>

    )
}
