import { Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";

export function ReqExtraPageOne({
  setPageNo,
  pageNo,
  setActivePage,
  setRegisterModalOpen,
  fullFormData,
  setFullFormData,
  isMobile
}) {
  useEffect(() => {
    setActivePage(2);
  }, []);


  const data=[
    {courseHours: '08 Hours Course',
    subjects: 1,
    price: 235},
    {courseHours: '08 Hours Course',
    subjects: 2,
    price: 445},
    {courseHours: '08 Hours Course',
    subjects: 3,
    price: 640},

    {courseHours: '12 Hours Course',
    subjects: 1,
    price: 350},
    {courseHours: '12 Hours Course',
    subjects: 1,
    price: 665},
    {courseHours: '12 Hours Course',
    subjects: 1,
    price: 950},

    {courseHours: '16 Hours Course',
    subjects: 1,
    price: 465},
    {courseHours: '16 Hours Course',
    subjects: 1,
    price: 885},
    {courseHours: '16 Hours Course',
    subjects: 1,
    price: 1260}
  ]

  const [value, setValue] = useState( "0");

  const handleValueChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <div>
      <h3
        style={{
          fontSize: isMobile ? "1.1rem" : "1.3rem",
          marginBottom: "2rem",
          fontWeight: "bold",
        }}
      >
        Select Your Desired Course Hours and Subjects (Max. 3 Subjects)
      </h3>


      <div style={{ flex: 1,  fontSize: isMobile ? "small" : "1.2rem" }}>

        <div style={{ flex: 1,display: "flex", margin: '10px', gap: '10px'}}>
          {/* Content */}
          <div style={{ flex: 2, fontWeight: 'bold', textAlign: 'center'}}>
            <b> </b>
          </div>
          <div  style={{ flex: 1, fontWeight: 'bold', textAlign: 'center'}}>01 Subject</div>
          <div  style={{ flex: 1, fontWeight: 'bold', textAlign: 'center'}}>02 Subjects</div>
          <div  style={{ flex: 1, fontWeight: 'bold', textAlign: 'center'}}>03 Subjects</div>
        </div>

        <div style={{ flex: 1,display: "flex", margin: '10px', gap: '10px'}}>
          {/* Content */}
          <div style={{ flex: 2}}>
          08 Hours Course
          </div>
          <div  style={{ flex: 1, textAlign: 'center'}}>            <label>
              <input
                type="radio"
                name="subject"
                value="0"
                checked={value === "0"}
                onChange={handleValueChange}
                style={{ transform: "scale(1.5)" }}
              />
            </label></div>
          <div  style={{ flex: 1, textAlign: 'center'}}> <label>
              <input
                type="radio"
                name="subject"
                value="1"
                checked={value === "1"}
                onChange={handleValueChange}
                style={{ transform: "scale(1.5)" }}
              />
            </label></div>
          <div  style={{ flex: 1, textAlign: 'center'}}><label>
              <input
                type="radio"
                name="subject"
                value="2"
                checked={value === "2"}
                onChange={handleValueChange}
                style={{ transform: "scale(1.5)" }}
              />
            </label></div>
        </div>


        <div style={{ flex: 1,display: "flex", margin: '10px', gap: '10px'}}>
          {/* Content */}
          <div style={{ flex: 2}}>
          12 Hours Course
          </div>
          <div  style={{ flex: 1, textAlign: 'center'}}>
          <label>
              <input
                type="radio"
                name="subject"
                value="3"
                checked={value === "3"}
                onChange={handleValueChange}
                style={{ transform: "scale(1.5)" }}
              />
            </label>
            </div>
          <div  style={{ flex: 1, textAlign: 'center'}}> 
          <label>
              <input
                type="radio"
                name="subject"
                value="4"
                checked={value === "4"}
                onChange={handleValueChange}
                style={{ transform: "scale(1.5)" }}
              />
            </label>
            </div>
          <div  style={{ flex: 1, textAlign: 'center'}}>
          <label>
              <input
                type="radio"
                name="subject"
                value="5"
                checked={value === "5"}
                onChange={handleValueChange}
                style={{ transform: "scale(1.5)" }}
              />
            </label>
            </div>
        </div>

        <div style={{ flex: 1,display: "flex", margin: '10px', gap: '10px'}}>
          {/* Content */}
          <div style={{ flex: 2}}>
          16 Hours Course
          </div>
          <div  style={{ flex: 1, textAlign: 'center'}}>
          <label>
              <input
                type="radio"
                name="subject"
                value="6"
                checked={value === "6"}
                onChange={handleValueChange}
                style={{ transform: "scale(1.5)" }}
              />
            </label>
            </div>
          <div  style={{ flex: 1, textAlign: 'center'}}> 
          <label>
              <input
                type="radio"
                name="subject"
                value="7"
                checked={value === "7"}
                onChange={handleValueChange}
                style={{ transform: "scale(1.5)" }}
              />
            </label>
            </div>
          <div  style={{ flex: 1, textAlign: 'center'}}>
          <label>
              <input
                type="radio"
                name="subject"
                value="8"
                checked={value === "8"}
                onChange={handleValueChange}
                style={{ transform: "scale(1.5)" }}
              />
            </label>
            </div>
        </div>

      </div>



      <div style={{ fontSize: "1.2rem" }}>
        {" "}
        <h3
          style={{ fontSize: isMobile ? "1.1rem" : "1.3rem", marginTop: "2rem", fontWeight: "bold" }}
        >
          Price of Your Custom Course
        </h3>
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
          }}
        >
          £{" "}
          <div
            style={{
              marginLeft: "10px",
              border: "1px solid #ccc",
              backgroundColor: "#eee",
              padding: "10px",
              minWidth: "300px",
              height: '50px'
            }}
          >
             { data[parseInt(value)].price }
          </div>
        </div>
      </div>




      <Box
          sx={{
            width: "100%",
            display: "flex",
            gap: "10px",
            justifyContent: "flex-end",
            mt: 4,
            flexWrap: "wrap",
          }}
        >
          <Button
            variant="outlined"
            // color="error"
            sx={{fontSize: '1rem' }}
            type="button"
            onClick={() => {
              setPageNo(pageNo - 1);
            }}
          >
            Previous
          </Button>
          <Button
            variant="contained"
            sx={{ background: "#14549f", padding: '5px 20px', fontSize: '1rem' }}
          onClick={() => {
            if(value!==""){

              setFullFormData({...fullFormData, desiredCourse: data[parseInt(value)]})
            setPageNo(pageNo + 1);
          } else{alert("Choose an option to proceed.")}
          }}
          >
            Next
          </Button>
        </Box>

      {/* <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "2rem",
          flexWrap: "wrap",
        }}
      >
        <button
          style={{
            margin: "0.5rem 0.5rem",
            borderRadius: "5px",
            backgroundColor: "rgb(156, 16, 16)",
            minWidth: "7rem",
            transition: "opacity 0.3s ease",
            opacity: "1",
          }}
          onMouseOver={(event) => {
            event.target.style.opacity = "0.7";
          }}
          onMouseOut={(event) => {
            event.target.style.opacity = "1";
          }}
          onClick={() => {
            setRegisterModalOpen(false);
          }}
          type="button"
        >
          Close
        </button>
        <button
          style={{
            margin: "0.5rem 0.5rem",
            borderRadius: "5px",
            backgroundColor: "#292929",
            minWidth: "7rem",
            transition: "opacity 0.3s ease",
            opacity: "1",
          }}
          onMouseOver={(event) => {
            event.target.style.opacity = "0.7";
          }}
          onMouseOut={(event) => {
            event.target.style.opacity = "1";
          }}
          onClick={() => {
            setPageNo(pageNo - 1);
          }}
          type="button"
        >
          Previous
        </button>
        <button
          style={{
            margin: "0.5rem 0.5rem",
            borderRadius: "5px",
            backgroundColor: "#eee",
            border: "1px solid #292929",
            color: "#292929",
            minWidth: "7rem",
            transition: "opacity 0.3s ease",
            opacity: "1",
          }}
          onMouseOver={(event) => {
            event.target.style.opacity = "0.7";
          }}
          onMouseOut={(event) => {
            event.target.style.opacity = "1";
          }}
        >
          Next
        </button>
      </div> */}
    </div>
  );
}
