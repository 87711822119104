import React, { useEffect, useState } from 'react';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { Box, Button } from '@mui/material';
import dayjs from 'dayjs';
import { toast } from 'react-hot-toast';

export function ReqExtraPageTwo({
  setPageNo,
  pageNo,
  setActivePage,
  fullFormData,
  setFullFormData,
  isMobile
}) {
  useEffect(() => {
    setActivePage(3);
  }, []);

  const [startDateAndTime, setStartDateAndTime] = useState(fullFormData?.startDateAndTime || null);
  const [endDateAndTime, setEndDateAndTime] = useState(fullFormData?.endDateAndTime || null);
  const [error, setError] = useState(null);

  const handleStartDateChange = (newValue) => {
    setStartDateAndTime(newValue);
    if (endDateAndTime && newValue && dayjs(newValue).isAfter(dayjs(endDateAndTime))) {
      toast.error('End date and time must be after start date and time');
      setError("End date and time must be after start date and time");
    } else {
      setError(null);
    }
  };

  const handleEndDateChange = (newValue) => {
    if (newValue && startDateAndTime && dayjs(newValue).isBefore(dayjs(startDateAndTime))) {
      toast.error('End date and time must be after start date and time');
      setError("End date and time must be after start date and time");
    } else {
      setEndDateAndTime(newValue);
      setError(null);
    }
  };

  return (
    <div style={{ flex: 1 }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={['MobileDateTimePicker', 'MobileDateTimePicker']}>
          <div style={{ flex: 1 }}>
            <h3 style={{ fontSize: isMobile ? "1.1rem" : "1.3rem",  fontWeight: "bold" }}>
              Start Date and Time for Your Course <span style={{ color: 'red' }}>*</span>
            </h3>
            <DemoItem label={''}>
              <DateTimePicker
                value={startDateAndTime}
                onChange={handleStartDateChange}
              />
            </DemoItem>
          </div>

          <div style={{ flex: 1 }}>
            <h3 style={{ fontSize: isMobile ? "1.1rem" : "1.3rem", marginTop: "1rem", fontWeight: "bold" }}>
              End Date and Time for Your Course <span style={{ color: 'red' }}>*</span>
            </h3>
            <DemoItem label={''}>
              <DateTimePicker
                value={endDateAndTime}
                onChange={handleEndDateChange}
              />
            </DemoItem>
          </div>
        </DemoContainer>
      </LocalizationProvider>

      <Box
        sx={{
          width: "100%",
          display: "flex",
          gap: "10px",
          justifyContent: "flex-end",
          mt: 4,
          flexWrap: "wrap",
        }}
      >
        <Button
          variant="outlined"
          sx={{ fontSize: '1rem' }}
          type="button"
          onClick={() => {
            setPageNo(pageNo - 1);
          }}
        >
          Previous
        </Button>
        <Button
          disabled={!startDateAndTime || !endDateAndTime || !!error}
          variant="contained"
          sx={{ background: "#14549f", padding: '5px 20px', fontSize: '1rem' }}
          onClick={() => {
            if (startDateAndTime && endDateAndTime) {
              setFullFormData({ ...fullFormData, startDateAndTime: startDateAndTime, endDateAndTime: endDateAndTime });
              setPageNo(pageNo + 1);
            } else {
              toast.error("Please provide both start and end dates.");
            }
          }}
        >
          Next
        </Button>
      </Box>
    </div>
  );
}
