import { Box, Button, TextField, IconButton, Chip } from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import Form from "react-bootstrap/Form";

import { addDoc, collection, doc, updateDoc } from "firebase/firestore";
import emailjs from "emailjs-com";
import { db } from "../../firebase";
import { MyContext } from "../../Context/MyContext";
import { toast } from "react-hot-toast";

import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import moment from 'moment-timezone';


export function ReqExtraPageTwo({
  setPageNo,
  pageNo,
  setActivePage,
  fullFormData,
  setFullFormData,
  isMobile
}) {
  useEffect(() => {
    setActivePage(7);
  }, []);

  const [lessonDates, setLessonDates] = useState(
    fullFormData?.howOftenToTakeLesson || []
  );

  const [submitting, setSubmitting] = useState(false);
  const { adminAddNotification } = useContext(MyContext);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (lessonDates.some((date) => !date)) {
      toast("Please fill all dates");
      return;
    }

    setFullFormData({ ...fullFormData, howOftenToTakeLesson: lessonDates });

    const timeZone = moment.tz.guess();

    const dataToSubmit = {
      ...fullFormData,
      howOftenToTakeLesson: lessonDates,
      submittedAt: new Date(),
      timeZone: timeZone,
    };

    const tableRows = Object.entries(dataToSubmit)
      .map(([key, value]) => {
        if (typeof value === "object" && !Array.isArray(value)) {
          value = JSON.stringify(value);
        } else if (Array.isArray(value)) {
          value = value.join(", ");
        }
        return `
            <tr>
                <td>${key}</td>
                <td>${value}</td>
            </tr>
        `;
      })
      .join("");

    const ordersRef = collection(db, "adminPanel");
    const customDocRef = doc(ordersRef, "studentForm");
    const orderCollectionRef = collection(customDocRef, "studentForm");

    try {
      setSubmitting(true);
      // Create the document and get the generated ID
      const docRef = await addDoc(orderCollectionRef, dataToSubmit);
      const id = docRef.id;

      // Update the document to include the ID
      await updateDoc(docRef, { id: id });
      await adminAddNotification(
        `Register Student Form was just submitted by ${dataToSubmit?.userDetails?.email}`
      );

      const serviceId = process.env.REACT_APP_EMAILSERVICEID;
      const templateId = process.env.REACT_APP_EMAILTEMPLATEID;
      const userId = process.env.REACT_APP_EMAILUSERID;
      
      const emailParams = {
        from_name: "IBInnovators",
        to_name: "IBInnovators",
        send_to: "tuitions@ibinnovators.com", // Replace with your recipient's email address
        subject: "New Student Form Submission",
        message: `
      <html>
      <head>
      </head>
      <body>
      <p>A person just submitted Student Form:</p>
      <table style="border: 1px solid #000; border-collapse: collapse; width: 100%;">
      <tbody>
         ${tableRows}
      </body>
      </html>
      
  `,
      };

      emailjs
        .send(serviceId, templateId, emailParams, userId)
        .then((response) => {
          // Handle success, such as resetting form fields
          // setRegisterModalOpen(false);
        })
        .catch((error) => {});

      setPageNo(pageNo + 2);
      toast.success("Form Submitted");
      // setActivePage(1)
    } catch (error) {
      toast.error("Error submitting Form");
      console.error(error)
    } finally {
      setSubmitting(false);
    }

    // setPageNo(pageNo + 1);
  };



useEffect(() => {
  setFullFormData(prev => ({...prev, howOftenToTakeLesson: lessonDates}))
}, [lessonDates])





  const handleSlotClick = (day, time) => {
    const slot = `${day}-${time}`;
    setLessonDates((prevSelectedSlots) =>
      prevSelectedSlots.includes(slot)
        ? prevSelectedSlots.filter((s) => s !== slot)
        : [...prevSelectedSlots, slot]
    );
  };

  const isSelected = (day, time) => lessonDates.includes(`${day}-${time}`);

  const timePeriods = ["Before 12PM", "12PM - 3PM", "3PM - 6PM", "After 6PM"];
  const days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  return (
    <div >
      <Form.Label style={{ fontSize: isMobile ? "1.1rem" : "1.3rem" }}>
        How often would you like to take lessons?{" "}
        <span className="text-danger">*</span>
      </Form.Label>
  
      <form onSubmit={handleSubmit}>
        <div >
          {/* TIME TABLE */}
          <div
            style={{
              display: "flex",
              gap: "10px",
              justifyContent: "space-between",
              flex: 1,
            }}
          >
            <div
              style={{
                flex: 1,
                gap: "10px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  flex: 1,
                  minHeight: "40px",
                  padding: "5px",
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: 'center',
                  fontSize: isMobile ? "small" : "1rem",
                }}
              >
                GMT
              </div>
              {timePeriods.map((time) => (
                <div
                  key={time}
                  style={{
                    flex: 1,
                    minHeight: "40px",
                    padding: "5px",
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                    whiteSpace: isMobile ? "wrap" : 'nowrap',
                    justifyContent: 'center',
                    fontSize: isMobile ? "x-small" : "1rem"
                  }}
                >
                  {time}
                </div>
              ))}
            </div>
  
            {days.map((day) => (
              <div
                key={day}
                style={{
                  flex: 1,
                  gap: "10px",
                  display: "flex",
                  flexDirection: "column",
                  minWidth: isMobile ? "auto" : '50px',
                  fontSize: isMobile ? "small" : "1rem",
                  maxWidth: isMobile ? "10%" : '100%',
                }}
              >
                <div
                  style={{
                    flex: 1,
                    minHeight: "40px",
                    padding: "5px",
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {day.slice(0, 3)}
                </div>
                {timePeriods.map((time) => (
                  <div
                    key={time}
                    style={{
                      flex: 1,
                      minHeight: "40px",
                      padding: "5px",
                      textAlign: "center",
                      display: "flex",
                      alignItems: "center",
                      userSelect: "none",
                      transition: "all 0.5s ease-in-out",
                      cursor: "pointer",
                      background: isSelected(day, time) ? "#007bff" : "#ccc",
                    }}
                    onClick={() => handleSlotClick(day, time)}
                  ></div>
                ))}
              </div>
            ))}
          </div>
        </div>
  
        <Box
          sx={{
            width: "100%",
            display: "flex",
            gap: "10px",
            justifyContent: "flex-end",
            mt: 4,
            flexWrap: "wrap",
          }}
        >
          <Button
            variant="outlined"
            sx={{ fontSize: "1rem" }}
            onClick={() => setPageNo(pageNo - 1)}
          >
            Previous
          </Button>
          <Button
            disabled={lessonDates?.length === 0 || submitting}
            variant="contained"
            sx={{
              background: "#14549f",
              padding: "5px 20px",
              fontSize: "1rem",
            }}
            type="submit"
          >
            {submitting ? "Submitting" : "Submit"}
          </Button>
        </Box>
      </form>
    </div>
  );
  
}
