import React, { useState } from 'react'
import './index.css'
import QueryFormFirstStep from './Step1'
import QueryFormSecondStep from './Step2'
import QueryFormThirdStep from './Step3'
import QueryFormFourthStep from './Step4'
import QueryFormFifthStep from './Step5'
import QueryFormSixthStep from './Step6'
import QueryFormSeventhStep from './Step7'
import { Alert } from 'reactstrap'

export default function QueryForm(props) {
    const { closeModal } = props
    const [progressBar, setProgressBar] = useState(10)
    const [currentStep, setCurrentStep] = useState({
        step: 1,
        specificTab: "",
        formModal: []
    })

    const formSelected = (selectPage, currentStep, payload) => {
        setCurrentStep({
            ...currentStep,
            step: currentStep,
            specificTab: selectPage,
            formModal: payload,
        })
        handleProgressBarWidth(currentStep)
    }

    const currentForm = () => {
        if (currentStep.step === 1) {
            return (
                <QueryFormFirstStep
                    selectedCallBack={formSelected}
                />
            )
        } else if (currentStep.step === 2) {
            return (
                <QueryFormSecondStep
                    selectedCallBack={formSelected}
                    selectedProgram={currentStep.specificTab}
                />
            )
        } else if (currentStep.step === 3) {
            return (
                <QueryFormThirdStep
                    selectedCallBack={formSelected}
                    selectedProgram={currentStep.specificTab}
                />
            )
        } else if (currentStep.step === 4) {
            return (
                <QueryFormFourthStep
                    selectedCallBack={formSelected}
                    selectedProgram={currentStep.specificTab}
                />
            )
        } else if (currentStep.step === 5) {
            return (
                <QueryFormFifthStep
                    selectedCallBack={formSelected}
                    selectedProgram={currentStep.specificTab}
                />
            )
        } else if (currentStep.step === 6) {
            return (
                <QueryFormSixthStep
                    selectedCallBack={formSelected}
                    selectedProgram={currentStep.specificTab}
                />
            )
        } else if (currentStep.step === 7) {
            return (
                <QueryFormSeventhStep
                    selectedCallBack={formSelected}
                    selectedProgram={currentStep.specificTab}
                />
            )
        } else if (currentStep.step === 8) {
            return (
                <Alert color="success">
                    <h4 className="alert-heading text-center">🎉 Congratulations! 🎉</h4>
                    <p className='homeParaFont mt-4 p-3'>
                        You've successfully completed the student form and taken a significant step towards your
                        academic journey.
                        Your dedication and attention to detail are truly commendable.
                        We appreciate your commitment to providing accurate information.
                    </p>
                    <hr />
                    <p className="homeParaFont p-3">
                        Our team will now process your form promptly. Should we require any further details,
                        rest assured that our team will contact you soon using the contact information you've provided.
                        In the meantime, feel free to explore our website for more information about our programs and resources.
                        Thank you for choosing us to be a part of your educational aspirations. Wishing you all the best as you embark
                        on this exciting new chapter of your life! 📚🌟
                    </p>
                </Alert>
            )
        }

    }

    const handleProgressBarWidth = (step) => {
        setProgressBar(14 * step)
    }

    return (
        <div>
            {currentStep.step === 8 ?
                null
                :
                <div className="progress">
                    <div className="progress-bar progress-bar-striped" role="progressbar" style={{ width: `${progressBar}%` }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
            }

            {currentForm()}
            <div className='text-center mt-5'>
                {
                    currentStep.step === 8 ?
                        <React.Fragment>
                            <button
                                className='btn btn-outline-white text-dark'
                                onClick={() => closeModal()}
                                disabled={currentStep.step === 1}
                            >
                                Close
                            </button>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <button
                                className='btn btn-outline-light text-dark'
                                onClick={() => {
                                    setCurrentStep({
                                        ...currentStep,
                                        step: currentStep.step - 1
                                    })
                                    handleProgressBarWidth(currentStep.step - 1)
                                }}
                                disabled={currentStep.step === 1}
                            >
                                <i className="fa-solid fa-left-long ps-2" />
                            </button>
                        </React.Fragment>
                }
                <div className='btn-group'>

                </div>

            </div>
        </div >
    )
}
