import React, { useEffect, useState, useRef } from 'react'
import './index.css'
import ReviewCard from './ReviewCard'
import RotateCard from './RotateCard'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { borderRadius } from '@mui/system';

export default function ReviewSection() {
    const reviewCard = [
        {
            name: "Joshua",
            from: "British Virgin Islands",
            comment:
                "Ms. Sarah is an excellent teacher. She has a great personality and is always willing to help and when she is teaching,she makes sure you understand the concept and would answer all your questions and therefore I really enjoy her classes and will definitely recommend her.",
            rating: 4
        },
        {
            name: "Inayatullah",
            from: "Pakistan",
            comment:
                "I have been studying ESS from Mr. Daniel for almost two years and it has been a great experience. He is a very thoughtful teacher who puts a lot of thought into how he presents the material. His lessons were engaging, useful, and he was very patient with everyone in class always encouraging his students to try.",
            rating: 5
        },
        {
            name: "Mael",
            from: "France",
            comment:
                "Mr. Anderson has been an extremely patient teacher, taking time to explain simple concepts in detail which made the learning environment comfortable and conducive. I was able to score a 6 due to his efforts.",
            rating: 5
        },
        {
            name: "Noor",
            from: "Germany",
            comment:
                "Sir Alex is a wonderful teacher. I used to be weak at biology as some of my concepts weren’t clear, prior to having him as my teacher. Since he has been my teacher, I have cleared a lot of my concepts and received consistent feedback which has always been specific and detailed.",
            rating: 4
        },
        {
            name: "Annalia",
            from: "Netherlands",
            comment:
                "Hi, I have a 6 in Chemistry, what I was hoping for. I would like to thank Ms. Elizabeth for helping me out with my IA and final exam practice, it allowed me to strengthen my skills and improve my grade from a 4 to a 6. I would definitely recommend her to all IB chemistry students.",
            rating: 5
        },
        {
            name: "Tomas",
            from: "UK",
            comment:
                "I got 38 with a 7 in BM. Exactly what I was hoping for. Thanks again for your help with tutoring. I felt so much more confident about the exam thanks to you. You were also extremely helpful with my EE which I am very grateful for!",
            rating: 5
        },
        {
            name: "Yaroslav",
            from: "Spain",
            comment:
                "Ms. Olivia has an ability that I think is unique to her. I was struggling Maths AA(HL) throughout my 1st year of IB. I started tutoring with her at the end of my 1st Year. Overall, I scored 6 in the subject and the credit goes to her. I never knew online learning can help me out this much.",
            rating: 4
        },
        {
            name: "Aman",
            from: "Germany",
            comment:
                "Hello, I am just sending this text as an appreciation for what you have given me. I could not thank you enough for your help and great tutoring. I was easily able to improve my grade from 4 to 6 in IB psychology just because of you. I have a total of 38 but I am glad that I found you in my DP2.",
            rating: 5
        },
        {
            name: "Jessica",
            from: "UK",
            comment:
                "Jonathan is an exceptional teacher. I was hesitant in the start as my daughter Julie had Autism and I was not sure if online learning would help her that much. But I must say the way he handled my daughter was out of this world. She was very comfortable with his style of teaching.",
            rating: 5
        },
        {
            name: "Eriola",
            from: "Albania",
            comment:
                "“Miss Emily helped a lot to achieve a better score in my Maths AI exam. I was struggling throughout the year, however with her tutoring I was able to get a 6 and improve my IA.“",
            rating: 4
        },
        {
            name: "Olga",
            from: "UK",
            comment:
                "Alex is a lovely tutor who has helped me upgrade from a grade 3 to a 6! He is skilled at adjusting the pace to my needs which has helped me understand concepts I struggled with in class. What I found useful is the numerous worksheets and videos he can provide to cement my knowledge and practice exam technique.",
            rating: 5
        },
        {
            name: "Raiaab",
            from: "Pakistan",
            comment:
                "My biology paper went very well, and Alhamdulillah I scored a 7 just because of my tutor Daniel. I have been struggling with Biology and he has helped me out throughout my DP2. He built my IB foundation and helped me with my tough topics. Thankyou for all the resources and worksheets.",
            rating: 5
        },
        {
            name: "Amrit",
            from: "Germany",
            comment:
                "Ms. Elizabeth has really been a source of inspiration for me. She helped me with essential concepts of IB Chemistry and because of her I scored a 7 in my final exams. Her way of teaching really helps the students to understand difficult concepts in very less time.",
            rating: 5
        }
    ]
    const [slidesToShow, setSlidesToShow] = useState(3);
    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 900) {
                setSlidesToShow(3);
                setIsMobile(false)
            } else if (window.innerWidth >= 750) {
                setSlidesToShow(2);
                setIsMobile(false)
            } else {
                setSlidesToShow(1);
                setIsMobile(true)
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{
                    ...style,
                    display: "flex", // Use flexbox for centering
                    justifyContent: "center", // Align horizontally to the center
                    alignItems: "center", // Align vertically to the center
                    background: "rgba(14, 56, 136, 0.9)",
                    zIndex: 1, // Ensure the arrow is on top of the slider
                    transform: 'scale(2.5)', // Use 'transform' to scale the element,
                    borderRadius: '50%'
                }}
                onClick={onClick}
            > 
                {/* Content inside the arrow */}
            </div>
        );
    }
    
    
    
    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{
                    ...style,
                    display: "flex", // Use flexbox for centering
                    justifyContent: "center", // Align horizontally to the center
                    alignItems: "center", // Align vertically to the center
                    background: "rgba(14, 56, 136, 0.9)",
                    zIndex: 1, // Ensure the arrow is on top of the slider
                    transform: 'scale(2.5)', // Use 'transform' to scale the element,
                    borderRadius: '50%'
                }}
                onClick={onClick}
            > 
                {/* Content inside the arrow */}
            </div>
        );
    }
    


    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: slidesToShow,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    // fade: true,
    
    };



    return (
        <React.Fragment>
            <div style={{backgroundColor: 'white'}} className='mt-5'>
                <section>
                    <div className="homePageHeadings text-center textTheme pt-3" style={{ fontSize:isMobile ? "2rem" : "2.5rem", color: "rgb(14, 56, 136)", fontWeight: 'bold' }}>
                        <div>Don’t just take our word for it....</div>
                    </div>
                    <div>
                        <div className="row text-center">
                            <div  className="profile-card-slider mt-5">
                                <Slider  style={{height: '100%', borderRadius: 'none'}}  {...settings}>
                                    {(reviewCard || []).map((review, i) => 
                                            <div style={{borderRadius: 'none'}} className="col-xl-4 col-lg-12" key={i}>
                                                <RotateCard review={review} />
                                            </div>
)}
                                </Slider>
                            </div>

                        </div>
                    </div>
                </section>
            </div>
        </React.Fragment>
    )
}
