import React, { useState, useEffect, useContext } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import { addDoc, collection, doc, updateDoc } from "firebase/firestore";
import emailjs from "emailjs-com";
import { db } from "../../firebase";
import { MyContext } from "../../Context/MyContext";

import {
  Button,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Box,
  Container,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import toast from "react-hot-toast";
import { GuidanceAndSupport } from "./GuidanceAndSupport";
import { useLocation } from "react-router-dom";

export function RevPageNoFive({
  setRegisterModalOpen,
  setPageNo,
  pageNo,
  setActivePage,
  fullFormData,
  setFullFormData,
  isMobile,
  setShowImage,
}) {
  useEffect(() => {
    setActivePage(6);
    setShowImage(true);
  }, []);



  function handleSubmit() {
    if(fullFormData?.guidancePrice > 0){
      setPageNo((prev) => prev + 1);
    }
  }

  return (
    <Form onSubmit={handleSubmit}>

      <GuidanceAndSupport
        setShowImage={setShowImage}
        setRegisterModalOpen={setRegisterModalOpen}
        setPageNo={setPageNo}
        pageNo={pageNo}
        setActivePage={setActivePage}
        fullFormData={fullFormData}
        setFullFormData={setFullFormData}
        isMobile={isMobile}
      />

      <Box
        sx={{
          width: "100%",
          display: "flex",
          gap: "10px",
          justifyContent: "flex-end",
          mt: 4,
          flexWrap: "wrap",
        }}
      >
        <Button
          variant="outlined"
          // color="error"
          sx={{ fontSize: "1rem" }}
          type="button"
          onClick={() => {
            setPageNo(pageNo - 1);
          }}
        >
          Previous
        </Button>
        <Button
          variant="contained"
          sx={{
            background: "#14549f",
            padding: "5px 20px",
            fontSize: "1rem",
          }}
          disabled={
              fullFormData?.guidancePrice <= 0
          }
          type="submit"
        >
          Next
        </Button>
      </Box>

      {/* <Form onSubmit={handleSubmit}>
        <Form.Group
          as={Col}
          md="12"
          controlId="validationCustom05"
          className="mt-4"
        >
          <Form.Label style={{ fontSize: "1.3rem" }}>
            What's your fundamental objective?{" "}
            <span className="text-danger">*</span>
          </Form.Label>
          <Grid container spacing={0} style={{ flex: 1, minWidth: "300px" }}>
            {[
              "Improve my Grade",
              "Prepare for my Final Exams",
              "Boost my Grip on Subjects",
              "Fill in Subject Gaps",
              "Other",
            ].map((label, index) => (
              <Grid item xs={12} sm={isMobile ? 12 : 6} key={index}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={objective.includes(label)}
                      onChange={handleCurriculaCheckboxChange}
                      value={label}
                    />
                  }
                  label={label}
                  style={{ fontSize: "1.2rem" }}
                />
                {label === "Other" && objective.includes("Other") && (
                  <TextField
                    style={{ fontSize: "1.2rem" }}
                    onChange={(e) => {
                      setOtherText(e.target.value);
                    }}
                    required
                    type={"text"}
                    label="Enter here"
                    fullWidth
                  />
                )}
              </Grid>
            ))}
          </Grid>
        </Form.Group>

        <Form.Group
          as={Col}
          md="12"
          controlId="validationCustom05"
          className="mt-4"
        >
          <Form.Label style={{ fontSize: "1.3rem" }}>
            Do you also want guidance and support for your internal assessment
            or extended essay for your chosen subjects?{" "}
            <span className="text-danger">*</span>
          </Form.Label>
          <RadioGroup
            aria-label="guidance and support"
            name="guidanceAndSupport"
            value={wantGuidanceAndSupport}
            onChange={handleRadioChange}
          >
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="no" control={<Radio />} label="No" />
          </RadioGroup>
        </Form.Group>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            gap: "10px",
            justifyContent: "flex-end",
            mt: 4,
            flexWrap: "wrap",
          }}
        >
          <Button
            variant="outlined"
            // color="error"
            sx={{ fontSize: "1rem" }}
            type="button"
            onClick={() => {
              setPageNo(pageNo - 1);
            }}
          >
            Previous
          </Button>
          <Button
            variant="contained"
            sx={{
              background: "#14549f",
              padding: "5px 20px",
              fontSize: "1rem",
            }}
            disabled={objective?.length === 0 || wantGuidanceAndSupport === ""}
            type="submit"
          >
            Next
          </Button>
        </Box>
      </Form> */}
    </Form>
  );
}
