import React, {useState, useEffect} from "react";
import "./index.css";
import { useNavigate } from "react-router-dom";

import First from '../../assets/1st.png';
import Second from '../../assets/2nd.png';
import Third from '../../assets/3rd.png';

export default function HomeSteps(props) {
  const navigate = useNavigate();
  const { invisibleCardInfo } = props;

  const [isMobile, setIsMobile] = useState(false);

  const checkIsMobile = () => {
    setIsMobile(window.innerWidth <= 1200); // You can adjust the width threshold as needed
  };

  useEffect(() => {
    checkIsMobile(); // Initial check
    window.addEventListener("resize", checkIsMobile); // Add event listener

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", checkIsMobile);
    };
  }, []);

  return (
    <div
      id="howItWork"
      style={{
        width: "100%",
        marginBottom: 0,
      }}
    >
      {invisibleCardInfo.title && (
        <div
          className="homePageHeadings text-center textTheme"
        >
          <div style={{ fontSize: isMobile ? "2rem" : "2.5rem", color: "rgb(14, 56, 136)", fontWeight: 'bold', margin: '15px' }}>
            Unlock Your Potential: Register Now, Match Instantly, Succeed Always.
          </div>
        </div>
      )}

      {invisibleCardInfo.description && (
        <div style={{ textAlign: "center" }} className="p-3">
          {invisibleCardInfo.description}
        </div>
      )}

      <div
        style={{
          paddingLeft: "5vw",
          paddingRight: "5vw",
          color: "rgb(17, 44, 95)",
        }}
        className="row mt-5"
      >
        <React.Fragment>
          <div
            style={{ marginBottom: "1rem" }}
            className={"col-md-" + 12 / invisibleCardInfo.cardsInfo.length}
          >
            <div
              style={{
                height: "100%",
                justifyContent: 'space-between',
                flex: 1,
                display: 'flex'
              }}
            >
              <div className="card-body" style={{textAlign: 'center'}}>
              <div style={{margin: '10px', flex: 1}}>
                <img src={First} alt='First Step' style={{width: '70%'}} />
            </div>
                <h5 style={{ fontSize: "1.5rem", flex: 1 }} className="card-title mb-0">
                  <b>Register Now</b>
                </h5>
                <div  style={{
                justifyContent: 'space-between',
                flex: 1,
                display: 'flex',
                flexDirection: 'column'}}>
                <div
                  style={{
                    textAlign: "center",
                    marginTop: "0px",
                    fontSize: "1.2rem",
                  }}
                  className="p-3"
                >
                  At <b >IB <span style={{color: 'rgb(156, 16, 16)'}}>INNOVATORS</span></b>, we offer flexible tutoring packages designed
                  to cater to your exact requirements.
                </div>
                
                <div>
                {/* <button
                  style={{
                    background: "transparent",
                    border: "1px solid rgb(17, 44, 95)",
                    color: "rgb(17, 44, 95)",
                    marginBottom: '1rem'
                  }}
                  onClick={() => navigate("/login")}
                >
                  Register Now
                </button> */}
                </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>

        <React.Fragment>
          <div
            style={{ marginBottom: "1rem" }}
            className={"col-md-" + 12 / invisibleCardInfo.cardsInfo.length}
          >
            <div
              style={{
                height: "100%",
                justifyContent: 'space-between',
                flex: 1,
                display: 'flex'
              }}
            >
              <div className="card-body" style={{textAlign: 'center'}}>
              <div style={{margin: '10px', flex: 1 }}>
                <img src={Second} alt='First Step' style={{width: '70%'}}  />
            </div>
                <h5 style={{ fontSize: "1.5rem", flex: 1  }} className="card-title mb-0">
                  <b>Match Instantly</b>
                </h5>
                <div  style={{
                justifyContent: 'space-between',
                flex: 1,
                display: 'flex',
                flexDirection: 'column'}}>
                <div
                  style={{
                    textAlign: "center",
                    marginTop: "0px",
                    fontSize: "1.2rem",
                  }}
                  className="p-3"
                >
                  After booking your package, the search is on us! From our
                  extensive network of tutors, we meticulously match you with
                  the best tutors.
                </div>
                <div>
                {/* <button
                  style={{
                    background: "transparent",
                    border: "1px solid rgb(17, 44, 95)",
                    color: "rgb(17, 44, 95)",
                    marginBottom: '1rem'
                  }}
                  onClick={() => navigate("/howitworks")}
                >
                  Learn More...
                </button> */}
                </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>

        <React.Fragment>
          <div
            style={{ marginBottom: "1rem" }}
            className={"col-md-" + 12 / invisibleCardInfo.cardsInfo.length}
          >
            <div
              style={{
                height: "100%",
                justifyContent: 'space-between',
                flex: 1,
                display: 'flex'
              }}
            >
              <div className="card-body" style={{textAlign: 'center'}}>
              <div style={{margin: '10px', flex: 1 }}>
                <img src={Third} alt='First Step' style={{width: '70%'}}  />
            </div>
                <h2 style={{ fontSize: "1.5rem", flex: 1  }} className="card-title mb-0">
                  <b>Succeed Always</b>
                </h2>
                <div  style={{
                justifyContent: 'space-between',
                flex: 1,
                display: 'flex',
                flexDirection: 'column'}}>
                <div
                  style={{
                    textAlign: "center",
                    marginTop: "0px",
                    fontSize: "1.2rem",
                  }}
                  className="p-3"
                >
                  Once your perfect tutor is found, you'll connect with them
                  through an online class.
                </div>
                
                <div>
                {/* <button
                  style={{
                    background: "transparent",
                    border: "1px solid rgb(17, 44, 95)",
                    color: "rgb(17, 44, 95)",
                    marginBottom: '1rem'
                  }}
                  onClick={() => navigate("/howitworks")}
                >
                  Learn More...
                </button> */}
                </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      </div>
    </div>
  );
}
