import React from "react";
import classes from "./Styles.module.css";

export default function CompanyDetails(){
    
    return(
        <div className={classes.container}>
          <h1>About Us</h1>
          <p>As a leading online tutoring platform with over 8 years of unwavering commitment to student success, IB Innovators stands tall as a beacon of guidance and support for ambitious IBDP, IBMYP, and IGCSE students. Our legacy of excellence is reflected in the countless students we have helped surpass their potential and achieve remarkable academic feats.</p>
        
        <h3>Our Mission</h3>
        <h4>Pioneering Pathways to Success</h4>
        <p>At IB Innovators, we empower future leaders with a holistic learning approach, nurturing essential life skills such as critical thinking, creativity, adaptability, and empathy. Our students emerge resilient and confident, ready for an ever-changing world.</p>
        
        <h3>Contact Us</h3>
        <p style={{marginBottom: '0px'}}><b>UK Company Details</b></p>
        <p><b>IB INNOVATORS LTD</b></p>
        <p>
        4258, Unit 3A, 34-35<br/>
Hatton Garden,<br/>
Holborn London,<br/>
EC1N 8DX
        </p>
        <p>Company number: 15470023</p>
        <p style={{marginBottom: '0px'}}>+44 (0) 7462 316382 – Operations</p>
        <p>+44 (0) 207 965 7567 – Support</p>

        <p><a href="mailto:support@ibinnovators.com">support@ibinnovators.com</a></p>

        <p style={{marginBottom: '0px'}}>Follow us on:</p>
        <p>
        <a href="https://www.facebook.com/Ibinnovators">Facebook</a> | <a href="https://www.instagram.com/ibinnovators/">Instagram</a> | <a href="https://www.linkedin.com/company/ib-innovators/">LinkedIn</a> | <a href="https://www.ibinnovators.com">Website</a>
        </p>
        </div>
    )
}