import React from "react";
import "./Blogs.css";
import { useContext, useEffect, useState } from "react";
import { useCallback } from "react";
import { Link } from "react-router-dom";
import { Button, TextField } from "@mui/material";
import FindATutor from "../../Components/FindATutor";
import {
  Firestore,
  collection,
  addDoc,
  getDoc,
  getDocs,
} from "firebase/firestore";
import { db } from "../../firebase";
import groupPeople from "./../../assets/Revision.png";
import blogsImage from "../../assets/blogs.png";
import { Helmet } from "react-helmet";

import blogsImageMobile from "../../assets/blogsMobile.jpg";
import { MyContext } from "../../Context/MyContext";
import BlogCard from "./BlogCard";

import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";

const Blogs = (props) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [data, setData] = useState([]);

  const { seoData } = useContext(MyContext);
  const [thisPageSEO, setThisPageSEO] = useState({});
  const [imageLoaded, setImageLoaded] = useState(false);
  const [selectedTag, setSelectedTag] = useState("All");
  const [showAllCat, setShowAllCat] = useState(false);
  
  const tags = [
    "All",
    "Applying for University",
    "Efficiency",
    "Exam Tips",
    "IB - Understanding IT",
    "IB CAS",
    "IB Extended Essay",
    "IB Lanterna Courses",
    "IB Theory of Knowledge",
    "IGCSE",
    "Most Popular",
    "Plan for Success",
    "Quick Fix",
    "Revision Skills",
    "Student Self Care",
    "Study Skills",
    "Uncategorized",
  ];

  const handleClick = (e) => {
    setSelectedTag(e);
  };

  useEffect(() => {
    if (seoData) {
      setThisPageSEO(seoData?.find((e) => e?.pageName === "Blogs"));
    }
  }, [seoData]);

  // Function to fetch blogs from Firestore
  const fetchBlogs = async () => {
    const blogsRef = collection(db, "Blogs");
    const snapshot = await getDocs(blogsRef);

    const blogsArray = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    console.log("BLOGSSS", blogsArray);
    setData(blogsArray);
  };

  // Using useEffect to call fetchBlogs on component mount
  useEffect(() => {
    fetchBlogs();
  }, []);

  // const data = [
  //   {
  //     id: 181860158,
  //     header: "IB Innovators Blog",
  //     content: `
  //     <h1 style="font-size: 24px; font-weight: bold; color: #333;">Discover the World of Learning with IB Innovators</h1>
  //     <p style="font-size: 16px; color: #777;">Posted by IB Innovators Team on September 10, 2023</p>

  //     <p style="font-size: 18px; line-height: 1.5; color: #333;">Welcome to IB Innovators, your gateway to excellence in education! We are thrilled to introduce you to the world of opportunities and innovation in learning.</p>

  //     <h2 style="font-size: 20px; font-weight: bold; color: #333;">Why Choose IB Innovators?</h2>
  //     <p style="font-size: 18px; line-height: 1.5; color: #333;">At IB Innovators, we are dedicated to empowering learners of all ages with world-class education. Here's what sets us apart:</p>
  //     <ul style="font-size: 18px; color: #333; padding-left: 20px;">
  //       <li>Comprehensive IB curriculum tailored to your needs</li>
  //       <li>Experienced and certified educators</li>
  //       <li>State-of-the-art online learning platform</li>
  //       <li>Engaging and interactive lessons</li>
  //       <li>Flexible schedules to fit your lifestyle</li>
  //     </ul>

  //     <h2 style="font-size: 20px; font-weight: bold; color: #333;">Join Our Community</h2>
  //     <p style="font-size: 18px; line-height: 1.5; color: #333;">Unlock your full potential with IB Innovators. Whether you are a student seeking academic excellence or an educator passionate about teaching, our platform is designed to inspire and transform.</p>

  //     <h2 style="font-size: 20px; font-weight: bold; color: #333;">Contact Us Today</h2>
  //     <p style="font-size: 18px; line-height: 1.5; color: #333;">Ready to embark on your educational journey with IB Innovators? Contact us today to explore our courses, enroll, and start your path to success.</p>

  //     <p style="font-size: 16px; color: #777; text-align: right;">Stay tuned for more updates from IB Innovators!</p>`,
  //     hot: true,
  //     image:
  //       "https://images.unsplash.com/photo-1497633762265-9d179a990aa6?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1473&q=80",
  //     price: "IB Innovators",
  //   },
  //   {
  //     id: 181860157,
  //     header: "IB Innovators Blog 2",
  //     content: `
  //     <h1 style="font-size: 24px; font-weight: bold; color: #333;">Discover the World of Learning with IB Innovators</h1>
  //     <p style="font-size: 16px; color: #777;">Posted by IB Innovators Team on September 10, 2023</p>

  //     <p style="font-size: 18px; line-height: 1.5; color: #333;">Welcome to IB Innovators, your gateway to excellence in education! We are thrilled to introduce you to the world of opportunities and innovation in learning.</p>

  //     <h2 style="font-size: 20px; font-weight: bold; color: #333;">Why Choose IB Innovators?</h2>
  //     <p style="font-size: 18px; line-height: 1.5; color: #333;">At IB Innovators, we are dedicated to empowering learners of all ages with world-class education. Here's what sets us apart:</p>
  //     <ul style="font-size: 18px; color: #333; padding-left: 20px;">
  //       <li>Comprehensive IB curriculum tailored to your needs</li>
  //       <li>Experienced and certified educators</li>
  //       <li>State-of-the-art online learning platform</li>
  //       <li>Engaging and interactive lessons</li>
  //       <li>Flexible schedules to fit your lifestyle</li>
  //     </ul>

  //     <h2 style="font-size: 20px; font-weight: bold; color: #333;">Join Our Community</h2>
  //     <p style="font-size: 18px; line-height: 1.5; color: #333;">Unlock your full potential with IB Innovators. Whether you are a student seeking academic excellence or an educator passionate about teaching, our platform is designed to inspire and transform.</p>

  //     <h2 style="font-size: 20px; font-weight: bold; color: #333;">Contact Us Today</h2>
  //     <p style="font-size: 18px; line-height: 1.5; color: #333;">Ready to embark on your educational journey with IB Innovators? Contact us today to explore our courses, enroll, and start your path to success.</p>

  //     <p style="font-size: 16px; color: #777; text-align: right;">Stay tuned for more updates from IB Innovators!</p>`,
  //     hot: true,
  //     image:
  //       "https://images.unsplash.com/photo-1497633762265-9d179a990aa6?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1473&q=80",
  //     price: "IB Innovators",
  //   },
  //   {
  //     id: 181860155,
  //     header: "IB Innovators Blog 3",
  //     content: `
  //     <h1 style="font-size: 24px; font-weight: bold; color: #333;">Discover the World of Learning with IB Innovators</h1>
  //     <p style="font-size: 16px; color: #777;">Posted by IB Innovators Team on September 10, 2023</p>

  //     <p style="font-size: 18px; line-height: 1.5; color: #333;">Welcome to IB Innovators, your gateway to excellence in education! We are thrilled to introduce you to the world of opportunities and innovation in learning.</p>

  //     <h2 style="font-size: 20px; font-weight: bold; color: #333;">Why Choose IB Innovators?</h2>
  //     <p style="font-size: 18px; line-height: 1.5; color: #333;">At IB Innovators, we are dedicated to empowering learners of all ages with world-class education. Here's what sets us apart:</p>
  //     <ul style="font-size: 18px; color: #333; padding-left: 20px;">
  //       <li>Comprehensive IB curriculum tailored to your needs</li>
  //       <li>Experienced and certified educators</li>
  //       <li>State-of-the-art online learning platform</li>
  //       <li>Engaging and interactive lessons</li>
  //       <li>Flexible schedules to fit your lifestyle</li>
  //     </ul>

  //     <h2 style="font-size: 20px; font-weight: bold; color: #333;">Join Our Community</h2>
  //     <p style="font-size: 18px; line-height: 1.5; color: #333;">Unlock your full potential with IB Innovators. Whether you are a student seeking academic excellence or an educator passionate about teaching, our platform is designed to inspire and transform.</p>

  //     <h2 style="font-size: 20px; font-weight: bold; color: #333;">Contact Us Today</h2>
  //     <p style="font-size: 18px; line-height: 1.5; color: #333;">Ready to embark on your educational journey with IB Innovators? Contact us today to explore our courses, enroll, and start your path to success.</p>

  //     <p style="font-size: 16px; color: #777; text-align: right;">Stay tuned for more updates from IB Innovators!</p>`,
  //     hot: true,
  //     image:
  //       "https://images.unsplash.com/photo-1497633762265-9d179a990aa6?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1473&q=80",
  //     price: "IB Innovators",
  //   },
  //   {
  //     id: 181860154,
  //     header: "IB Innovators Blog 4",
  //     content: `
  //     <h1 style="font-size: 24px; font-weight: bold; color: #333;">Discover the World of Learning with IB Innovators</h1>
  //     <p style="font-size: 16px; color: #777;">Posted by IB Innovators Team on September 10, 2023</p>

  //     <p style="font-size: 18px; line-height: 1.5; color: #333;">Welcome to IB Innovators, your gateway to excellence in education! We are thrilled to introduce you to the world of opportunities and innovation in learning.</p>

  //     <h2 style="font-size: 20px; font-weight: bold; color: #333;">Why Choose IB Innovators?</h2>
  //     <p style="font-size: 18px; line-height: 1.5; color: #333;">At IB Innovators, we are dedicated to empowering learners of all ages with world-class education. Here's what sets us apart:</p>
  //     <ul style="font-size: 18px; color: #333; padding-left: 20px;">
  //       <li>Comprehensive IB curriculum tailored to your needs</li>
  //       <li>Experienced and certified educators</li>
  //       <li>State-of-the-art online learning platform</li>
  //       <li>Engaging and interactive lessons</li>
  //       <li>Flexible schedules to fit your lifestyle</li>
  //     </ul>

  //     <h2 style="font-size: 20px; font-weight: bold; color: #333;">Join Our Community</h2>
  //     <p style="font-size: 18px; line-height: 1.5; color: #333;">Unlock your full potential with IB Innovators. Whether you are a student seeking academic excellence or an educator passionate about teaching, our platform is designed to inspire and transform.</p>

  //     <h2 style="font-size: 20px; font-weight: bold; color: #333;">Contact Us Today</h2>
  //     <p style="font-size: 18px; line-height: 1.5; color: #333;">Ready to embark on your educational journey with IB Innovators? Contact us today to explore our courses, enroll, and start your path to success.</p>

  //     <p style="font-size: 16px; color: #777; text-align: right;">Stay tuned for more updates from IB Innovators!</p>`,
  //     hot: true,
  //     image:
  //       "https://images.unsplash.com/photo-1497633762265-9d179a990aa6?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1473&q=80",
  //     price: "IB Innovators",
  //   },
  //   {
  //     id: 181860153,
  //     header: "IB Innovators Blog 5",
  //     content: `
  //     <h1 style="font-size: 24px; font-weight: bold; color: #333;">Discover the World of Learning with IB Innovators</h1>
  //     <p style="font-size: 16px; color: #777;">Posted by IB Innovators Team on September 10, 2023</p>

  //     <p style="font-size: 18px; line-height: 1.5; color: #333;">Welcome to IB Innovators, your gateway to excellence in education! We are thrilled to introduce you to the world of opportunities and innovation in learning.</p>

  //     <h2 style="font-size: 20px; font-weight: bold; color: #333;">Why Choose IB Innovators?</h2>
  //     <p style="font-size: 18px; line-height: 1.5; color: #333;">At IB Innovators, we are dedicated to empowering learners of all ages with world-class education. Here's what sets us apart:</p>
  //     <ul style="font-size: 18px; color: #333; padding-left: 20px;">
  //       <li>Comprehensive IB curriculum tailored to your needs</li>
  //       <li>Experienced and certified educators</li>
  //       <li>State-of-the-art online learning platform</li>
  //       <li>Engaging and interactive lessons</li>
  //       <li>Flexible schedules to fit your lifestyle</li>
  //     </ul>

  //     <h2 style="font-size: 20px; font-weight: bold; color: #333;">Join Our Community</h2>
  //     <p style="font-size: 18px; line-height: 1.5; color: #333;">Unlock your full potential with IB Innovators. Whether you are a student seeking academic excellence or an educator passionate about teaching, our platform is designed to inspire and transform.</p>

  //     <h2 style="font-size: 20px; font-weight: bold; color: #333;">Contact Us Today</h2>
  //     <p style="font-size: 18px; line-height: 1.5; color: #333;">Ready to embark on your educational journey with IB Innovators? Contact us today to explore our courses, enroll, and start your path to success.</p>

  //     <p style="font-size: 16px; color: #777; text-align: right;">Stay tuned for more updates from IB Innovators!</p>`,
  //     hot: true,
  //     image:
  //       "https://images.unsplash.com/photo-1497633762265-9d179a990aa6?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1473&q=80",
  //     price: "IB Innovators",
  //   },
  //   {
  //     id: 181860152,
  //     header: "IB Innovators Blog 6",
  //     content: `
  //     <h1 style="font-size: 24px; font-weight: bold; color: #333;">Discover the World of Learning with IB Innovators</h1>
  //     <p style="font-size: 16px; color: #777;">Posted by IB Innovators Team on September 10, 2023</p>

  //     <p style="font-size: 18px; line-height: 1.5; color: #333;">Welcome to IB Innovators, your gateway to excellence in education! We are thrilled to introduce you to the world of opportunities and innovation in learning.</p>

  //     <h2 style="font-size: 20px; font-weight: bold; color: #333;">Why Choose IB Innovators?</h2>
  //     <p style="font-size: 18px; line-height: 1.5; color: #333;">At IB Innovators, we are dedicated to empowering learners of all ages with world-class education. Here's what sets us apart:</p>
  //     <ul style="font-size: 18px; color: #333; padding-left: 20px;">
  //       <li>Comprehensive IB curriculum tailored to your needs</li>
  //       <li>Experienced and certified educators</li>
  //       <li>State-of-the-art online learning platform</li>
  //       <li>Engaging and interactive lessons</li>
  //       <li>Flexible schedules to fit your lifestyle</li>
  //     </ul>

  //     <h2 style="font-size: 20px; font-weight: bold; color: #333;">Join Our Community</h2>
  //     <p style="font-size: 18px; line-height: 1.5; color: #333;">Unlock your full potential with IB Innovators. Whether you are a student seeking academic excellence or an educator passionate about teaching, our platform is designed to inspire and transform.</p>

  //     <h2 style="font-size: 20px; font-weight: bold; color: #333;">Contact Us Today</h2>
  //     <p style="font-size: 18px; line-height: 1.5; color: #333;">Ready to embark on your educational journey with IB Innovators? Contact us today to explore our courses, enroll, and start your path to success.</p>

  //     <p style="font-size: 16px; color: #777; text-align: right;">Stay tuned for more updates from IB Innovators!</p>`,
  //     hot: true,
  //     image:
  //       "https://images.unsplash.com/photo-1497633762265-9d179a990aa6?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1473&q=80",
  //     price: "IB Innovators",
  //   },
  // ];

  // Function to fetch blogs from Firestore

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  let filteredData = data.filter((pkg) =>
    pkg.header.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Function to handle window resize
    const handleResize = () => {
      if (window.innerWidth < 990) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    // Initial check on component mount
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const createHelmetTags = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    const headTags = Array.from(doc.head.children);

    return headTags.map((tag, index) => {
      const tagName = tag.tagName.toLowerCase();

      if (tagName === "title") {
        return <title key={index}>{tag.innerText}</title>;
      } else if (tagName === "meta") {
        const attributes = Array.from(tag.attributes).reduce((acc, attr) => {
          acc[attr.name] = attr.value;
          return acc;
        }, {});
        return <meta key={index} {...attributes} />;
      } else if (tagName === "link") {
        const attributes = Array.from(tag.attributes).reduce((acc, attr) => {
          acc[attr.name] = attr.value;
          return acc;
        }, {});
        return <link key={index} {...attributes} />;
      } else if (tagName === "script" && tag.type === "application/ld+json") {
        return (
          <script key={index} type="application/ld+json">
            {tag.innerText}
          </script>
        );
      } else {
        return null;
      }
    });
  };

  return (
    <React.Fragment>
      <Helmet>
        {thisPageSEO?.schema && createHelmetTags(thisPageSEO.schema)}
      </Helmet>

      {/* <div style={{ flex: 1 }}>
    {isMobile ? 
    <img src={blogsImageMobile} width='100%' alt='Blogs' height='auto' />
    :
  <img src={blogsImage} width='100%' alt='Blogs' height='auto' />
}
</div> */}

      <div
        style={{
          flex: 1,
          width: "100%",
          background: "red",
          height: imageLoaded ? "max-content" : "600px",
          background: "#14549f",
        }}
      >
        <div style={{ maxWidth: "1800px", flex: 1, margin: "0px auto" }}>
          {isMobile ? (
            <img
              src={blogsImageMobile}
              width="100%"
              alt=""
              onLoad={() => setImageLoaded(true)}
              height="auto"
              style={{ maxWidth: "1800px" }}
            />
          ) : (
            <img
              src={blogsImage}
              onLoad={() => setImageLoaded(true)}
              width="100%"
              alt=""
              height="auto"
            />
          )}
        </div>
      </div>

      <div
        style={{
          padding: "5vw",
        }}
      >
        <section
          style={
            isMobile
              ? {
                  justifyItems: "center",
                  alignItems: "center",
                  margin: 0,
                  width: "100%",
                  justifyContent: "center",
                  alignContent: "center",
                  textAlign: "center",
                }
              : {}
          }
          className="container"
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
              marginBottom: "20px",
              padding: "1rem",
              gap: "10px",
            }}
          >
            {tags
              ?.slice(
                0,
                isMobile ? (showAllCat ? tags?.length : 5) : tags?.length
              )
              ?.map((item, index) => (
                <Button
                  variant={selectedTag === item ? "contained" : "outlined"}
                  key={index}
                  style={{
                    fontSize: isMobile ? "1rem" : "1.1rem",
                    // padding: "0.5rem 1rem",
                    whiteSpace: "nowrap",
                  }}
                  onClick={(e) => handleClick(item)}
                >
                  {" "}
                  {item}{" "}
                </Button>
              ))}
            {isMobile && (
              <Button
                onClick={() => {
                  setShowAllCat((prev) => !prev);
                }}
                style={{
                  color: '#3e3e3e',
                  borderColor: '#3e3e3e',
                  width: '100%'
                }}
                variant="outlined"
              >
                Show {showAllCat ? "less" : "All"} Categories
              </Button>
            )}
          </div>

          <div
            style={
              isMobile
                ? {
                    flexDirection: "column",
                    display: "flex",
                    justifyItems: "center",
                    alignItems: "center",
                    textAlign: "center",
                    marginLeft: "2rem",
                    marginRight: "2rem",
                    marginTop: "2rem",
                    flex: 1,
                  }
                : {
                    flexDirection: "column",
                    display: "flex",
                    justifyItems: "center",
                    alignItems: "center",
                    textAlign: "center",
                    flex: 1,
                    background: "#f2f2f2",
                    padding: "1rem 2rem 2rem 2rem",
                    borderRadius: "1rem",
                  }
            }
          >
            <TextField
              style={{ width: "100%" }}
              id="filled-search"
              label="Search Blogs"
              type="search"
              value={searchTerm}
              variant="standard"
              onChange={handleSearch}
            />
          </div>

          <div className="package-container">
            {filteredData?.filter((e) => selectedTag=== "All" ? e : e?.selectedTags?.some((f) => f === selectedTag)).map((pkg, index) => (
              <BlogCard
                key={index}
                image={pkg?.image}
                date={pkg?.createdOn}
                title={pkg?.header}
                link={`/blogs/${pkg.url}`}
              />
              // <section key={pkg.id}>
              //   <Link to={`/blogs/${pkg.url}`}>
              //     <div
              //       style={
              //         isMobile
              //           ? { height: "35vh", width: "85vw", padding: "4vh" }
              //           : { padding: "4vh", width: "100%" }
              //       }
              //       className="package"
              //       key={pkg.id}
              //     >
              //       <img className="image" src={pkg.image} alt="image" />
              //       <div className="packageContainer">{pkg.header}</div>
              //     </div>
              //   </Link>
              // </section>
            ))}
          </div>
          {filteredData?.filter((e) => selectedTag=== "All" ? e : e?.selectedTags?.some((f) => f === selectedTag))?.length === 0 && (
            <div
              style={{
                height: "30vh",
                width: "100%",
                fontSize: "x-large",
                textAlign: "center",
                color: "#8e8e8e",
              }}
            >
              {" "}
              No Results Found{" "}
            </div>
          )}
        </section>
      </div>
      <FindATutor />
    </React.Fragment>
  );
};

export default Blogs;
