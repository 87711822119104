import React, { useEffect, useState } from "react";
import {
  Button,
  MenuItem,
  Select,
  TextField,
  Typography,
  Container,
  Box,
  InputLabel,
  FormControl,
} from "@mui/material";

export function ReqExtraPageOne({
  setPageNo,
  pageNo,
  setActivePage,
  fullFormData,
  setFullFormData,
  isMobile
}) {
  useEffect(() => {
    setActivePage(2);
  }, []);
  const courses = [
    "IB DP", "IB MYP", "IGCSE", "A Levels", "Other"
  ];

  const [value, setValue] = useState(courses.some((e) => e === fullFormData?.whatCourseToPursue ) ? fullFormData?.whatCourseToPursue : (fullFormData?.whatCourseToPursue !== "" && fullFormData?.whatCourseToPursue) ? "Other" : "");
  const [inputText, setInputText] = useState(courses.some((e) => e === fullFormData?.whatCourseToPursue ) ? "" : fullFormData?.whatCourseToPursue);
  const [showInput, setShowInput] = useState(false);


  const handleValueChange = (e) => {
    setValue(e.target.value);
    setShowInput(e.target.value === "Other");
  };

  const handleInputChange = (e) => {
    setInputText(e.target.value);
  };

  


  return (
    <div>
      <Typography
        component="h3"
        sx={{  mb: isMobile ? 2 : 4, fontWeight: "bold", fontSize: isMobile ? "1.1rem" : "1.3rem" }}
      >
        What course of study are you pursuing? *
      </Typography>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          let updatedObjective = value;

          if (value !== "") {
            if (value === "Other") {
              if (inputText !== "") {
                updatedObjective = inputText;
              } else {
                alert('Please enter input');
                return;
              }
            }

            setValue(updatedObjective);

            setFullFormData({ ...fullFormData, whatCourseToPursue: updatedObjective });
            setPageNo(pageNo + 1);
          } else {
            alert('Please select an option');
          }
        }}
      >
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Select Course *</InputLabel>
          <Select
            label="Select Course"
            fullWidth
            required
            value={value}
            onChange={handleValueChange}
            sx={{ mb: isMobile ? 1 : 2 }}
          >
            {courses.map((item) =>
              <MenuItem key={item} value={item}>{item}</MenuItem>
            )}
          </Select>
        </FormControl>

        {value==="Other" && (
          <Box>
            <TextField
              fullWidth
              value={inputText}
              onChange={handleInputChange}
              label="Enter your course"
              required
            />
          </Box>
        )}

        <Box
          sx={{
            width: "100%",
            display: "flex",
            gap: "10px",
            justifyContent: "flex-end",
            mt: 4,
            flexWrap: "wrap",
          }}
        >
          <Button
            variant="outlined"
            // color="error"
            sx={{fontSize: '1rem' }}
            type="button"
            onClick={() => {
              setPageNo(pageNo - 1);
            }}
          >
            Previous
          </Button>
          <Button
            disabled={!value || (value==="Other" && inputText==="" ) || value ===""}
            variant="contained"
            sx={{ background: "#14549f", padding: '5px 20px', fontSize: '1rem' }}
            type="submit"
          >
            Next
          </Button>
        </Box>
      </form>
    </div>
  );
}
